import React, { FunctionComponent, useContext, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import i18n from '../../utils/i18n';
import { ActionType, AppContext, PermissionType } from '../../interfaces';
import { Context } from '../../context/Context';
import { ReactComponent as PermissionIcon } from '../../assets/imgs/permission_icon.svg';

import './LocationPermission.scss';

interface LocationPermissionModalProps {
	onGetLocation: any;
	onCancel?: any;
	isVisible: boolean;
}

const LocationPermissionModal: FunctionComponent<LocationPermissionModalProps> = ({
	onGetLocation,
	onCancel,
	isVisible,
}): JSX.Element => {
	const { dispatch } = useContext(Context) as AppContext;
	const [loading, setLoading] = useState(false);

	const locateMe = (): void => {
		setLoading(true);
		if (!('geolocation' in navigator)) {
			console.log('Geolocation unavailable!');
			setLoading(false);
			dispatch({ type: ActionType.SET_LOCATION_PERMISSION, data: PermissionType.DENIED });
			return;
		}

		navigator.geolocation.getCurrentPosition(locationSuccessCallback, locationErrorCallback, {
			enableHighAccuracy: true,
			timeout: 10000,
			maximumAge: 10000,
		});
	};

	const locationSuccessCallback: PositionCallback = (position) => {
		onGetLocation(position);
		setLoading(false);
		dispatch({ type: ActionType.SET_LOCATION_PERMISSION, data: PermissionType.GRANTED });
	};

	const locationErrorCallback: PositionErrorCallback = (positionError) => {
		setLoading(false);
		dispatch({ type: ActionType.SET_LOCATION_PERMISSION, data: PermissionType.DENIED });
		onCancel();
	};

	return (
		<Modal disableAutoFocus={true} open={isVisible}>
			<Box className="modal-material location-permission">
				<PermissionIcon className="svg-color-stroke" />
				<p>{i18n.get('location.permission.title', 'Permítenos acceder a tu localización.')}</p>
				<button disabled={loading} onClick={onCancel} className="btn-outline-primary font-size-btn-footer">
					{i18n.get('location.permission.btn_cancel', 'Cancelar')}
				</button>
				<button disabled={loading} onClick={locateMe} className="font-size-btn-footer btn-primary">
					{loading ? <div className="btn-loader" /> : i18n.get('location.permission.btn_confirm', 'Sí, dar permiso')}
				</button>
			</Box>
		</Modal>
	);
};

export default LocationPermissionModal;
