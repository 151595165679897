import React from 'react';
import S from './styles';

interface SurveyScoreInputProps {
	value: string;
	onChange: (value: string) => void;
}

const Textarea = ({ value, onChange }: SurveyScoreInputProps): JSX.Element => {
	return (
		<S.Textarea
			value={value}
			onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => onChange(event.target.value)}
			name="comment"
			rows={4}
			maxLength={255}
		/>
	);
};

export default Textarea;
