import Axios, { AxiosResponse, AxiosStatic } from 'axios';
import HttpClient from './HttpClient';
import { SuggestionRequest, ResponseMessage, SuggestionResponse } from '../interfaces';

const AUTO_DISPATCH_API = process.env.REACT_APP_AUTO_DISPATCH_API || '';

export default class AutoDispatchClient extends HttpClient {
	constructor(axios: AxiosStatic = Axios) {
		super(axios, AUTO_DISPATCH_API);
	}

	async requestSuggestions(data: {
		request: SuggestionRequest;
	}): Promise<AxiosResponse<ResponseMessage<SuggestionResponse>>> {
		return await this.post(`/api/suggestions`, data);
	}
}
