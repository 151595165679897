import React, { useState, FunctionComponent, useContext } from 'react';
import { useHistory } from 'react-router';
import SurveyContent from '../../components/survey/SurveyContent';
import { Context } from '../../context/Context';
import { AppContext, Branches, TRACKS } from '../../interfaces';
import HeliosClient from '../../api/Helios';
import { renderTipView } from '../../utils/Utils';
import { SURVEY_V2_QUESTIONS_BRANDS, WHITELABEL_BRANDS } from '../../utils/constants';
import Breadcrumbs from '../../components/common/Breadcrumbs';

import i18n from '../../utils/i18n';
import { Footer } from '../../components/common/footer';
import Header from '../../components/common/Header';
import RatingHeader from '../../components/survey/RatingHeader';
import './Survey.scss';

interface SurveyScreenProps {
	handleResetClick: () => void;
}
interface ConnectAppSurveyState {
	[key: string]: number | boolean | string;
}

export const submitSurvey = async (data: any): Promise<any> => {
	return await new HeliosClient().submitSurvey(data);
};

const redirect = (brand: string, provider: any, branch: string, serviceType: TRACKS): string => {
	if (WHITELABEL_BRANDS.includes(brand)) {
		return '/feedback';
	} else if (provider > 3 && renderTipView(brand, branch as Branches, serviceType)) {
		return '/tips';
	} else {
		return '/social';
	}
};

const SurveyScreen: FunctionComponent<SurveyScreenProps> = ({ handleResetClick }): JSX.Element => {
	const [connectAppSurvey, setConnectAppSurvey] = useState<ConnectAppSurveyState>({});
	const [loading, setLoading] = useState<boolean>(false);

	const {
		brand,
		service: {
			driver,
			provider,
			branch,
			_id,
			flags: { fromConnectApp },
			serviceType,
			situation,
		},
	} = useContext(Context) as AppContext;

	const history = useHistory();

	const onConfirmClick = async (): Promise<void> => {
		setLoading(true);
		const redirectUrl = redirect(brand, connectAppSurvey.provider, branch, serviceType as TRACKS);
		const result = await submitSurvey({
			answers: connectAppSurvey,
			comments: connectAppSurvey.comments,
			serviceId: _id,
		});
		setLoading(false);
		if (result.status) history.replace(redirectUrl);
	};

	return (
		<div className="survey tracking-route">
			<Breadcrumbs />
			<Header
				showLine={SURVEY_V2_QUESTIONS_BRANDS.includes(brand)}
				title={i18n.get('survey.title', 'Cuéntanos tu experiencia')}
				subTitle={i18n.get('survey.description', '')}
			/>

			<div className="survey-container">
				{SURVEY_V2_QUESTIONS_BRANDS.includes(brand) ? (
					<div className="survey-container__title">
						Por favor conteste las siguientes preguntas dónde 0 es nada satisfecho y 10 es muy satisfecho.
					</div>
				) : (
					<RatingHeader brand={brand} />
				)}
				<SurveyContent
					brand={brand}
					fromConnectApp={fromConnectApp}
					connectAppSurvey={connectAppSurvey}
					setConnectAppSurvey={setConnectAppSurvey}
					branch={branch}
					driver={driver}
					serviceProvider={provider}
					serviceType={serviceType}
					situation={situation}
				/>
			</div>
			<Footer.Root>
				<Footer.Button isLoading={loading} onClick={onConfirmClick} variant="primary">
					{i18n.get('survey.btn_confirm', 'Confirmar')}
				</Footer.Button>
			</Footer.Root>
		</div>
	);
};

export default SurveyScreen;
