import React, { FunctionComponent } from 'react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import parse from 'autosuggest-highlight/parse';
import { AutocompleteOption } from './index';

import './styles.scss';

interface DynamicOptionsProps {
	option: AutocompleteOption;
}

const DynamicOptions: FunctionComponent<DynamicOptionsProps> = ({ option }): JSX.Element | null => {
	const { matchesMainText = '', mainText = '', secondaryText = '' } = option;
	const matches = matchesMainText || [];
	const parts = parse(
		mainText,
		matches.map((match: any) => [match.offset, match.offset + match.length])
	);

	return (
		<div className="location-search__option">
			<LocationOnIcon />
			<div className="location-search__option-text">
				<span>
					{parts.map((part: any, index: number) => (
						<span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
							{part.text}
						</span>
					))}
				</span>
				<span color="textSecondary">{secondaryText}</span>
			</div>
		</div>
	);
};

export default DynamicOptions;
