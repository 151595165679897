import { GoogleMap, Marker } from '@react-google-maps/api';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { Context } from '../../context/Context';
import { AppContext, Driver, TripStatus } from '../../interfaces';
import { getImagesByBrand, isDriverActive } from '../../utils/Utils';
import { Brand } from '../../utils/constants';
import TripAccepted from './map-elements/trip-accepted';
import OnRoute from './map-elements/on-route';
import OnRouteOffApp from './map-elements/on-route-off-app';
import { isValidLatLng } from '../../utils/maps';
import './Map.scss';

const SITUATION_PIN_SIZE = 55;
const LNG_SITUATION_PIN_BY_SIZE = {
	bottom: SITUATION_PIN_SIZE,
	middle: SITUATION_PIN_SIZE / 2,
	top: 0,
};

interface TrackingMapProps {
	mapLoaded: boolean;
	currentStatus?: TripStatus;
}

const TrackingMap: FunctionComponent<TrackingMapProps> = ({ mapLoaded, currentStatus }): JSX.Element => {
	const {
		brand,
		locations: { provider, situation },
		service: { driver, trip },
	} = useContext(Context) as AppContext;
	const [map, setMap] = useState<google.maps.Map | null>(null);
	const [pathway, setPathway] = useState<google.maps.LatLng[]>([]);
	const [cachedDriver, setCachedDriver] = useState<Driver>(driver);

	useEffect(() => {
		const stringDriver = JSON.stringify(driver);
		const stringCachedDriver = JSON.stringify(cachedDriver);
		if (stringDriver !== stringCachedDriver) {
			setCachedDriver(driver);
		}
		// eslint-disable-next-line
	}, [driver]);

	const onLoad = (map: google.maps.Map): void => {
		map.setOptions({ maxZoom: 18 });
		setMap(map);
	};

	const imagesByBrand = getImagesByBrand(brand);
	const isNotOnRouteOffAppState = currentStatus !== TripStatus.ON_ROUTE || isDriverActive(cachedDriver);

	return (
		<div className="map-container">
			<div className="map">
				{mapLoaded && (
					<GoogleMap
						id="map"
						onLoad={onLoad}
						center={situation}
						zoom={12}
						options={{
							draggable: isNotOnRouteOffAppState,
							scrollwheel: isNotOnRouteOffAppState,
							disableDoubleClickZoom: isNotOnRouteOffAppState,
							fullscreenControl: false,
							disableDefaultUI: true,
							clickableIcons: false,
							keyboardShortcuts: isAndroid || isIOS ? false : true,
						}}
					>
						{isNotOnRouteOffAppState && (
							<>
								{isValidLatLng(pathway?.[pathway?.length - 1] || situation) && (
									<Marker
										zIndex={2}
										position={pathway?.[pathway?.length - 1] || situation}
										icon={{
											anchor: new window.google.maps.Point(
												27.5,
												LNG_SITUATION_PIN_BY_SIZE[imagesByBrand.placeSituationPin]
											),
											url: imagesByBrand.situationPin,
											scaledSize: new window.google.maps.Size(SITUATION_PIN_SIZE, SITUATION_PIN_SIZE),
										}}
									/>
								)}
								{isValidLatLng(pathway?.[0] || cachedDriver) && (
									<Marker
										zIndex={2}
										position={pathway?.[0] || cachedDriver}
										icon={{
											anchor: new window.google.maps.Point(20, 20),
											url: imagesByBrand.vehicleVial,
											scaledSize: new window.google.maps.Size(40, 40),
										}}
									/>
								)}
							</>
						)}

						{currentStatus === TripStatus.ACCEPTED && (
							<TripAccepted brand={brand as Brand} map={map} driver={cachedDriver} situation={situation} />
						)}

						{currentStatus === TripStatus.ON_ROUTE && (
							<>
								{isDriverActive(cachedDriver) ? (
									<OnRoute
										map={map}
										pathway={pathway}
										onPathwayUpdate={setPathway}
										driver={cachedDriver}
										situationLocation={situation}
										brand={brand as Brand}
									/>
								) : (
									<OnRouteOffApp
										map={map}
										brand={brand as Brand}
										situationLocation={situation}
										providerLocation={provider}
										simulatedEta={trip?.simulatedEta || 0}
										tripStamps={trip?.stamps || []}
									/>
								)}
							</>
						)}
					</GoogleMap>
				)}
			</div>
		</div>
	);
};

export default TrackingMap;
