import React, { useState, Fragment, FunctionComponent, useContext } from 'react';
import { useHistory } from 'react-router';
import cn from 'classnames';
import { Context } from '../../context/Context';
import { AppContext, CancelReasons } from '../../interfaces';
import { clearAll } from '../../context/actions';
import HeliosClient from '../../api/Helios';
import illustration from '../../assets/imgs/connect-offroad.png';
import homeImg from '../../assets/imgs/home_icon.svg';
import cancelX from '../../assets/imgs/Cancel_button_icon-01.png';
import i18n from '../../utils/i18n';

interface ReasonsModalProps {
	brand: string;
	dismiss: () => void;
	finishService?: boolean;
}

const ReasonsModal: FunctionComponent<ReasonsModalProps> = ({ brand, dismiss, finishService }) => {
	const [reason, setReason] = useState('');
	const {
		dispatch,
		account: { _id },
		service,
	} = useContext(Context) as AppContext;

	const history = useHistory();

	const sendCancelReason = async (reason: string): Promise<void> => {
		setReason(reason);

		if (finishService) {
			const fields = {
				_id: service._id,
				status: 'Void',
				home: {},
				schedule: '',
				cancellationNotes: '',
				specificCancellationReason: reason,
			};
			const cancelService: any = await new HeliosClient().cancelService(fields);
			if (cancelService.statusText === 'OK') {
				new HeliosClient().sendCancellationEmail({ reason });
				clearAll(dispatch);
				history.replace(`/?account=${_id}&brand=${brand}`);
			}
		} else {
			new HeliosClient().sendCancellationEmail({ reason });
			clearAll(dispatch);
			history.replace(`/?account=${_id}&brand=${brand}`);
		}
	};

	const processBtnActive = cn('modal__container__button', {
		'modal__container__button--active btn-primary': reason === 'Proceso muy complicado',
	});
	const phoneBtnActive = cn('modal__container__button', {
		'modal__container__button--active btn-primary': reason === 'Continué por teléfono',
	});
	const serviceBtnActive = cn('modal__container__button', {
		'modal__container__button--active btn-primary': reason === 'No necesito servicio',
	});

	const imgToDisplay = brand === 'connect-home-repair-ondemand-pr' ? homeImg : illustration;

	return (
		<Fragment>
			<div className="modal__container__header">
				<img onClick={dismiss} className="modal__container__header-cancel" src={cancelX} alt="Cancel" />
			</div>
			<h4 className="app-font-bold">{i18n.get('reasons_modal.title', '¡Ayúdanos a mejorar!')}</h4>
			<figure className="modal__container__figure">
				<img width="200" height="200" className="modal__container__image" src={imgToDisplay} alt="Illustration" />
			</figure>
			<h5 className="app-font-bold">{i18n.get('reasons_modal.sub_title', 'Razones para cancelar su servicio')}</h5>
			<button onClick={(): Promise<void> => sendCancelReason(CancelReasons.COMPLICATED)} className={processBtnActive}>
				{i18n.get('reasons_modal.btn_complicated', CancelReasons.COMPLICATED)}
			</button>
			<button onClick={(): Promise<void> => sendCancelReason(CancelReasons.PHONE)} className={phoneBtnActive}>
				{i18n.get('reasons_modal.btn_phone', CancelReasons.PHONE)}
			</button>
			<button onClick={(): Promise<void> => sendCancelReason(CancelReasons.NO_SERVICE)} className={serviceBtnActive}>
				{i18n.get('reasons_modal.btn_no_service', CancelReasons.NO_SERVICE)}
			</button>
		</Fragment>
	);
};

export default ReasonsModal;
