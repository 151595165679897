import React, { useContext } from 'react';
import { useParams, useHistory } from 'react-router';
import { Context } from '../../context/Context';
import { AppContext } from '../../interfaces';
import i18n from '../../utils/i18n';
import { getImagesByBrand } from '../../utils/Utils';
import HeliosClient from '../../api/Helios';

import './GeoTextPermissionScreen.scss';

const GeoTextPermissionScreen: React.FC = () => {
	// roomId is either a ObjectId of user or a Base64 hash of JSON.stringify({ fromNumber, toNumber });
	const { roomId } = useParams<{ roomId?: string }>();

	const history = useHistory();
	const { brand } = useContext(Context) as AppContext;

	const askForPermission = (): void => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				() => {
					history.push(`/geotext/map/${roomId}`);
				},
				async (error) => {
					console.error(error);
					await new HeliosClient().sendGeotextResponse(null, null, null, roomId || '', false);
					history.push('/geotext/error/location');
				}
			);
		} else {
			console.error('Dispositivo no tiene capacidades de geolocalización.');
			history.push('/geotext/error/location');
		}
	};

	return (
		<div className="geotext-permission-screen tracking-route">
			<img className="pin-logo" src={getImagesByBrand(brand).permissionPin} alt="Pin Logo" />
			<p className="message">
				{i18n.get('screens.geotext.permission.title', 'Permita a nuestra aplicación accesar su localización.')}
			</p>
			<button className="button btn-primary btn-footer" onClick={askForPermission}>
				{i18n.get('screens.geotext.permission.btn_allow', 'Permitir')}
			</button>
		</div>
	);
};

export default GeoTextPermissionScreen;
