import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import React, { FunctionComponent, useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import cn from 'classnames';
import { ReactComponent as BackBtnSvg } from '../../assets/imgs/back.svg';
import gpsIcon from '../../assets/imgs/gps.svg';
import { reverseGeocode } from '../../components/location/Geocoder';
import { updateLatLng } from '../../components/location/Utils';
import { Context } from '../../context/Context';
import { AddressType, AppContext, Locations, Location, PermissionType } from '../../interfaces';
import './PointOnMap.scss';
import { getImagesByBrand } from '../../utils/Utils';
import i18n from '../../utils/i18n';
import Header from '../../components/common/Header';

interface LocationChooseProps {
	addressType: AddressType;
}

const getInitialLocation = (locations: Locations, addressType: AddressType): Location => {
	if (addressType === AddressType.DESTINATION && locations.destination.lat) return locations.destination;
	return locations.situation;
};
const LocationPointOnMap: FunctionComponent<LocationChooseProps> = ({ addressType }): JSX.Element => {
	const { dispatch, locations } = useContext(Context) as AppContext;
	const [gettingLocation, setGettingLocation] = useState(false);
	const history = useHistory();
	const location = getInitialLocation(locations, addressType);

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.REACT_APP_GMAPS_KEY || '',
		preventGoogleFontsLoading: true,
		libraries: ['places'],
	});
	console.log('isLoaded', isLoaded);
	const showMeOnMap = async (position: Position): Promise<void> => {
		const coordinates = {
			latLng: { lat: (): number => position.coords.latitude, lng: (): number => position.coords.longitude },
		};

		await updateLatLng(coordinates, addressType, dispatch, '', reverseGeocode);
		setGettingLocation(false);
	};

	const locateMe = (): void => {
		if (!('geolocation' in navigator)) return console.log('Geolocation unavailable!');
		setGettingLocation(true);
		navigator.geolocation.getCurrentPosition(showMeOnMap, () => setGettingLocation(false), {
			enableHighAccuracy: true,
			maximumAge: 10000,
		});
	};

	const title =
		addressType === AddressType.SITUATION
			? i18n.get('screens.location.point_of_map.choose_location', 'Elige tu localización')
			: i18n.get('screens.location.point_of_map.destiny', 'Elige tu destino');
	// Use internal UI state so as to de-bounce the center changes, and only
	//  send updates to global state onDragEnd, while keeping marker centered.
	const mapRef = useRef<any>(null);
	const onLoad = (map: any): void => {
		mapRef.current = map;
	};
	const onDragEnd = async (): Promise<void> => {
		if (mapRef.current === null) return;
		const { lat, lng } = mapRef.current.getCenter().toJSON();

		updateLatLng(
			{ latLng: { lat: (): number => lat, lng: (): number => lng } },
			addressType,
			dispatch,
			null,
			reverseGeocode
		);
	};

	const setPosition = async () => {
		await onDragEnd();
		history.push('location');
	};

	return (
		<div className="point-on-map">
			<div className="header">
				<div className="navigation">
					<BackBtnSvg className="breadcrumbs__back_img svg-color" onClick={() => history.goBack()} />
				</div>
				<Header
					showLine={false}
					title={title}
					subTitle={i18n.get(
						'screens.location.point_of_map.sub_title',
						'Puedes mover el mapa y usar zoom para ajustar la localización'
					)}
				/>
			</div>
			<div className="map-container">
				<Map
					gmapsLoaded={isLoaded}
					locateMe={locateMe}
					gettingLocation={gettingLocation}
					location={location}
					onLoad={onLoad}
					onDragEnd={onDragEnd}
				/>
			</div>
			<div className="footer">
				<button className="btn-footer btn-primary" onClick={setPosition}>
					{i18n.get('screens.location.point_of_map.btn_ok', 'Ok')}
				</button>
			</div>
		</div>
	);
};

interface MapProps {
	gmapsLoaded: boolean;
	gettingLocation: boolean;
	location: Location;
	onLoad: (map: any) => void;
	onDragEnd: () => Promise<void>;
	locateMe: () => void;
}
const Map: FunctionComponent<MapProps> = ({
	gmapsLoaded,
	onLoad,
	onDragEnd,
	location,
	locateMe,
	gettingLocation,
}): JSX.Element => {
	const {
		locations: { permission },
		brand,
	} = useContext(Context) as AppContext;
	return (
		<div className="gmap">
			{gmapsLoaded && (
				<>
					<GoogleMap
						id="gmap"
						zoom={19}
						center={location}
						options={{
							fullscreenControl: false,
							disableDefaultUI: true,
							clickableIcons: false,
						}}
						onLoad={onLoad}
						onDragEnd={onDragEnd}
					/>
					{permission === PermissionType.GRANTED && (
						<button className="locate" onClick={locateMe}>
							<img className={cn({ loading: gettingLocation })} src={gpsIcon} alt="gps icon" />
						</button>
					)}
				</>
			)}
			<img className="center-pin2" src={getImagesByBrand(brand).permissionPin} alt="pin" />
		</div>
	);
};
export default LocationPointOnMap;
