import Axios, { AxiosResponse, AxiosStatic } from 'axios';
import { HELIOS_API, HELIOS_AUTO_DISPATCH_KEY } from '../utils/constants';
import { createOnSubmitServiceUrl, parseObjToQuery, parseToHelios } from '../utils/Utils';
import HttpClient from './HttpClient';
import {
	ActionType,
	AppContext,
	BranchIsoCode,
	Branches,
	Campaign,
	Drivers,
	HeliosResponse,
	Integration,
	ResponseMessage,
	ServiceType,
} from '../interfaces';

export default class HeliosClient extends HttpClient {
	constructor(axios: AxiosStatic = Axios) {
		super(axios, HELIOS_API);
	}

	async getMake(year: string): Promise<AxiosResponse<{ _id: string }[]>> {
		return this.get(`/utils/getMake/${year}`);
	}

	async getModels(year: string, make: string): Promise<AxiosResponse<{ _id: string }[]>> {
		return this.get(`/utils/getModel/${year}/${make}`);
	}

	async getCustomerFee(data?: any): Promise<AxiosResponse<ResponseMessage>> {
		return this.get(`/fees/customer${parseObjToQuery(data)}`);
	}

	async getDrivers(): Promise<AxiosResponse<ResponseMessage<Drivers>>> {
		return this.get(`/api/v1/drivers`, {
			headers: { Authorization: HELIOS_AUTO_DISPATCH_KEY },
		});
	}

	async createCustomer(data: any): Promise<AxiosResponse<ResponseMessage>> {
		return await this.post(`/dispatch/cCustomer`, data, getHeaders());
	}

	async submitService(ctx: AppContext): Promise<AxiosResponse<ResponseMessage>> {
		return new Promise((resolve) => {
			this.post('/api/v1/service-requests', parseToHelios(ctx)).then((response: any) => {
				ctx.dispatch({ type: ActionType.SET_TOKEN, data: response.data.token });
				resolve(response);
				const url = createOnSubmitServiceUrl(response.data, ctx.integrationServiceCreation);
				if (url) window.location.href = url;
			});
		});
	}

	async setPushOptInService(serviceNumber: string, pushOptIn: boolean): Promise<AxiosResponse<ResponseMessage>> {
		return this.patch(`/api/v1/service-requests/${serviceNumber}/pushOptIn`, { pushOptIn }, getHeaders());
	}

	async confirmationData(ctx: AppContext): Promise<AxiosResponse<ResponseMessage>> {
		return this.patch('/api/v1/service-requests/confirm-data', parseToHelios(ctx), getHeaders());
	}

	async getService(serviceNumber: number | string): Promise<AxiosResponse<ResponseMessage>> {
		return this.get(`/services/${serviceNumber}`, getHeaders());
	}

	async getDriver(driverId: number | string): Promise<AxiosResponse<ResponseMessage>> {
		return this.get(`/providers/drivers/driverById/${driverId}`, getHeaders());
	}

	async getDriverLocation(driverId: number | string): Promise<AxiosResponse<ResponseMessage>> {
		return this.get(`/providers/drivers/driverById/${driverId}/location`, getHeaders());
	}

	async getZones(branch: Branches | undefined): Promise<AxiosResponse<ResponseMessage>> {
		return this.get(`/utils/zones/${BranchIsoCode[branch || Branches.PR]}?populate=false`);
	}

	async submitSurvey(data: any): Promise<AxiosResponse> {
		return await this.post(`/surveys`, data, getHeaders());
	}

	async sendDriverSms(data: any): Promise<AxiosResponse<ResponseMessage>> {
		return await this.post('/api/v1/drivers/sms', data, getHeaders());
	}

	async sendCancellationEmail(data: any): Promise<AxiosResponse<ResponseMessage>> {
		return await this.post('/api/v1/service-requests/cancel', data, getHeaders());
	}

	async getAccount(accountId: string): Promise<AxiosResponse<ResponseMessage>> {
		return this.get(`/accounts/${accountId}`);
	}

	async getAccountBySalesForceId(salesForceId: string): Promise<AxiosResponse<ResponseMessage>> {
		return this.get(`/accounts/salesforce/${salesForceId}`);
	}

	async updateLocation(serviceId: string, situationAddress: any): Promise<AxiosResponse<ResponseMessage>> {
		return this.patch(`/api/v1/services/${serviceId}/locations`, { situationAddress });
	}

	async contractSearch(
		search: string,
		account?: string,
		branch?: string,
		fullName?: string,
		active?: boolean
	): Promise<AxiosResponse<ResponseMessage>> {
		return this.get(`/api/v1/dispatch/contracts/${parseObjToQuery({ branch, search, account, fullName, active })}`);
	}

	async checkLimit(data: any): Promise<AxiosResponse<ResponseMessage>> {
		return this.post('/api/v1/dispatch/contact/checklimit', data);
	}

	async cancelService(data: any): Promise<AxiosResponse<ResponseMessage>> {
		return this.post('/services/holdEdit', data);
	}

	async getClientServices(
		accountId: any,
		plate: string,
		mbi?: string,
		contractId?: string | number | null
	): Promise<AxiosResponse<ResponseMessage>> {
		return this.get(`/api/v1/dispatch/vehicle/services${parseObjToQuery({ accountId, plate, mbi, contractId })}`);
	}

	async getVehicleMemberMMM(mbi: string): Promise<AxiosResponse<ResponseMessage>> {
		return await this.get(`/api/v1/mmm/member/${mbi}/vehicle`);
	}

	async processTipsPayment(data: any): Promise<AxiosResponse<ResponseMessage>> {
		return await this.post(`/tips`, data, getHeaders());
	}

	async fetchAddress(lat: number, lng: number): Promise<string> {
		const response = await this.post('/maps/directions/getAddress', {
			coords: `${lat},${lng}`,
		});
		return response.status ? (((response.data as any)?.data || '') as string) : '';
	}

	async sendGeotextResponse(
		lat: number | null,
		lng: number | null,
		address: string | null,
		roomId: string | null,
		success: boolean,
		phoneNumber?: string
	): Promise<AxiosResponse<ResponseMessage>> {
		return await this.post('/api/v1/geotext-responses', {
			lat,
			lng,
			address,
			roomId,
			phoneNumber,
			success,
		});
	}

	async fetchServiceTypes(): Promise<AxiosResponse<ResponseMessage<ServiceType[]>>> {
		return await this.get(`/services/types/all`);
	}

	async addNoteToService(serviceId: string, body: string): Promise<AxiosResponse<ResponseMessage<ServiceType[]>>> {
		return await this.post(`/utils/addNote`, {
			serviceId,
			body,
			employeeId: 'app@connect.pr',
			identifyUserByEmail: true,
		});
	}

	async fetchAccountIntegrations(accountId: string): Promise<AxiosResponse<ResponseMessage<Integration[]>>> {
		return await this.get(`/api/v2/accounts/${accountId}/integrations`);
	}

	async udateSituationSpecification(
		serviceId: string,
		hasComment: boolean,
		imagesCount: number
	): Promise<AxiosResponse<ResponseMessage>> {
		return await this.patch(`/utils/situation-specification`, {
			serviceId,
			hasComment,
			imagesCount,
		});
	}

	async getCampaignByName(campaign: string): Promise<AxiosResponse<HeliosResponse<Campaign>>> {
		return await this.get(`/accounts/campaigns/${campaign}`);
	}
}

function getHeaders(options?: any) {
	return {
		headers: { ...options, Authorization: getTokenService() },
	};
}

function getTokenService() {
	const state = localStorage.getItem('state');
	const { token } = state ? JSON.parse(state) : null;
	return token;
}
