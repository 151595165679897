import React, { useContext, useState } from 'react';
import closeIcon from '../../assets/imgs/close_icon.svg';
import iosShareIcon from '../../assets/imgs/ios_share.svg';
import androidDotsIcon from '../../assets/imgs/android_dots_icon.svg';
import { isAndroid, isIOS } from 'react-device-detect';
import { getImagesByBrand } from '../../utils/Utils';

import './AppBanner.scss';
import { Context } from '../../context/Context';
import { AppContext } from '../../interfaces';
import i18n from '../../utils/i18n';

const AppBanner = (): JSX.Element => {
	const { brand } = useContext(Context) as AppContext;
	const [showBanner, setShowBanner] = useState(true);
	const closeBtn = () => {
		setShowBanner(false);
	};
	return (
		<div>
			{showBanner && (
				<div className="app-banner">
					<div className="app-banner__info">
						<img className="app-banner__logo" src={getImagesByBrand(brand).appIcon} alt="logo" width="50" />

						<span>
							{i18n.get('app_banner.title', 'Añade el app a la pantalla principal')}
							<br></br>
							<br></br>
							1. {i18n.get('app_banner.step_1', 'Haz clic en icono de compartir')}
							{isIOS && <img src={iosShareIcon} alt="ios_share_icon" width="16" />}
							{isAndroid && <img src={androidDotsIcon} alt="ios_share_icon" width="15" />}
							<br></br>
							2. {i18n.get('app_banner.step_2', 'Selecciona “Add to Home Screen”')}
						</span>
						<img className="app-banner__info-closeIcon" alt="closeIcon" onClick={closeBtn} src={closeIcon} width="15" />
					</div>
				</div>
			)}
		</div>
	);
};

export default AppBanner;
