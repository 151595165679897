import {
	homeCategories,
	ServiceTypesObject,
	Situation,
	ServiceCategoryInList,
	TRACKS,
	Campaign,
} from '../../interfaces';
import flatTireCsm from '../../assets/imgs/flat-tire-csm.png';
import truckCsm from '../../assets/imgs/truck-csm.png';
import gasolineCsm from '../../assets/imgs/gasoline-csm.png';
import batteryCsm from '../../assets/imgs/battery-csm.png';
import keyCsm from '../../assets/imgs/key-csm.png';

import flatTireBanesco from '../../assets/imgs/flat-tire-banesco.svg';
import flatTireBanescoSelected from '../../assets/imgs/flat-tire-banesco-selected.svg';
import truckBanesco from '../../assets/imgs/truck-banesco.svg';
import truckBanescoSelected from '../../assets/imgs/truck-banesco-selected.svg';
import gasolineBanesco from '../../assets/imgs/gasoline-banesco.svg';
import gasolineBanescoSelected from '../../assets/imgs/gasoline-banesco-selected.svg';
import batteryBanesco from '../../assets/imgs/battery-banesco.svg';
import batteryBanescoSelected from '../../assets/imgs/battery-banesco-selected.svg';
import keyBanesco from '../../assets/imgs/key-banesco.svg';
import keyBanescoSelected from '../../assets/imgs/key-banesco-selected.svg';

import { ReactComponent as tire } from '../../assets/imgs/flatTire_icon.svg';
import { ReactComponent as truck } from '../../assets/imgs/grua_icon.svg';
import { ReactComponent as gasoline } from '../../assets/imgs/gasolina_icon.svg';
import { ReactComponent as battery } from '../../assets/imgs/bateria_icon.svg';
import { ReactComponent as key } from '../../assets/imgs/cerrajeria_icon.svg';

import { ReactComponent as electricity } from '../../assets/imgs/electricity.svg';
import { ReactComponent as handyMan } from '../../assets/imgs/handyman_icon.svg';
import { ReactComponent as plumber } from '../../assets/imgs/plumbing.svg';
import { ReactComponent as fallPrevention } from '../../assets/imgs/falls.svg';
import { ReactComponent as locksmith } from '../../assets/imgs/locksmith.svg';
import { ReactComponent as pestControl } from '../../assets/imgs/fumigation.svg';
import { ReactComponent as glassworks } from '../../assets/imgs/glassware.svg';
import { ReactComponent as tech } from '../../assets/imgs/tech.svg';

import { ReactComponent as conductorDesignado } from '../../assets/imgs/conductor_designado_icon.svg';
import { ReactComponent as reparacionNeumatico } from '../../assets/imgs/reparacion_neumatico_icon.svg';
import { ReactComponent as asistenciaViajes } from '../../assets/imgs/asistencia_viajes_icon.svg';
import { ReactComponent as ambulance } from '../../assets/imgs/ambulance_icon.svg';
import { ReactComponent as glassReplacement } from '../../assets/imgs/glass_replacement_icon.svg';

import { Account } from '../../interfaces';

import i18n from '../../utils/i18n';
import { getSituationLabel } from '../../utils/Utils';
import { ACCOUNTS_TO_KEEP_SERVICE_TYPES_ON_CHECK_LIMIT, Brand } from '../../utils/constants';

const situationsByBrand = (brand: string) => {
	switch (brand) {
		case 'multiple-asistencia-en-viaje':
		case 'inspecciones-csm':
			return {
				[Situation.FLAT_TIRE]: {
					image: flatTireCsm,
					selectedImage: flatTireCsm,
				},
				[Situation.TOW_BREAKDOWN]: {
					image: truckCsm,
					selectedImage: truckCsm,
				},
				[Situation.FUEL_DELIVERY]: {
					image: gasolineCsm,
					selectedImage: gasolineCsm,
				},
				[Situation.JUMP_START]: {
					image: batteryCsm,
					selectedImage: batteryCsm,
				},
				[Situation.LOCKSMITH]: {
					image: keyCsm,
					selectedImage: keyCsm,
				},
			};
		case 'banesco':
			return {
				[Situation.FLAT_TIRE]: {
					image: flatTireBanesco,
					selectedImage: flatTireBanescoSelected,
				},
				[Situation.TOW_BREAKDOWN]: {
					image: truckBanesco,
					selectedImage: truckBanescoSelected,
				},
				[Situation.FUEL_DELIVERY]: {
					image: gasolineBanesco,
					selectedImage: gasolineBanescoSelected,
				},
				[Situation.JUMP_START]: {
					image: batteryBanesco,
					selectedImage: batteryBanescoSelected,
				},
				[Situation.LOCKSMITH]: {
					image: keyBanesco,
					selectedImage: keyBanescoSelected,
				},
			};
		default: {
			return {};
		}
	}
};

const getOtherRoadSituations = (serviceTypes: ServiceTypesObject) => [
	{
		name: Situation.AMBULANCE,
		label: i18n.get('screens.services.ambulance', getSituationLabel(Situation.AMBULANCE, serviceTypes)),
		Svg: ambulance,
		image: null,
		selectedImage: null,
	},
	{
		name: Situation.CONDUCTOR_DESIGNADO,
		label: i18n.get(
			'screens.services.conductor_designado',
			getSituationLabel(Situation.CONDUCTOR_DESIGNADO, serviceTypes)
		),
		Svg: conductorDesignado,
		image: null,
		selectedImage: null,
	},
	{
		name: Situation.COORDINACION_REPARACION_NEUMATICO,
		label: i18n.get(
			'screens.services.coordinacion_reparacion_neumatico',
			getSituationLabel(Situation.COORDINACION_REPARACION_NEUMATICO, serviceTypes)
		),
		Svg: reparacionNeumatico,
		image: null,
		selectedImage: null,
	},
	{
		name: Situation.ASISTENCIA_VIAJES_NACIONALES,
		label: i18n.get(
			'screens.services.asistencia_viajes_nacionales',
			getSituationLabel(Situation.LOCKSMITH, serviceTypes)
		),
		Svg: asistenciaViajes,
		image: null,
		selectedImage: null,
	},
	{
		name: Situation.GLASS_REPLACEMENT,
		label: i18n.get('screens.services.glass_replacement', getSituationLabel(Situation.GLASS_REPLACEMENT, serviceTypes)),
		Svg: glassReplacement,
		image: null,
		selectedImage: null,
	},
];

const getDefaultRoadSituations = (serviceTypes: ServiceTypesObject, brand: string) => [
	{
		name: Situation.FLAT_TIRE,
		Svg: tire,
		image: null,
		selectedImage: null,
		label: i18n.get('screens.services.flat_tire', getSituationLabel(Situation.FLAT_TIRE, serviceTypes)),
		...situationsByBrand(brand)[Situation.FLAT_TIRE],
	},
	{
		name: Situation.TOW_BREAKDOWN,
		Svg: truck,
		image: null,
		selectedImage: null,
		label: i18n.get('screens.services.tow_breakdown', getSituationLabel(Situation.TOW_BREAKDOWN, serviceTypes)),
		...situationsByBrand(brand)[Situation.TOW_BREAKDOWN],
	},
	{
		name: Situation.FUEL_DELIVERY,
		Svg: gasoline,
		image: null,
		selectedImage: null,
		label: i18n.get('screens.services.fuel_delivery', getSituationLabel(Situation.FUEL_DELIVERY, serviceTypes)),
		...situationsByBrand(brand)[Situation.FUEL_DELIVERY],
	},
	{
		name: Situation.JUMP_START,
		Svg: battery,
		image: null,
		selectedImage: null,
		label: i18n.get('screens.services.jump_start', getSituationLabel(Situation.JUMP_START, serviceTypes)),
		...situationsByBrand(brand)[Situation.JUMP_START],
	},
	{
		name: Situation.LOCKSMITH,
		Svg: key,
		image: null,
		selectedImage: null,
		label: i18n.get('screens.services.locksmith', getSituationLabel(Situation.LOCKSMITH, serviceTypes)),
		...situationsByBrand(brand)[Situation.LOCKSMITH],
	},
];

export const getHomeSituations = (): ServiceCategoryInList[] => [
	{
		name: homeCategories.PLUMBING,
		Svg: plumber,
		image: null,
		selectedImage: null,
		label: i18n.get('screens.services.plumbing', 'Plomería'),
	},
	{
		name: homeCategories.ELECTRICITY,
		Svg: electricity,
		image: null,
		selectedImage: null,
		label: i18n.get('screens.services.electricity', 'Electricidad'),
	},
	{
		name: homeCategories.HOME_LOCKSMITH,
		Svg: locksmith,
		image: null,
		selectedImage: null,
		label: i18n.get('screens.services.home_locksmith', 'Cerrajería'),
	},
	{
		name: homeCategories.GLASSWORKS,
		Svg: glassworks,
		image: null,
		selectedImage: null,
		label: i18n.get('screens.services.glassworks', 'Cristalería'),
	},
	{
		name: homeCategories.PEST_CONTROL,
		Svg: pestControl,
		image: null,
		selectedImage: null,
		label: i18n.get('screens.services.pest_control', 'Fumigación'),
	},
	{
		name: homeCategories.FALL_PREVENTION,
		Svg: fallPrevention,
		image: null,
		selectedImage: null,
		label: i18n.get('screens.services.fall_prevention', 'Prevención de caídas'),
	},
	{
		name: homeCategories.TECH_ASSISTANCE,
		Svg: tech,
		image: null,
		selectedImage: null,
		label: i18n.get('screens.services.tech_assistance_remote', 'Asistencia tecnológica'),
	},
	{
		name: homeCategories.HANDYMAN,
		Svg: handyMan,
		image: null,
		selectedImage: null,
		label: i18n.get('screens.services.handyman', homeCategories.HANDYMAN),
	},
];

export const getSituationsToDisplay = (
	account: Account,
	brand: string,
	currentTrack: string,
	serviceTypes: ServiceTypesObject,
	previousAccount?: Account,
	campaign?: Campaign | null
) => {
	const isHome = currentTrack === TRACKS.HOME;
	let situationsToDisplay = isHome ? getHomeSituations() : getDefaultRoadSituations(serviceTypes, brand);

	if (isHome) {
		const allowedServicesByAccount = getServicesAllowed(account, brand, previousAccount, campaign);
		const filteredCategories = new Set();
		Object.values(serviceTypes).forEach((serviceType) => {
			if (allowedServicesByAccount.includes(serviceType.name)) {
				filteredCategories.add(serviceType.category);
			}
		});
		return situationsToDisplay.filter((situation) => filteredCategories.has(situation.name));
	} else {
		const pwaRoadServicesAllowedByAccount = account.pwaRoadServicesAllowed;

		if (pwaRoadServicesAllowedByAccount?.length) {
			const otherSituationsToDisplay = getOtherRoadSituations(serviceTypes).filter((situation) =>
				pwaRoadServicesAllowedByAccount.includes(situation.name)
			);
			situationsToDisplay = situationsToDisplay.concat(otherSituationsToDisplay);
		}
		return situationsToDisplay;
	}
};

export const getServiceTypesFromCategory = (
	account: Account,
	previousAccount: Account,
	brand: string,
	serviceTypes: ServiceTypesObject,
	category: homeCategories
): string[] => {
	const allowedServicesByAccount = getHomeServiceTypesFromAccount(account, brand, previousAccount);
	const currentServiceTypes = Object.values(serviceTypes).filter(
		(serviceType) => allowedServicesByAccount.includes(serviceType.name) && serviceType.category === category
	);
	return currentServiceTypes.map((serviceType) => serviceType.name);
};

/**
 * Given the current account, the previous account and the brand, it returns the service types that should be displayed in the Home screen.
 */
export const getHomeServiceTypesFromAccount = (
	account: Account,
	brand: string,
	previousAccount?: Account
): string[] => {
	let accountToGetServiceTypesFrom = account;
	// Se muestran los service types de la cuenta anterior si el brand actual es HOME_ON_DEMAND_PR y el usuario fue redirigido a Home On Demand por una cuenta (e.g. MMM),
	// y a esta cuenta anterior se le permite que los service types que se muestren sean los mismos que en el flujo de la cuenta anterior.
	if (
		brand === Brand.HOME_ON_DEMAND_PR &&
		ACCOUNTS_TO_KEEP_SERVICE_TYPES_ON_CHECK_LIMIT.includes(previousAccount?.name!) &&
		previousAccount
	) {
		accountToGetServiceTypesFrom = previousAccount;
	}
	return accountToGetServiceTypesFrom.pwaHomeServicesAllowed || accountToGetServiceTypesFrom.homeServicesAllowed || [];
};

export const getServicesAllowed = (
	account: Account,
	brand: string,
	previousAccount?: Account,
	campaign?: Campaign | null
) => {
	if (campaign && 'serviceTypes' in campaign && campaign.serviceTypes) {
		return campaign.serviceTypes?.map((service) => service.name);
	}
	return getHomeServiceTypesFromAccount(account, brand, previousAccount);
};
