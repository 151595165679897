import React, { FunctionComponent } from 'react';
import { InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/dayjs';

import { ReactComponent as CalendarSvg } from '../../../assets/imgs/agenda_icon.svg';
import i18n from '../../../utils/i18n';
import { ReactComponent as ClockSvg } from '../../../assets/imgs/tiempo_icon.svg';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
interface HomeAvailabilityProps {
	selectedDate: number;
	availability: string;
	setAvailability: (args: string) => void;
	handleDateChange: (args: MaterialUiPickersDate) => void;
}

const HomeAvailability: FunctionComponent<HomeAvailabilityProps> = ({
	selectedDate,
	availability,
	setAvailability,
	handleDateChange,
}) => {
	const today = new Date();
	const AVAILABILITY_OPTIONS = [
		{ value: 'Durante la mañana', text: i18n.get('bottom_sheet.lbl_morning', 'Durante la mañana') },
		{ value: 'Durante la tarde', text: i18n.get('bottom_sheet.lbl_afternoon', 'Durante la tarde') },
		{ value: 'Disponible todo el día', text: i18n.get('bottom_sheet.lbl_all_day', 'Disponible todo el día') },
	];

	return (
		<>
			<div className="container__title">
				{i18n.get('bottom_sheet.home_availability', '¿Qué día y horario estás disponible para recibir la visita?')}
			</div>
			<div className="container__date-time">
				<div className="container__date-time-info">
					<div className="container__date-time-label">
						{/* TODO: Change template to match branding colors */}
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<DatePicker
								format="MM/DD/YYYY"
								autoOk={true}
								disablePast
								disableToolbar
								inputVariant="outlined"
								label={i18n.get('bottom_sheet.home.datelbl', 'Fecha')}
								value={selectedDate}
								onChange={handleDateChange}
								minDate={today.setDate(today.getDate() + 2)}
								InputProps={{
									startAdornment: <CalendarSvg className="svg-color" />,
								}}
							/>
						</MuiPickersUtilsProvider>
					</div>
				</div>
			</div>
			<TextField
				select
				onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setAvailability(event.target.value)}
				className="client__form__inputs"
				label={i18n.get('bottom_sheet.home.availability', 'Disponibilidad')}
				name="availability"
				variant="outlined"
				fullWidth
				value={availability}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<ClockSvg className="svg-color" />
						</InputAdornment>
					),
				}}
			>
				{(AVAILABILITY_OPTIONS || []).map((option) => (
					<MenuItem key={option.value} value={option.value}>
						{option.text}
					</MenuItem>
				))}
			</TextField>
		</>
	);
};

export default HomeAvailability;
