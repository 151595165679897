import React from 'react';
import { useLottie } from 'lottie-react';
import receivedServiceAnimation from '../../../lotties/tipAnimation.json';
import i18n from '../../../utils/i18n';
import './Animation.scss';

const TipAnimation = (): JSX.Element => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: receivedServiceAnimation,
	};

	const { View } = useLottie(defaultOptions);

	return (
		<div className="tracking-animation">
			<div className="lottie-container tip-animation">
				<div>{View}</div>
			</div>
			<div className="text-animation">
				<p>
					{i18n.get(
						'tracking.animations.tip.title',
						'Cuentas con la opción de brindar una propina al proveedor una vez finalizada la asistencia.'
					)}
				</p>
			</div>
		</div>
	);
};

export default TipAnimation;
