import React, { FunctionComponent, useState, useContext } from 'react';
import HeliosClient from '../../../api/Helios';
import './NoteModal.scss';
import { Context } from '../../../context/Context';
import { AppContext } from '../../../interfaces';
import { AxiosResponse } from 'axios';
import i18n from '../../../utils/i18n';

interface DriverNoteModalProps {
	dismiss: () => void;
}

export const sendDriverSms = async (data: any): Promise<AxiosResponse> => {
	return await new HeliosClient().sendDriverSms(data);
};

const DriverNoteModal: FunctionComponent<DriverNoteModalProps> = ({ dismiss }): JSX.Element => {
	const {
		service: {
			serviceNumber,
			branch,
			driver: { phone },
		},
	} = useContext(Context) as AppContext;
	const [note, setNote] = useState<string>('');

	const handleSendButton = async (): Promise<void> => {
		const smsObject = {
			message: `No Reply: #${serviceNumber}: ${note}`,
			serviceNumber,
			phone: phone,
			branch: branch,
		};
		const response = await sendDriverSms(smsObject);
		if (response.data.status === true) dismiss();
	};

	return (
		<div className="note-modal">
			<div className="header">
				<div>{i18n.get('note_modal.title', 'Instrucciones para chofer')}</div>
				{!note ? (
					<span onClick={dismiss}>X</span>
				) : (
					<button onClick={handleSendButton}>{i18n.get('note_modal.btn_send', 'Enviar')}</button>
				)}
			</div>
			<div className="content">
				<textarea value={note} onChange={({ target: { value } }): void => setNote(value)}></textarea>
			</div>
		</div>
	);
};

export default DriverNoteModal;
