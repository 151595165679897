import React, { FC } from 'react';
import { Snackbar, Button } from '@material-ui/core';
import { register } from '../serviceWorker';
import { extractDomain, getCorporateAccount } from '../utils/Utils';
import { CORPORATE_ACCOUNTS } from '../utils/constants';
import { CorporateAccount } from '../interfaces';
import i18n from '../utils/i18n';

const ServiceWorkerWrapper: FC = () => {
	const [showReload, setShowReload] = React.useState(false);
	const [installingWorker, setInstallingWorker] = React.useState<ServiceWorker | null>(null);
	const domainInfo: CorporateAccount = getCorporateAccount(extractDomain(window.location.href));
	const onSWUpdate = (registration: ServiceWorkerRegistration) => {
		setShowReload(true);
		setInstallingWorker(registration.installing);
	};

	React.useEffect(() => {
		// Register only to Mazda, Acura, Honda
		if (domainInfo.status && CORPORATE_ACCOUNTS.includes(domainInfo.brand)) {
			register({ onUpdate: onSWUpdate });
		}
	}, [domainInfo.brand, domainInfo.status]);

	const reloadPage = () => {
		installingWorker?.postMessage({ type: 'SKIP_WAITING' });
		setShowReload(false);
		window.location.reload();
	};

	return (
		<Snackbar
			open={showReload}
			message={i18n.get(
				'service_worker_wrapper.message',
				'Nueva versión disponible. Cerrar y abrir la aplicación nuevamente.'
			)}
			onClick={reloadPage}
			data-test-id="screens-new-version-snackbar"
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			action={
				<Button color="inherit" size="small" onClick={reloadPage}>
					{i18n.get('service_worker_wrapper.btn_ok', 'Ok')}
				</Button>
			}
		/>
	);
};

export default ServiceWorkerWrapper;
