import React, { FunctionComponent } from 'react';
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/dayjs';

import { ReactComponent as ClockSvg } from '../../../assets/imgs/tiempo_icon.svg';
import { ReactComponent as CalendarSvg } from '../../../assets/imgs/agenda_icon.svg';
import i18n from '../../../utils/i18n';

interface AvailabilityProps {
	displayButtons: boolean;
	isScheduledService: boolean;
	etaFormatted: string;
	selectedDate: any;
	selectedTime: any;
	setIsScheduledService: (args: boolean) => void;
	handleDateChange: (args: any) => void;
	handleTimeChange: (args: any) => void;
}

const Availability: FunctionComponent<AvailabilityProps> = ({
	displayButtons,
	isScheduledService,
	etaFormatted,
	selectedDate,
	selectedTime,
	setIsScheduledService,
	handleDateChange,
	handleTimeChange,
}) => {
	return (
		<>
			<div className="title">{i18n.get('bottom_sheet.availability.title', '¿Cuándo deseas recibir el servicio?')}</div>
			{displayButtons && (
				<div className="container__btn-container">
					<button
						onClick={(): void => setIsScheduledService(false)}
						className={`${isScheduledService ? 'container__btn-container-disable' : 'btn-primary'}`}
					>
						{i18n.get('bottom_sheet.availability.btn_now', 'Ahora')}
					</button>
					<button
						onClick={(): void => setIsScheduledService(true)}
						className={`${!isScheduledService ? 'container__btn-container-disable' : 'btn-primary'}`}
					>
						{i18n.get('bottom_sheet.availability.btn_scheduled', 'Agendado')}
					</button>
				</div>
			)}
			{!isScheduledService ? (
				<div className="container__clock">
					<div className="container__clock-info">
						<ClockSvg className="svg-color" />
						<div className="container__clock-label">
							{i18n.get('bottom_sheet.availability.estimated_time', 'Tiempo Estimado')}
						</div>
					</div>
					<div>
						{etaFormatted} {i18n.get('bottom_sheet.availability.eta_unit', 'Minutos')}
					</div>
				</div>
			) : (
				<div className="container__date-time">
					<div className="container__date-time-info">
						<CalendarSvg className="svg-color" />
						<div className="container__date-time-label">
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<DatePicker
									format="MM/DD/YYYY"
									autoOk={true}
									disablePast={true}
									disableToolbar={true}
									label=""
									value={selectedDate}
									onChange={handleDateChange}
									className="picker_field"
								/>
							</MuiPickersUtilsProvider>
						</div>
					</div>

					<div className="container__date-time-info">
						<ClockSvg className="svg-color" />
						<div className="container__date-time-label">
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<TimePicker
									label=""
									autoOk={true}
									value={selectedTime}
									onChange={handleTimeChange}
									className="picker_field"
								/>
							</MuiPickersUtilsProvider>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Availability;
