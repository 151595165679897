import React, { useContext, Fragment, FunctionComponent } from 'react';

import { Context } from '../../context/Context';
import { ContactPhone, AppContext } from '../../interfaces';
import { customerServicePhones, getContactPhone, getServiceTypeContactPhone } from '../../utils/Utils';
import { MMM, MMM_EMPLEADOS, VANTI, ASC } from '../../utils/constants';
import i18n from '../../utils/i18n';

import { ReactComponent as BackBtnSvg } from '../../assets/imgs/back.svg';
import { ReactComponent as PhoneIconSvg } from '../../assets/imgs/call.svg';
import whatsappIcon from '../../assets/imgs/whatsapp.svg';

interface HelpModalProps {
	dismiss: () => void;
}

const HelpModal: FunctionComponent<HelpModalProps> = ({ dismiss }) => {
	const { brand, account, service, currentTrack } = useContext(Context) as AppContext;
	const contactPhone: ContactPhone = getContactPhone(brand, account);
	let phone = contactPhone?.phone;

	if (brand === MMM) {
		phone = service?._id
			? getServiceTypeContactPhone(MMM, service.serviceType || currentTrack)
			: customerServicePhones[brand].phone;
	} else if (brand === MMM_EMPLEADOS) {
		phone = getServiceTypeContactPhone(MMM_EMPLEADOS, service.serviceType || currentTrack);
	}

	const isWhatsAppEnabled = ![MMM, MMM_EMPLEADOS, VANTI, ASC].includes(brand);

	return (
		<Fragment>
			<div className="modal__container__header-help" role="button">
				<BackBtnSvg onClick={dismiss} className="svg-color modal__container__header-arrow" />
			</div>
			<p className="app-font-light text-left">{i18n.get('help_modal.sub_title', 'Solicitar ayuda por teléfono.')}</p>
			{isWhatsAppEnabled && (
				<a
					className="modal__container--text-help"
					href={contactPhone?.urlWhatsapp ? contactPhone?.urlWhatsapp : `https://wa.me/${contactPhone?.whatsapp}`}
				>
					<div className="modal__option border-light-primary-color">
						<img className="modal__container__help-image" src={whatsappIcon} width="25" height="25" alt="phone" />
						<p>{i18n.get('screens.help.btn_chat', 'Chat')}</p>
					</div>
				</a>
			)}
			<a className="modal__container--text-help" href={`tel:${phone}`}>
				<div className="modal__option border-light-primary-color">
					<PhoneIconSvg className="modal__container__help-image svg-color-stroke" />
					<p>{i18n.get('screens.help.btn_call', 'Llamar')}</p>
				</div>
			</a>
		</Fragment>
	);
};

export default HelpModal;
