import React, { useContext, useEffect, useCallback, FunctionComponent, useState } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import cn from 'classnames';
import { Context } from '../../context/Context';
import HeliosClient from '../../api/Helios';
import { AppContext } from '../../interfaces';
import { getImagesByBrand, getRedirectPathByAccount } from '../../utils/Utils';
import './LandingPage.scss';
import {
	setActionType,
	setBrand,
	setDestinationCoords,
	setSecondStopClientInfo,
	setServiceData,
	setSituationCoords,
	selectService,
	setAccount,
	setuserExternalParams,
	setServiceTypes,
	setToken,
	setProviderCoords,
	setPhoneNumber,
} from '../../context/actions';
import { GREYLABEL_BRANDS, HIDE_SPLASH_LOGO_WHEN_WEBVIEW_BRANDS } from '../../utils/constants';
import { getRedirectPath } from './LandingPage.utils';

const TIMER_TO_FETCH = 3000;
interface RouteParams {
	id?: string; // optional because might be for GeoText
}

/**
 * The intention of this component is to be a loading-placeholder
 * while it sets up the white-labeling infrastructure and
 * where to redirect the user based on its query parameters.
 */
const LandingPage: FunctionComponent = (): JSX.Element => {
	const [visible, setVisible] = useState<boolean>(false);
	const location = useLocation();
	const params = useParams<RouteParams>();
	const history = useHistory();
	const { dispatch, brand, userExternalParams } = useContext(Context) as AppContext;

	const getService = useCallback(
		async (serviceId: string, queryParams): Promise<void> => {
			const helios = new HeliosClient();
			const {
				data: { data: service },
			} = await helios.getService(serviceId);

			const {
				data: { data: serviceTypes },
			} = await helios.fetchServiceTypes();

			const redirectQueryParam: string = queryParams.get('redirect');

			setServiceTypes(dispatch, serviceTypes);
			setSecondStopClientInfo(service, dispatch);
			setProviderCoords(service.locations.provider, dispatch);
			setSituationCoords(service.locations.situation, dispatch);
			setDestinationCoords(service.locations.destination, dispatch);
			setServiceData(service, dispatch, redirectQueryParam);
			selectService(service.situation, dispatch);

			if (service && service.sfIdAccount) {
				const {
					data: { data: account },
				} = await helios.getAccountBySalesForceId(service.sfIdAccount);
				setAccount(account, dispatch);
			}

			let redirectPath = getRedirectPath(queryParams.get('type'), redirectQueryParam, service.status, service._id);

			redirectPath = getRedirectPathByAccount(service.sfIdAccount, redirectPath, service.status);

			history.replace(`/${redirectPath}`);
		},
		[history, dispatch]
	);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		setBrand(queryParams.get('account'), dispatch);
		setToken(queryParams.get('t'), dispatch);
		setPhoneNumber(queryParams.get('phoneNumber') || '', dispatch);

		const isFromWebview = queryParams.get('isFromWebview');
		isFromWebview && setuserExternalParams(null, null, true, null, dispatch);

		setVisible(true);

		let timer: NodeJS.Timer | undefined;
		// if its for second stop, get service information
		if (params.id) {
			const serviceId = params.id;
			setActionType(queryParams.get('type') || '', dispatch);
			timer = setTimeout(() => {
				getService(serviceId, queryParams);
			}, TIMER_TO_FETCH);
		} else {
			history.replace(queryParams.get('redirect') || '');
		}

		return (): void => timer && clearTimeout(timer);
		// eslint-disable-next-line
	}, []);

	const classname = cn({
		hidden: !visible,
		'landing-page': visible,
	});

	const hideLogo = userExternalParams?.isFromWebview && HIDE_SPLASH_LOGO_WHEN_WEBVIEW_BRANDS.includes(brand);
	return (
		<div
			className={cn(`tracking-route splash-background-color ${classname}`, {
				'landing-page__co-brand': GREYLABEL_BRANDS.includes(brand),
			})}
		>
			{GREYLABEL_BRANDS.includes(brand) && (
				<>
					<figure className="landing-page__logo">
						<img
							width="200"
							className="landing-page__logo"
							src={getImagesByBrand('connect').logoDark}
							alt="Connect Logo"
						/>
					</figure>
					<div className="landing-page__separator"></div>
				</>
			)}
			{!hideLogo ? (
				<figure className="landing-page__logo">
					<img width="300" src={getImagesByBrand(brand).logoDark} alt="logo" />
				</figure>
			) : null}
			{!GREYLABEL_BRANDS.includes(brand) && (
				<div className={`landing-page__footer ${hideLogo ? 'hidden-logo' : ''}`}>
					<div className="landing-page__loader loader-color border-right-transparent-color" />
				</div>
			)}
		</div>
	);
};

export default LandingPage;
