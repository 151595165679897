import { useLoadScript } from '@react-google-maps/api';
import React, { FunctionComponent, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import Breadcrumbs from '../../components/common/Breadcrumbs';
import LocationBottomSheet from '../../components/location/BottomSheet';
import { reverseGeocode } from '../../components/location/Geocoder';
import LocationMap from '../../components/location/Map';
import LocationSearch from '../../components/location/Search';
import { updateLatLng } from '../../components/location/Utils';
import { Context } from '../../context/Context';
import { AddressType, AppContext, Situation } from '../../interfaces';
import i18n from '../../utils/i18n';
import './Location.scss';
import Header from '../../components/common/Header';

const LIBRARIES: any = ['places'];

const LocationScreen: FunctionComponent = (): JSX.Element => {
	const { situation, dispatch, locations, currentTrack } = useContext(Context) as AppContext;

	const [gettingLocation, setGettingLocation] = useState(false);

	const {
		location: { pathname },
	} = useHistory();
	const locationConfirmed = pathname.includes('/summary');

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GMAPS_KEY || '',
		preventGoogleFontsLoading: true,
		libraries: LIBRARIES,
		version: '3',
	});

	const showMeOnMap = async (position: Position): Promise<void> => {
		const coordinates = {
			latLng: { lat: (): number => position.coords.latitude, lng: (): number => position.coords.longitude },
		};
		await updateLatLng(coordinates, AddressType.SITUATION, dispatch, null, reverseGeocode);
	};

	const locateMe = (): void => {
		if (!('geolocation' in navigator)) return console.log('Geolocation unavailable!');

		setGettingLocation(true);
		navigator.geolocation.getCurrentPosition(showMeOnMap, () => setGettingLocation(false), {
			enableHighAccuracy: true,
			maximumAge: 10000,
		});
	};

	const hasTow = situation === Situation.TOW_BREAKDOWN;

	const getlocationSearchButtons = (): JSX.Element | null => {
		if (isLoaded && !locationConfirmed) {
			return (
				<>
					<LocationSearch currentLocation={locations.situation} addressType={AddressType.SITUATION} />
					{hasTow && <LocationSearch currentLocation={locations.destination} addressType={AddressType.DESTINATION} />}
				</>
			);
		}
		return null;
	};

	return (
		<div className="location-screen">
			<Breadcrumbs />
			<Header title={i18n.get(`screens.location.title.${currentTrack}`, '¿En dónde necesitas el servicio?')} />
			<div className="map-container body-container">
				{getlocationSearchButtons()}
				<LocationMap
					locateMe={locateMe}
					gettingLocation={gettingLocation}
					locationConfirmed={locationConfirmed}
					hidden={false}
					mapLoaded={isLoaded}
				/>
			</div>

			<LocationBottomSheet locationConfirmed={locationConfirmed} hidden={false} />
		</div>
	);
};

export default LocationScreen;
