import React from 'react';
import cn from 'classnames';
import i18n from '../../../utils/i18n';

interface ChoiceButtonsProps {
	onChange: (value: boolean) => void;
	value?: boolean;
}

const YesNoInput: React.FC<ChoiceButtonsProps> = ({ onChange, value }) => {
	return (
		<>
			<button
				onClick={() => onChange(true)}
				className={cn('survey-container__btn survey-container__btn-round', {
					'btn-primary': value === true,
					'btn-outline-primary': value === false || value === undefined,
				})}
			>
				{i18n.get('survey.choice.btn_yes', 'Sí')}
			</button>
			<button
				onClick={() => onChange(false)}
				className={cn('survey-container__btn survey-container__btn-round', {
					'btn-primary': value === false,
					'btn-outline-primary': value === true || value === undefined,
				})}
			>
				{i18n.get('survey.choice.btn_no', 'No')}
			</button>
		</>
	);
};

export default YesNoInput;
