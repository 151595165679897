import { GoogleMap, InfoBox, Marker, Polyline } from '@react-google-maps/api';
import React, { FunctionComponent, useCallback, useContext, useState, useEffect } from 'react';
import destinationPin from '../../assets/imgs/destination_pin.png';
import { Context } from '../../context/Context';
import { useDrivers } from '../../hooks/useDrivers';
import { ActionType, AppContext, DispatchFunction } from '../../interfaces';
import { getImagesByBrand } from '../../utils/Utils';
import { MemoizedDirections, MemoizedDirectionsRenderer } from '../common/DirectionRenderer';
import { setSituationCoords } from '../../context/actions';
import './Map.scss';
import { reverseGeocode } from './Geocoder';
import i18n from '../../utils/i18n';

const MINUTES_BUFFER = 10;

interface LocationMapProps {
	locateMe: () => void;
	gettingLocation: boolean;
	mapLoaded: boolean;
	hidden: boolean;
	locationConfirmed: boolean;
}

export const setDistance = (dispatch: DispatchFunction, result: any): void => {
	dispatch({ type: ActionType.SET_DISTANCE, data: +result });
};

const LocationMap: FunctionComponent<LocationMapProps> = ({ mapLoaded, hidden, locationConfirmed }): JSX.Element => {
	const { locations, dispatch, brand } = useContext(Context) as AppContext;
	const [direction, setDirection] = useState();
	const [pathway, setPathway]: any = useState<{ lat: () => number; lng: () => number }[]>([]);
	const drivers = useDrivers();
	const coords = { lat: locations?.situation?.lat, lng: locations?.situation?.lng };

	useEffect(() => {
		setSituationCoords(
			{ lat: locations?.situation?.lat, lng: locations?.situation?.lng, placeId: locations?.situation?.placeId },
			dispatch
		);
		setCurrentPosition();
		// eslint-disable-next-line
	}, []);

	const setCurrentPosition = () => {
		if (!coords.lat && !coords.lng) {
			navigator.geolocation.getCurrentPosition(
				async (position: any) => {
					const location = await reverseGeocode(position.coords.latitude, position.coords.longitude);
					setSituationCoords(
						{
							lat: position.coords.latitude,
							lng: position.coords.longitude,
							placeId: location.placeId,
						},
						dispatch
					);
				},
				() => null,
				{
					enableHighAccuracy: true,
					maximumAge: 10000,
				}
			);
		}
	};

	const onResult = useCallback(
		async (result: any): Promise<void> => {
			setDirection(result);
			setPathway(result?.routes[0].overview_path || []); // assign empty array to prevent
			setDistance(dispatch, result.routes[0]?.legs[0]?.distance?.value);
		},
		[dispatch]
	);

	const renderInfoBox = (): JSX.Element => {
		if (drivers.length > 0 && drivers[0]?.eta) {
			const eta = Math.round(drivers[0]?.eta / 60) + MINUTES_BUFFER;
			const etaFormated = isNaN(eta) || -1 ? 35 : eta;
			return (
				<InfoBox position={coords} options={{ closeBoxURL: '', enableEventPropagation: true }}>
					<div className="info-box">
						<div className="info-box__minutes-container">
							<span className="info-box__minutes-container__minutes">
								{etaFormated} <br />
								{i18n.get('location.map.eta_unit', 'min')}
							</span>
						</div>
						<span className="info-box__text">{i18n.get('location.map.info_text', 'Estimado')}</span>
					</div>
				</InfoBox>
			);
		} else {
			return <></>;
		}
	};

	return (
		<div hidden={hidden} className="map" id="map-container">
			{mapLoaded && (
				<>
					<GoogleMap
						id="map"
						zoom={17}
						center={coords}
						options={{
							fullscreenControl: false,
							disableDefaultUI: true,
							clickableIcons: false,
							keyboardShortcuts: false,
						}}
					>
						{locationConfirmed && renderInfoBox()}

						{drivers.length > 0 &&
							drivers.map((d) => (
								<Marker
									key={d._id}
									position={{ lat: d.lat, lng: d.lng }}
									icon={{
										url: getImagesByBrand(brand).vehicleVial,
										scaledSize: new window.google.maps.Size(40, 40),
									}}
								/>
							))}
						{locations?.situation?.lat && (
							<div className="loading-pin">
								<Marker
									position={pathway?.[0] || locations.situation}
									icon={{
										anchor: new window.google.maps.Point(27.5, 27.5),
										url: getImagesByBrand(brand).permissionPin,
										scaledSize: new window.google.maps.Size(40, 40),
									}}
								/>
							</div>
						)}
						{locations?.destination?.lat && (
							<Marker
								position={pathway?.[pathway?.length - 1] || locations.destination}
								icon={{
									anchor: new window.google.maps.Point(10, 10),
									url: destinationPin,
									scaledSize: new window.google.maps.Size(40, 40),
								}}
							/>
						)}
						{locations?.situation?.lat && locations.destination.lat && (
							<>
								<MemoizedDirections
									onResult={onResult}
									origin={`${locations.situation.lat},${locations.situation.lng}`}
									destination={`${locations.destination.lat},${locations.destination.lng}`}
								/>
								<Polyline
									path={pathway}
									options={{ strokeColor: '#585858', strokeWeight: 12, strokeOpacity: 0.15 }}
								></Polyline>
								<MemoizedDirectionsRenderer directions={direction} />
							</>
						)}
					</GoogleMap>
				</>
			)}
		</div>
	);
};

export default LocationMap;
