import React, { useContext, useState } from 'react';
import shareBtn from '../../assets/imgs/share_button.svg';
import CancelServiceModal from '../common/CancelModal';
import ReasonsModal from '../common/ReasonsModal';
import './Header.scss';
import Modal from '../../components/common/Modal';
import ShareModal from './ShareModal';
import { AppContext, TripStatus } from '../../interfaces';
import Breadcrumbs from '../../components/common/Breadcrumbs';
import Steps from './Steps';
import { Context } from '../../context/Context';

interface TrackingHeaderProps {
	title: string | React.ReactNode;
	subtitle?: string;
	finishService?: boolean;
	currentStatus?: TripStatus;
}

const TrackingHeader = (props: TrackingHeaderProps): JSX.Element => {
	const { brand, userExternalParams } = useContext(Context) as AppContext;

	const [showModal, setshowModal] = useState(false);
	const [cancelModal, setCancelModal] = useState(true);
	const [reasonsModal, setReasonsModal] = useState(false);
	const [showShareModal, setShowShareModal] = useState<boolean>(false);

	const onCancelServiceClick = (): void => {
		setCancelModal(false);
		setReasonsModal(true);
	};

	const onDimissClick = (): void => {
		setshowModal(false);
		setCancelModal(false);
		setReasonsModal(false);
	};

	const dismissShareModal = (): void => setShowShareModal(false);
	return (
		<div className="tracking-header">
			<Breadcrumbs />
			<Steps currentStatus={props.currentStatus} />
			<div className="upper-container">
				<div className="top-header-div">
					<h2 className="title">{props.title}</h2>
					{props.subtitle && <div className="subtitle">{props.subtitle}</div>}
				</div>

				<div className="actions">
					{!userExternalParams?.isFromWebview && (
						<button className="share" onClick={(): void => setShowShareModal(true)}>
							<img src={shareBtn} alt="share" />
						</button>
					)}
				</div>
			</div>
			{showModal && (
				<Modal dismiss={onDimissClick}>
					{cancelModal && (
						<CancelServiceModal onCancelServiceClick={onCancelServiceClick} brand={brand} dismiss={onDimissClick} />
					)}
					{reasonsModal && <ReasonsModal brand={brand} dismiss={onDimissClick} finishService={props.finishService} />}
				</Modal>
			)}
			{showShareModal && (
				<Modal windowClassName={'modal__window--share'} dismiss={dismissShareModal}>
					<ShareModal dismiss={dismissShareModal} />
				</Modal>
			)}
		</div>
	);
};

export default TrackingHeader;
