import { validateServiceTracking } from '../../utils/Utils';

export function getRedirectPath(
	typeQueryParam: string,
	redirectQueryParam: string,
	serviceStatus: string,
	serviceId: string
): string {
	if (typeQueryParam === 'confirmData') {
		if (serviceStatus === 'Finished') return 'survey';
		if (serviceStatus === 'Active') return 'tracking/loading';
		return redirectQueryParam;
	}

	if (serviceStatus === 'Finished' && ['tracking', 'tracking/permission', 'survey'].includes(redirectQueryParam)) {
		return 'survey';
	}

	if (['HoldInspection', 'Queued', 'Active'].includes(serviceStatus) && redirectQueryParam === 'comments') {
		// This happens when the user requested a home service, we want the user to add comments and pictures before the service starts
		sessionStorage.setItem('isRedirectedFromComments', 'true');
		return redirectQueryParam;
	}

	if (validateServiceTracking(serviceId)) {
		return 'tracking/loading';
	}

	return redirectQueryParam;
}
