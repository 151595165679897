import React, { useContext, FunctionComponent, useEffect, useState } from 'react';
import { Context } from '../../../context/Context';
import { AppContext } from '../../../interfaces';
import { getSituationLabel } from '../../../utils/Utils';
import driverPlaceholder from '../../../assets/imgs/driver_placeholder.png';
import './Details.scss';
import HeliosClient from '../../../api/Helios';
import S3ManagerService from '../../../api/S3ManagerService';
import i18n from '../../../utils/i18n';

const heliosClient = new HeliosClient();
const s3ManagerService = new S3ManagerService();

const DriverDetails: FunctionComponent = (): JSX.Element => {
	const { service, serviceTypes } = useContext(Context) as AppContext;
	const [profileImage, setProfileImage] = useState<string>(driverPlaceholder);
	const [vehicleImage, setVehicleImage] = useState<string | null>(null);

	useEffect(() => {
		const fetchDriver = async (): Promise<void> => {
			try {
				const {
					data: { data },
				} = await heliosClient.getDriver(service.driver.id);

				if (data.employeeImage) {
					const { status, data: signedEmployeeImageData } = await s3ManagerService.generateDownloadUrl(
						data.employeeImage
					);
					if (status === 200) {
						setProfileImage(signedEmployeeImageData.signedUrl);
					}
				}
				if (data.vehicleImage) {
					const { status, data: signedVehicleImageData } = await s3ManagerService.generateDownloadUrl(
						data.vehicleImage
					);
					if (status === 200) {
						setVehicleImage(signedVehicleImageData.signedUrl);
					}
				}
			} catch (error) {
				console.log('Error Getting driver: ', error);
			}
		};

		if (service.driver.id) {
			fetchDriver();
		}
	}, [service.driver.id]);

	return (
		<div className="driver-details">
			<div className="driver">
				{service.driver?.id ? (
					<>
						<div className="photos">
							<div
								className="driver-photo"
								style={{
									backgroundImage: `url("${profileImage}")`,
								}}
							/>
							<div
								className="vehicle-photo"
								style={{
									backgroundImage: `url("${vehicleImage}")`,
								}}
							/>
						</div>
						<span className="name">{service?.driver?.name}</span>
					</>
				) : null}
			</div>
			<div className="service">
				<span>{i18n.get('tracking.driver.detail.lbl_service', 'Servicio')}</span>
				<span>{getSituationLabel(service.situation, serviceTypes)}</span>
			</div>
		</div>
	);
};

export default DriverDetails;
