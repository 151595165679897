import { InfoBox, Polyline } from '@react-google-maps/api';
import React, { FunctionComponent } from 'react';
import { Driver, Location } from '../../../../interfaces';
import { MemoizedDirections } from '../../../common/DirectionRenderer';

import i18n from '../../../../utils/i18n';
import { Brand } from '../../../../utils/constants';
import { strokeColorBrand } from '../utils';
import { useOnRouteTracking } from './use-on-route-tracking';

interface OnRouteProps {
	map: google.maps.Map | null;
	pathway: google.maps.LatLng[];
	onPathwayUpdate: (pathway: google.maps.LatLng[]) => void;
	driver: Driver;
	situationLocation: Location;
	brand: Brand;
}

const OnRoute: FunctionComponent<OnRouteProps> = ({
	map,
	pathway,
	onPathwayUpdate,
	driver,
	situationLocation,
	brand,
}): JSX.Element => {
	const { etaInMinutes, shouldRequestDirections, onDirectionsResult } = useOnRouteTracking(
		map,
		pathway,
		onPathwayUpdate,
		driver,
		situationLocation,
		brand
	);

	return (
		<>
			{etaInMinutes !== null && (
				<InfoBox position={situationLocation} options={{ closeBoxURL: '', enableEventPropagation: true }}>
					<div className="info-box">
						<div className="info-box__minutes-container bg-primary">
							<span className="info-box__minutes-container__minutes">
								{etaInMinutes} <br />
								{i18n.get('tracking.map.lbl_eta_unit', 'min')}
							</span>
						</div>
						<span className="info-box__text">{i18n.get('tracking.map.lbl_estimated', 'Estimado')}</span>
					</div>
				</InfoBox>
			)}

			{situationLocation?.lat && driver?.lat && (
				<>
					<MemoizedDirections
						omitRequest={!shouldRequestDirections}
						onResult={onDirectionsResult}
						origin={`${driver!.lat}, ${driver!.lng}`}
						destination={`${situationLocation.lat},${situationLocation.lng}`}
					/>
					<Polyline
						path={pathway}
						options={{
							strokeColor: strokeColorBrand(brand),
							strokeWeight: 5,
							strokeOpacity: 1,
						}}
					/>
					<Polyline
						path={pathway}
						options={{
							strokeColor: strokeColorBrand(brand),
							strokeWeight: 5,
							strokeOpacity: 0.8,
						}}
					/>
				</>
			)}
		</>
	);
};

export default OnRoute;
