import React, { FunctionComponent } from 'react';
import { useLottie } from 'lottie-react';
import receivedServiceAnimation from '../../../lotties/receivedServiceAnimation.json';
import hdiAgent from '../../../assets/imgs/hdi-agent.svg';
import { HDI } from '../../../utils/constants';
import i18n from '../../../utils/i18n';
import './Animation.scss';

interface ReceivedServiceAnimationProps {
	brand: string;
	hideProviderText: boolean;
}

const ReceivedServiceAnimation: FunctionComponent<ReceivedServiceAnimationProps> = ({
	brand,
	hideProviderText,
}): JSX.Element => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: receivedServiceAnimation,
	};

	const { View } = useLottie(defaultOptions);

	return (
		<div className="tracking-animation">
			{brand === HDI ? (
				<div className="icon-container">
					<img width={150} id="agent" src={hdiAgent} alt="Agent Icon" />
				</div>
			) : (
				<div className="lottie-container">
					<div>{View}</div>
				</div>
			)}
			<div className="text-animation">
				<p>{i18n.get('tracking.animations.received_service.title', '¡Servicio Recibido!')}</p>
				{!hideProviderText ? (
					<p>
						{i18n.get('tracking.animations.received_service.subtitle', 'Pronto mostraremos por dónde va tu proveedor.')}
					</p>
				) : null}
			</div>
		</div>
	);
};

export default ReceivedServiceAnimation;
