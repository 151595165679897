import React, { FunctionComponent, useContext, useState } from 'react';
import cancelX from '../../assets/imgs/Cancel_button_icon-01.png';
import linkIcon from '../../assets/imgs/link_circle_background_icon.png';
import pinIcon from '../../assets/imgs/pin_circle_background_icon.png';
import { Context } from '../../context/Context';
import { AppContext } from '../../interfaces';
import { CONNECT_SHARE_URL } from '../../utils/constants';
import i18n from '../../utils/i18n';
import './ShareModal.scss';

interface ShareModalProps {
	dismiss: () => void;
}

const BeforeLinkCopy: FunctionComponent = (): JSX.Element => (
	<>
		<img className="circleIcon" src={pinIcon} alt="pin icon" />
		<p>{i18n.get('tracking.share_modal.title', 'Comparta su servicio')}</p>
	</>
);

const AfterLinkCopy: FunctionComponent = (): JSX.Element => (
	<>
		<img className="circleIcon" src={linkIcon} alt="link icon" />
		<p>{i18n.get('tracking.share_modal.message', 'Enlace copiado')}</p>
	</>
);

const ShareModal: FunctionComponent<ShareModalProps> = (props): JSX.Element => {
	const [copyShareLink, setCopyShareLink] = useState(false);
	const {
		brand,
		service: { _id },
	} = useContext(Context) as AppContext;
	const shareUrl = `${CONNECT_SHARE_URL}/${_id}?account=${brand}&redirect=tracking/loading`;

	const addToClipboard = (): void => {
		navigator.clipboard.writeText(shareUrl).then(() => setCopyShareLink(true));
	};

	const showCopyStatus = (): JSX.Element => (!copyShareLink ? <BeforeLinkCopy /> : <AfterLinkCopy />);

	return (
		<>
			<div>
				<img className="cancel-x" src={cancelX} alt="cancel" onClick={props.dismiss} />
			</div>
			<div className="share-modal">
				{showCopyStatus()}
				<p>
					{i18n.get(
						'tracking.share_modal.description',
						'Puede compartir enlace de servcio con sus contactos preferidos.'
					)}
				</p>
			</div>
			<div className="centerText">
				{!copyShareLink && (
					<div className="centerText">
						<div className="copyLinkBtn" onClick={addToClipboard}>
							{i18n.get('tracking.share_modal.btn_copy', 'Copiar enlace')}
						</div>
					</div>
				)}
			</div>
		</>
	);
};
export default ShareModal;
