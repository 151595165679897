import React, { useState, FunctionComponent, useContext } from 'react';
import { Context } from '../../context/Context';
import { AppContext } from '../../interfaces';
import { getImagesByBrand } from '../../utils/Utils';
import { WHITELABEL_BRANDS, FINISHED_TRIP_STATUS } from '../../utils/constants';
import './Cancelled.scss';
import i18n from '../../utils/i18n';
import Modal from '../../components/common/Modal';
import HelpModal from '../../components/common/HelpModal';

interface CancelledScreenProps {
	handleResetClick: () => void;
}

const CancelledScreen: FunctionComponent<CancelledScreenProps> = ({ handleResetClick }): JSX.Element => {
	const {
		brand,
		userExternalParams,
		service: {
			serviceNumber,
			flags: { fromConnectApp },
			trip,
		},
	} = useContext(Context) as AppContext;
	const [showHelpModal, setShowHelpModal] = useState(false);

	const onCancel = () => {
		if (userExternalParams.isFromWebview) {
			window.ReactNativeWebView?.postMessage('closed');
		} else {
			handleResetClick();
		}
	};

	const onHelpClick = (): void => {
		setShowHelpModal(!showHelpModal);
	};

	return (
		<div className="cancelled-screen tracking-route">
			{WHITELABEL_BRANDS.includes(brand) && (
				<div className="cancelled-screen__header">
					<img src={getImagesByBrand(brand).logo} alt="logo" />
				</div>
			)}
			<div className="upper-container">
				<h2 className="title primary-color">
					{FINISHED_TRIP_STATUS.includes(trip?.status as any)
						? i18n.get('screens.cancelled.titleEnd', 'Su servicio ha sido finalizado')
						: i18n.get('screens.cancelled.title', 'Su servicio ha sido cancelado')}
				</h2>
				<div className="actions">
					<div className="help" onClick={onHelpClick}>
						{i18n.get('screens.cancelled.btn_help', 'Ayuda')}
					</div>
				</div>
			</div>
			<div className="container">
				<img src={getImagesByBrand(brand).headsetImage} alt="headset" />
				<p>
					{i18n.get('screens.cancelled.subtitle', 'Recibo de servicio')} <br /> #{serviceNumber}
				</p>
				<p>{i18n.get('screens.cancelled.description', 'Para más información sobre \n su servicio presione ayuda')}</p>
			</div>
			{fromConnectApp && (
				<div className="footer">
					<button className="btn-primary btn-footer" onClick={onCancel}>
						{i18n.get('screens.cancelled.btn_ok', 'Ok')}
					</button>
				</div>
			)}
			{showHelpModal && (
				<Modal dismiss={onHelpClick} containerClassName={'modal__container--help'}>
					<HelpModal dismiss={onHelpClick} />
				</Modal>
			)}
		</div>
	);
};

export default CancelledScreen;
