import dayjs from 'dayjs';
import Jimp from 'jimp';
import UserAgentParser from 'ua-parser-js';
import {
	ACCOUNTS_WITH_DISABLED_IN_HOUSE_SURVEY,
	ACCOUNTS_WITH_DISABLED_IN_HOUSE_SURVEY_TO_SOCIAL,
	Brand,
	CONNECT_BRAND_NAMES,
	MediaId,
	ONLY_TRACKING_ACCOUNTS,
	S3_BUCKET_NAME_CR,
	S3_BUCKET_NAME_PAN,
	S3_BUCKET_NAME_PR,
	S3_BUCKET_REGION_1,
	S3_BUCKET_REGION_2,
	WHITELABEL_BRANDS,
} from './constants';
import situationPin from '../assets/imgs/situation_pin.png';
import marker from '../assets/imgs/connect-marker.svg';
import connectOffAppProviderIcon from '../assets/imgs/connect-off-app-provider-icon.svg';
import vehicleVial from '../assets/imgs/vehicle_vial.svg';
import headset from '../assets/imgs/headset.png';
import successCheckmark from '../assets/imgs/icons8-ok.svg';
import ConnectLogo from '../assets/imgs/connect-logo.svg';
import starConnectFull from '../assets/imgs/Star_Vector_Color-01.svg';
import minusSign from '../assets/imgs/Icons_CA-01.svg';
import plusSign from '../assets/imgs/Icons_CA-02.svg';
import parking from '../assets/imgs/parking-icon.png';
import parkingSelected from '../assets/imgs/parking-selected.svg';
import street from '../assets/imgs/small-street.png';
import streetSelected from '../assets/imgs/small-street-selected.svg';
import bumper from '../assets/imgs/Icon_Car.png';
import bumperSelected from '../assets/imgs/bumper-selected.svg';
import noOption from '../assets/imgs/no-option.png';
import noOptionSelected from '../assets/imgs/no-option-selected.svg';
import cameraIcon from '../assets/imgs/camera.svg';

import acertaLogo from '../assets/imgs/acerta-logo.svg';
import acertaHeaderLogo from '../assets/imgs/acerta-logo.svg';
import staracertaFull from '../assets/imgs/acerta-star-icon.svg';
import acertaCheckmark from '../assets/imgs/acerta-checkmark.svg';
import acertaVial from '../assets/imgs/acerta-vial.svg';
import acertaPin from '../assets/imgs/acerta-pin.svg';
import acertaOffAppProviderIcon from '../assets/imgs/acerta-off-app-provider-icon.svg';
import acertaHeadset from '../assets/imgs/acerta-headset.svg';

import lafisePin from '../assets/imgs/ICON_LAFISE-01.svg';
import lafiseOffAppProviderIcon from '../assets/imgs/lafise-off-app-provider-icon.svg';
import lafiseVial from '../assets/imgs/VIAL-LAFISE-01.svg';
import lafiseLandingLogo from '../assets/imgs/lafise-logo-landing.png';
import lafiseLogo from '../assets/imgs/Logo_lafise_2.jpg';
import lafiseHeaderLogo from '../assets/imgs/lafise-header-logo.png';
import starLafiseFull from '../assets/imgs/lafise-star-icon.svg';
import lafiseHeadset from '../assets/imgs/lafise-headset.png';
import lafiseCheckMark from '../assets/imgs/lafise-checkmark.png';

import starCSMFull from '../assets/imgs/csm-star-icon.svg';
import csmLogo from '../assets/imgs/csm-logo.svg';
import csmHeaderLogo from '../assets/imgs/csm-header-logo.svg';
import csmLogoDark from '../assets/imgs/csm-logo-dark.svg';
import csmPin from '../assets/imgs/csm-pin.svg';
import csmOffAppProviderIcon from '../assets/imgs/csm-off-app-provider-icon.svg';
import csmVial from '../assets/imgs/csm-vial.svg';
import csmCheckmark from '../assets/imgs/csm-checkmark.svg';
import csmHeadset from '../assets/imgs/csm-headset.svg';
import csmMinus from '../assets/imgs/csm-minus.svg';
import csmPlus from '../assets/imgs/csm-plus.svg';
import csmParkingSelected from '../assets/imgs/csm-muti-parking-selected.svg';
import csmStreetSelected from '../assets/imgs/csm-small-street-selected.svg';
import csmBumperSelected from '../assets/imgs/csm-bumper_selected.svg';
import csmNoOptionSelected from '../assets/imgs/csm-no-option-selected.svg';

import suraLogo from '../assets/imgs/sura-logo.svg';
import suraHeaderLogo from '../assets/imgs/sura-header-logo.svg';
import starSuraFull from '../assets/imgs/sura-star-icon.svg';
import suraPin from '../assets/imgs/sura-pin.svg';
import suraOffAppProviderIcon from '../assets/imgs/sura-off-app-provider-icon.svg';
import suraVial from '../assets/imgs/sura-vial.svg';
import suraCheckmark from '../assets/imgs/sura-checkmark.svg';
import suraHeadset from '../assets/imgs/sura-headset.svg';
import suraMinus from '../assets/imgs/sura-minus.svg';
import suraPlus from '../assets/imgs/sura-plus.svg';
import suraParkingSelected from '../assets/imgs/sura-muti-parking-selected.svg';
import suraStreetSelected from '../assets/imgs/sura-small-street-selected.svg';
import suraBumperSelected from '../assets/imgs/sura-bumper_selected.svg';
import suraNoOptionSelected from '../assets/imgs/sura-no-option-selected.svg';

import qualitasLogo from '../assets/imgs/qualitas-logo.png';
import qualitasHeaderLogo from '../assets/imgs/qualitas-header-logo.png';
import starQualitasFull from '../assets/imgs/qualitas-star-icon.svg';
import qualitasCheckmark from '../assets/imgs/qualitas-checkmark.svg';
import qualitasVial from '../assets/imgs/qualitas-vial.svg';
import qualitasPin from '../assets/imgs/qualitas-pin.svg';
import qualitasOfAppProviderIcon from '../assets/imgs/qualitas-off-app-provider-icon.svg';
import qualitasHeadset from '../assets/imgs/qualitas-headset.svg';

import assaLogo from '../assets/imgs/assa-logo.svg';
import assaHeaderLogo from '../assets/imgs/assa-header-logo.svg';
import starAssaFull from '../assets/imgs/assa-star-icon.svg';
import assaCheckmark from '../assets/imgs/assa-checkmark.svg';
import assaVial from '../assets/imgs/assa-vial.svg';
import assaPin from '../assets/imgs/assa-pin.svg';
import assaOffAppProviderIcon from '../assets/imgs/assa-off-app-provider-icon.svg';
import assaMinus from '../assets/imgs/assa-minus.svg';
import assaPlus from '../assets/imgs/assa-plus.svg';
import assaParkingSelected from '../assets/imgs/assa-muti-parking-selected.svg';
import assaStreetSelected from '../assets/imgs/assa-small-street-selected.svg';
import assaBumperSelected from '../assets/imgs/assa-bumper_selected.svg';
import assaNoOptionSelected from '../assets/imgs/assa-no-option-selected.svg';

import humanAdvantageLogo from '../assets/imgs/human-advantage-logo.png';
import humanAdvantageHeaderLogo from '../assets/imgs/human-advantage-header-logo.svg';
import humanAdvantageLogoDark from '../assets/imgs/human-advantage-logo-dark.png';
import starHumanAdvantageFull from '../assets/imgs/human-advantage-star.svg';
import humanAdvantagePin from '../assets/imgs/human-advantage-pin.svg';
import humanAdvantageOffappProviderIcon from '../assets/imgs/human-advantage-off-app-provider-icon.svg';
import humanAdvantageVial from '../assets/imgs/human-advantage-vial.svg';
import humanAdvantageHeadset from '../assets/imgs/human-advantage-headset.svg';
import humanAdvantageCheckmark from '../assets/imgs/human-advantage-checkmark.svg';
import humanAdvantageMinus from '../assets/imgs/human-advantage-minus.svg';
import humanAdvantagePlus from '../assets/imgs/human-advantage-plus.svg';
import humanAdvantageParkingSelected from '../assets/imgs/human-advantage-muti-parking-selected.png';
import humanAdvantageStreetSelected from '../assets/imgs/human-advantage-small-street-selected.png';
import humanAdvantageBumperSelected from '../assets/imgs/human-advantage-bumper_selected.png';
import humanAdvantageNoOptionSelected from '../assets/imgs/human-advantage-no-option-selected.png';

import hondaLogo from '../assets/imgs/honda_logo_white.svg';
import hondaHeaderLogo from '../assets/imgs/honda_header_logo.svg';
import hondaLogoDark from '../assets/imgs/honda_logo.svg';
import starhondaFull from '../assets/imgs/honda-star.svg';
import hondaPin from '../assets/imgs/honda-pin.svg';
import hondaOffAppProviderIcon from '../assets/imgs/honda-off-app-provider-icon.svg';
import hondaVial from '../assets/imgs/honda-vial.svg';
import hondaHeadset from '../assets/imgs/human-advantage-headset.svg';
import hondaCheckmark from '../assets/imgs/honda-checkmark.svg';
import hondaMinus from '../assets/imgs/honda-minus.svg';
import hondaPlus from '../assets/imgs/honda-plus.svg';
import hondaParkingSelected from '../assets/imgs/honda-muti-parking-selected.svg';
import hondaStreetSelected from '../assets/imgs/honda-small-street-selected.svg';
import hondaBumperSelected from '../assets/imgs/honda-bumper_selected.svg';
import hondaNoOptionSelected from '../assets/imgs/honda-no-option-selected.svg';
import hondaAppIcon from '../assets/imgs/honda-app-icon.svg';

import acuraLogo from '../assets/imgs/acura-logo-white.svg';
import acuraHeaderLogo from '../assets/imgs/acura-header-logo.svg';
import acuraLogoDark from '../assets/imgs/acura-logo-dark.svg';
import staracuraFull from '../assets/imgs/acura-star.svg';
import acuraPin from '../assets/imgs/acura-pin.svg';
import acuraOffAppProviderIcon from '../assets/imgs/acura-off-app-provider-icon.svg';
import acuraVial from '../assets/imgs/acura-vial.svg';
import acuraHeadset from '../assets/imgs/acura-headset.svg';
import acuraCheckmark from '../assets/imgs/acura-checkmark.svg';
import acuraMinus from '../assets/imgs/acura-minus.svg';
import acuraPlus from '../assets/imgs/acura-plus.svg';
import acuraParkingSelected from '../assets/imgs/acura-muti-parking-selected.svg';
import acuraStreetSelected from '../assets/imgs/acura-small-street-selected.svg';
import acuraBumperSelected from '../assets/imgs/acura-bumper_selected.svg';
import acuraNoOptionSelected from '../assets/imgs/acura-no-option-selected.svg';
import acuraAppIcon from '../assets/imgs/acura-app-icon.svg';

import mazdaLogo from '../assets/imgs/mazda-logo-white.svg';
import mazdaHeaderLogo from '../assets/imgs/mazda-header-logo.svg';
import mazdaLogoDark from '../assets/imgs/mazda-logo-dark.svg';
import starmazdaFull from '../assets/imgs/acura-star.svg';
import mazdaPin from '../assets/imgs/acura-pin.svg';
import mazdaOffAppProviderIcon from '../assets/imgs/acura-off-app-provider-icon.svg';
import mazdaVial from '../assets/imgs/acura-vial.svg';
import mazdaHeadset from '../assets/imgs/acura-headset.svg';
import mazdaCheckmark from '../assets/imgs/acura-checkmark.svg';
import mazdaMinus from '../assets/imgs/acura-minus.svg';
import mazdaPlus from '../assets/imgs/acura-plus.svg';
import mazdaParkingSelected from '../assets/imgs/acura-muti-parking-selected.svg';
import mazdaStreetSelected from '../assets/imgs/acura-small-street-selected.svg';
import mazdaBumperSelected from '../assets/imgs/acura-bumper_selected.svg';
import mazdaNoOptionSelected from '../assets/imgs/acura-no-option-selected.svg';
import mazdaAppIcon from '../assets/imgs/mazda-app-icon.svg';

import mmmLogo from '../assets/imgs/mmm-logo.svg';
import mmmHeaderLogo from '../assets/imgs/mmm-header-logo.svg';
import mmmLandingLogo from '../assets/imgs/mmm-logo.svg';
import mmmAppIcon from '../assets/imgs/mmm-logo.svg';
import startmmmFull from '../assets/imgs/mmm-star-color.svg';
import mmmPin from '../assets/imgs/mmm-pin.svg';
import mmmOffAppProviderIcon from '../assets/imgs/mmm-off-app-provider-icon.svg';
import mmmCheckmark from '../assets/imgs/mmm-checkmark.svg';
import mmmMinus from '../assets/imgs/mmm-icon-minus.svg';
import mmmPlus from '../assets/imgs/mmm-icon-plus.svg';
import mmmParkingSelected from '../assets/imgs/mmm-icon-parking.svg';
import mmmStreetSelected from '../assets/imgs/mmm-icon-street.svg';
import mmmBumperSelected from '../assets/imgs/mmm-icon-bumper.svg';
import mmmNoOptionSelected from '../assets/imgs/mmm-icon-noneOption.svg';
import mmmHeadSet from '../assets/imgs/mmm-headset.svg';
import mmmCameraIcon from '../assets/imgs/mmm-camera.svg';

import sssAutoLogo from '../assets/imgs/sss-auto-logo.png';
import sssAutoVial from '../assets/imgs/sss-auto-vial.svg';
import sssAutoHeadset from '../assets/imgs/sss-auto-headset.svg';

import sssAutoStar from '../assets/imgs/sss-auto-star.svg';
import sssAutoCheckmark from '../assets/imgs/sss-auto-checkmark.svg';
import sssAutoMinus from '../assets/imgs/sss-auto-minus.svg';
import sssAutoPlus from '../assets/imgs/sss-auto-plus.svg';
import sssAutoParkingSelected from '../assets/imgs/sss-auto-parking-selected.svg';
import sssAutoStreetSelected from '../assets/imgs/sss-auto-street-selected.svg';
import sssAutoBumperSelected from '../assets/imgs/sss-auto-bumper_selected.svg';
import sssAutoPin from '../assets/imgs/sss-auto-pin.svg';
import sssAutoOffAppProviderIcon from '../assets/imgs/sss-off-app-provider-icon.svg';
import sssAutoNoOptionSelected from '../assets/imgs/sss-auto-icon-noneOption.svg';

import banescoLogo from '../assets/imgs/banesco-logo.png';
import banescoHeaderLogo from '../assets/imgs/banesco-logo.png';
import starbanescoFull from '../assets/imgs/banesco-star-icon.svg';
import banescoCheckmark from '../assets/imgs/banesco-checkmark.svg';
import banescoVial from '../assets/imgs/banesco-vial.svg';
import banescoPin from '../assets/imgs/banesco-pin.svg';
import banescoOffAppProviderIcon from '../assets/imgs/banesco-off-app-provider-icon.svg';
import banescoHeadset from '../assets/imgs/banesco-headset.svg';
import banescoMinus from '../assets/imgs/banesco-minus.svg';
import banescoPlus from '../assets/imgs/banesco-plus.svg';
import banescoParkingSelected from '../assets/imgs/banesco-parking-selected.svg';
import banescoStreetSelected from '../assets/imgs/banesco-street-selected.svg';
import banescoBumperSelected from '../assets/imgs/banesco-bumper_selected.svg';
import banescoNoOptionSelected from '../assets/imgs/banesco-icon-noneOption.svg';

import lincolnLogo from '../assets/imgs/lincoln-logo.svg';
import lincolnVial from '../assets/imgs/lincoln-vial.svg';
import lincolnHeadset from '../assets/imgs/lincoln-headset.svg';

import lincolnStar from '../assets/imgs/lincoln-star.svg';
import lincolnCheckmark from '../assets/imgs/lincoln-checkmark.svg';
import lincolnMinus from '../assets/imgs/lincoln-minus.svg';
import lincolnPlus from '../assets/imgs/lincoln-plus.svg';
import lincolnParkingSelected from '../assets/imgs/lincoln-parking-selected.svg';
import lincolnStreetSelected from '../assets/imgs/lincoln-street-selected.svg';
import lincolnBumperSelected from '../assets/imgs/lincoln-bumper_selected.svg';
import lincolnPin from '../assets/imgs/lincoln-pin.svg';
import lincolnOffAppProviderIcon from '../assets/imgs/lincoln-off-app-provider-icon.svg';
import lincolnNoOptionSelected from '../assets/imgs/lincoln-icon-noneOption.svg';

import segurosMundialLogo from '../assets/imgs/seguros-mundial-logo.svg';
import segurosMundialVial from '../assets/imgs/seguros-mundial-vial.svg';
import segurosMundialHeadset from '../assets/imgs/seguros-mundial-survey-headset.svg';

import segurosMundialStar from '../assets/imgs/seguros-mundial-star.svg';
import segurosMundialCheckmark from '../assets/imgs/seguros-mundial-checkmark.svg';
import segurosMundialMinus from '../assets/imgs/seguros-mundial-minus.svg';
import segurosMundialPlus from '../assets/imgs/seguros-mundial-plus.svg';
import segurosMundialParkingSelected from '../assets/imgs/seguros-mundial-parking-selected.svg';
import segurosMundialStreetSelected from '../assets/imgs/seguros-mundial-street-selected.svg';
import segurosMundialBumperSelected from '../assets/imgs/seguros-mundial-bumper-selected.svg';
import segurosMundialPin from '../assets/imgs/seguros-mundial-pin.svg';
import segurosMundialOffAppProviderIcon from '../assets/imgs/seguros-mundial-off-app-provider-icon.svg';
import segurosMundialNoOptionSelected from '../assets/imgs/seguros-mundial-icon-noneOption.svg';

import ricardoPerezLogo from '../assets/imgs/ricardo-perez-logo.svg';
import ricardoPerezVial from '../assets/imgs/ricardo-perez-vial.svg';
import ricardoPerezHeadset from '../assets/imgs/ricardo-perez-headset.svg';
import ricardoPerezStar from '../assets/imgs/ricardo-perez-star.svg';
import ricardoPerezCheckmark from '../assets/imgs/ricardo-perez-checkmark.svg';
import ricardoPerezMinus from '../assets/imgs/ricardo-perez-minus.svg';
import ricardoPerezPlus from '../assets/imgs/ricardo-perez-plus.svg';
import ricardoPerezParkingSelected from '../assets/imgs/ricardo-perez-parking-selected.svg';
import ricardoPerezStreetSelected from '../assets/imgs/ricardo-perez-street-selected.svg';
import ricardoPerezBumperSelected from '../assets/imgs/ricardo-perez-bumper_selected.svg';
import ricardoPerezPin from '../assets/imgs/ricardo-perez-pin.svg';
import ricardoPerezOffAppProviderIcon from '../assets/imgs/ricardo-perez-off-app-provider-icon.svg';
import ricardoPerezNoOptionSelected from '../assets/imgs/ricardo-perez-icon-noneOption.svg';

import optimaSegurosLogo from '../assets/imgs/optima-seguros-logo.svg';
import optimaSegurosVial from '../assets/imgs/optima-seguros-vial.svg';
import optimaSegurosHeadset from '../assets/imgs/optima-seguros-headset.svg';
import optimaSegurosStar from '../assets/imgs/optima-seguros-star.svg';
import optimaSegurosCheckmark from '../assets/imgs/optima-seguros-checkmark.svg';
import optimaSegurosMinus from '../assets/imgs/optima-seguros-minus.svg';
import optimaSegurosPlus from '../assets/imgs/optima-seguros-plus.svg';
import optimaSegurosParkingSelected from '../assets/imgs/optima-seguros-parking-selected.svg';
import optimaSegurosStreetSelected from '../assets/imgs/optima-seguros-street-selected.svg';
import optimaSegurosBumperSelected from '../assets/imgs/optima-seguros-bumper_selected.svg';
import optimaSegurosPin from '../assets/imgs/optima-seguros-pin.svg';
import optimaSegurosOffAppProviderIcon from '../assets/imgs/optima-seguros-off-app-provider-icon.svg';
import optimaSegurosNoOptionSelected from '../assets/imgs/optima-seguros-icon-noneOption.svg';

import kiaLogo from '../assets/imgs/kia-logo.svg';
import kiaVial from '../assets/imgs/kia-vial.svg';
import kiaHeadset from '../assets/imgs/kia-headset.svg';
import kiaStar from '../assets/imgs/kia-star.svg';
import kiaCheckmark from '../assets/imgs/kia-checkmark.svg';
import kiaMinus from '../assets/imgs/kia-minus.svg';
import kiaPlus from '../assets/imgs/kia-plus.svg';
import kiaParkingSelected from '../assets/imgs/kia-parking-selected.svg';
import kiaStreetSelected from '../assets/imgs/kia-street-selected.svg';
import kiaBumperSelected from '../assets/imgs/kia-bumper_selected.svg';
import kiaPin from '../assets/imgs/kia-pin.svg';
import kiaOffAppProviderIcon from '../assets/imgs/kia-off-app-provider-icon.svg';
import kiaNoOptionSelected from '../assets/imgs/kia-icon-noneOption.svg';

import lexusLogo from '../assets/imgs/lexus-logo.svg';
import lexusVial from '../assets/imgs/lexus-vial.svg';
import lexusHeadset from '../assets/imgs/lexus-headset.svg';
import lexusStar from '../assets/imgs/lexus-star.svg';
import lexusCheckmark from '../assets/imgs/lexus-checkmark.svg';
import lexusMinus from '../assets/imgs/lexus-minus.svg';
import lexusPlus from '../assets/imgs/lexus-plus.svg';
import lexusParkingSelected from '../assets/imgs/lexus-parking-selected.svg';
import lexusStreetSelected from '../assets/imgs/lexus-street-selected.svg';
import lexusBumperSelected from '../assets/imgs/lexus-bumper_selected.svg';
import lexusPin from '../assets/imgs/lexus-pin.svg';
import lexusOffAppProviderIcon from '../assets/imgs/lexus-off-app-provider-icon.svg';
import lexusNoOptionSelected from '../assets/imgs/lexus-icon-noneOption.svg';

import infinitiLogo from '../assets/imgs/infiniti-logo.svg';
import infinitiVial from '../assets/imgs/infiniti-vial.svg';
import infinitiHeadset from '../assets/imgs/infiniti-headset.svg';
import infinitiStar from '../assets/imgs/infiniti-star.svg';
import infinitiCheckmark from '../assets/imgs/infiniti-checkmark.svg';
import infinitiMinus from '../assets/imgs/infiniti-minus.svg';
import infinitiPlus from '../assets/imgs/infiniti-plus.svg';
import infinitiParkingSelected from '../assets/imgs/infiniti-parking-selected.svg';
import infinitiStreetSelected from '../assets/imgs/infiniti-street-selected.svg';
import infinitiBumperSelected from '../assets/imgs/infiniti-bumper_selected.svg';
import infinitiPin from '../assets/imgs/infiniti-pin.svg';
import infinitiOffAppProviderIcon from '../assets/imgs/infiniti-off-app-provider-icon.svg';
import infinitiNoOptionSelected from '../assets/imgs/infiniti-icon-noneOption.svg';

import mitsubishiLogo from '../assets/imgs/mitsubishi-logo.svg';
import mitsubishiVial from '../assets/imgs/mitsubishi-vial.svg';
import mitsubishiHeadset from '../assets/imgs/mitsubishi-headset.svg';
import mitsubishiStar from '../assets/imgs/mitsubishi-star.svg';
import mitsubishiCheckmark from '../assets/imgs/mitsubishi-checkmark.svg';
import mitsubishiMinus from '../assets/imgs/mitsubishi-minus.svg';
import mitsubishiPlus from '../assets/imgs/mitsubishi-plus.svg';
import mitsubishiParkingSelected from '../assets/imgs/mitsubishi-parking-selected.svg';
import mitsubishiStreetSelected from '../assets/imgs/mitsubishi-street-selected.svg';
import mitsubishiBumperSelected from '../assets/imgs/mitsubishi-bumper_selected.svg';
import mitsubishiPin from '../assets/imgs/mitsubishi-pin.svg';
import mitsubishiOffAppProviderIcon from '../assets/imgs/mitsubishi-off-app-provider-icon.svg';
import mitsubishiNoOptionSelected from '../assets/imgs/mitsubishi-icon-noneOption.svg';

import hyundaiLogo from '../assets/imgs/hyundai-logo.svg';
import hyundaiVial from '../assets/imgs/hyundai-vial.svg';
import hyundaiHeadset from '../assets/imgs/hyundai-headset.svg';
import hyundaiStar from '../assets/imgs/hyundai-star.svg';
import hyundaiCheckmark from '../assets/imgs/hyundai-checkmark.svg';
import hyundaiMinus from '../assets/imgs/hyundai-minus.svg';
import hyundaiPlus from '../assets/imgs/hyundai-plus.svg';
import hyundaiParkingSelected from '../assets/imgs/hyundai-parking-selected.svg';
import hyundaiStreetSelected from '../assets/imgs/hyundai-street-selected.svg';
import hyundaiBumperSelected from '../assets/imgs/hyundai-bumper_selected.svg';
import hyundaiPin from '../assets/imgs/hyundai-pin.svg';
import hyundaiOffAppProviderIcon from '../assets/imgs/hyundai-off-app-provider-icon.svg';
import hyundaiNoOptionSelected from '../assets/imgs/hyundai-icon-noneOption.svg';

import toyotaLogo from '../assets/imgs/toyota-logo.svg';
import toyotaVial from '../assets/imgs/toyota-vial.svg';
import toyotaHeadset from '../assets/imgs/toyota-headset.svg';
import toyotaStar from '../assets/imgs/toyota-star.svg';
import toyotaCheckmark from '../assets/imgs/toyota-checkmark.svg';
import toyotaMinus from '../assets/imgs/toyota-minus.svg';
import toyotaPlus from '../assets/imgs/toyota-plus.svg';
import toyotaParkingSelected from '../assets/imgs/toyota-parking-selected.svg';
import toyotaStreetSelected from '../assets/imgs/toyota-street-selected.svg';
import toyotaBumperSelected from '../assets/imgs/toyota-bumper_selected.svg';
import toyotaPin from '../assets/imgs/toyota-pin.svg';
import toyotaOffAppProviderIcon from '../assets/imgs/toyota-off-app-provider-icon.svg';
import toyotaNoOptionSelected from '../assets/imgs/toyota-icon-noneOption.svg';

import nissanLogo from '../assets/imgs/nissan-logo.svg';
import nissanVial from '../assets/imgs/nissan-vial.svg';
import nissanHeadset from '../assets/imgs/nissan-headset.svg';

import nissanStar from '../assets/imgs/nissan-star.svg';
import nissanCheckmark from '../assets/imgs/nissan-checkmark.svg';
import nissanMinus from '../assets/imgs/nissan-minus.svg';
import nissanPlus from '../assets/imgs/nissan-plus.svg';
import nissanParkingSelected from '../assets/imgs/nissan-parking-selected.svg';
import nissanStreetSelected from '../assets/imgs/nissan-street-selected.svg';
import nissanBumperSelected from '../assets/imgs/nissan-bumper_selected.svg';
import nissanPin from '../assets/imgs/nissan-pin.svg';
import nissanOffAppProviderIcon from '../assets/imgs/nissan-off-app-provider-icon.svg';
import nissanNoOptionSelected from '../assets/imgs/nissan-icon-noneOption.svg';

import generalDeSegurosLogo from '../assets/imgs/general-de-seguros-logo.png';
import generalDeSegurosVial from '../assets/imgs/general-de-seguros-vial.svg';
import generalDeSegurosHeadset from '../assets/imgs/general-de-seguros-headset.svg';

import generalDeSegurosStar from '../assets/imgs/general-de-seguros-star.svg';
import generalDeSegurosCheckmark from '../assets/imgs/general-de-seguros-checkmark.svg';
import generalDeSegurosMinus from '../assets/imgs/general-de-seguros-minus.svg';
import generalDeSegurosPlus from '../assets/imgs/general-de-seguros-plus.svg';
import generalDeSegurosParkingSelected from '../assets/imgs/general-de-seguros-parking-selected.svg';
import generalDeSegurosStreetSelected from '../assets/imgs/general-de-seguros-street-selected.svg';
import generalDeSegurosBumperSelected from '../assets/imgs/general-de-seguros-bumper_selected.svg';
import generalDeSegurosPin from '../assets/imgs/general-de-seguros-pin.svg';
import generalDeSegurosOffAppProviderIcon from '../assets/imgs/general-de-seguros-off-app-provider-icon.svg';
import generalDeSegurosNoOptionSelected from '../assets/imgs/general-de-seguros-icon-noneOption.svg';

import internacionalDeSegurosLogo from '../assets/imgs/internacional-de-seguros-logo.svg';
import internacionalDeSegurosVial from '../assets/imgs/internacional-de-seguros-vial.svg';
import internacionalDeSegurosHeadset from '../assets/imgs/internacional-de-seguros-headset.svg';
import internacionalDeSegurosStar from '../assets/imgs/internacional-de-seguros-star.svg';
import internacionalDeSegurosCheckmark from '../assets/imgs/internacional-de-seguros-checkmark.svg';
import internacionalDeSegurosMinus from '../assets/imgs/internacional-de-seguros-minus.svg';
import internacionalDeSegurosPlus from '../assets/imgs/internacional-de-seguros-plus.svg';
import internacionalDeSegurosParkingSelected from '../assets/imgs/internacional-de-seguros-parking-selected.svg';
import internacionalDeSegurosStreetSelected from '../assets/imgs/internacional-de-seguros-street-selected.svg';
import internacionalDeSegurosBumperSelected from '../assets/imgs/internacional-de-seguros-bumper_selected.svg';
import internacionalDeSegurosPin from '../assets/imgs/internacional-de-seguros-pin.svg';
import internacionalDeSegurosOffAppProviderIcon from '../assets/imgs/internacional-de-seguros-off-app-provider-icon.svg';
import internacionalDeSegurosNoOptionSelected from '../assets/imgs/internacional-de-seguros-noneOption.svg';

import hdiLogo from '../assets/imgs/hdi-logo.svg';
import hdiVial from '../assets/imgs/hdi-vial.svg';
import hdiHeadset from '../assets/imgs/hdi-agent.svg';
import hdiStar from '../assets/imgs/hdi-star.svg';
import hdiCheckmark from '../assets/imgs/hdi-checkmark.svg';
import hdiMinus from '../assets/imgs/hdi-minus.svg';
import hdiPlus from '../assets/imgs/hdi-plus.svg';
import hdiParkingSelected from '../assets/imgs/hdi-parking-selected.svg';
import hdiStreetSelected from '../assets/imgs/hdi-street-selected.svg';
import hdiBumperSelected from '../assets/imgs/hdi-bumper_selected.svg';
import hdiPin from '../assets/imgs/hdi-pin.svg';
import hdiOffAppProviderIcon from '../assets/imgs/hdi-off-app-provider-icon.svg';
import hdiNoOptionSelected from '../assets/imgs/hdi-noneOption.svg';

import fedpaLogo from '../assets/imgs/fedpa-logo.svg';
import fedpaVial from '../assets/imgs/fedpa-vial.svg';
import fedpaHeadset from '../assets/imgs/fedpa-headset.svg';
import fedpaStar from '../assets/imgs/fedpa-star.svg';
import fedpaCheckmark from '../assets/imgs/fedpa-checkmark.svg';
import fedpaMinus from '../assets/imgs/fedpa-minus.svg';
import fedpaPlus from '../assets/imgs/fedpa-plus.svg';
import fedpaParkingSelected from '../assets/imgs/fedpa-parking-selected.svg';
import fedpaStreetSelected from '../assets/imgs/fedpa-street-selected.svg';
import fedpaBumperSelected from '../assets/imgs/fedpa-bumper_selected.svg';
import fedpaPin from '../assets/imgs/fedpa-pin.svg';
import fedpaOffAppProviderIcon from '../assets/imgs/fedpa-off-app-provider-icon.svg';
import fedpaNoOptionSelected from '../assets/imgs/fedpa-noneOption.svg';

import ascLogo from '../assets/imgs/asc-logo.svg';
import ascVial from '../assets/imgs/asc-vial.svg';
import ascHeadset from '../assets/imgs/asc-headset.svg';
import ascStar from '../assets/imgs/asc-star.svg';
import ascCheckmark from '../assets/imgs/asc-checkmark.svg';
import ascMinus from '../assets/imgs/asc-minus.svg';
import ascPlus from '../assets/imgs/asc-plus.svg';
import ascParkingSelected from '../assets/imgs/asc-parking-selected.svg';
import ascStreetSelected from '../assets/imgs/asc-street-selected.svg';
import ascBumperSelected from '../assets/imgs/asc-bumper_selected.svg';
import ascPin from '../assets/imgs/asc-pin.svg';
import ascOffAppProviderIcon from '../assets/imgs/asc-off-app-provider-icon.svg';
import ascNoOptionSelected from '../assets/imgs/asc-noneOption.svg';

import kintoOneLogo from '../assets/imgs/kinto-one-logo.svg';
import kintoOneVial from '../assets/imgs/kinto-one-vial.png';
import kintoOneStar from '../assets/imgs/kinto-one-star.svg';

import autochilangoLogo from '../assets/imgs/autochilango-logo.svg';
import autochilangoVial from '../assets/imgs/autochilango-vial.svg';
import autochilangoOffAppProviderIcon from '../assets/imgs/autochilango-off-app-provider-icon.svg';
import autochilangoStar from '../assets/imgs/autochilango-star.svg';
import autochilangoHeadset from '../assets/imgs/autochilango-headset.svg';
import autochilangoCheckmark from '../assets/imgs/autochilango-checkmark.svg';
import autochilangoMinus from '../assets/imgs/autochilango-minus.svg';
import autochilangoPlus from '../assets/imgs/autochilango-plus.svg';
import autochilangoParkingSelected from '../assets/imgs/autochilango-parking-selected.svg';
import autochilangoStreetSelected from '../assets/imgs/autochilango-street-selected.svg';
import autochilangoBumperSelected from '../assets/imgs/autochilango-bumper_selected.svg';
import autochilangoNoOptionSelected from '../assets/imgs/autochilango-noneOption.svg';
import autochilangoPin from '../assets/imgs/autochilango-pin.svg';

import eparkLogo from '../assets/imgs/epark-logo.svg';
import eparkVial from '../assets/imgs/epark-vial.svg';
import eparkHeadset from '../assets/imgs/epark-headset.svg';
import eparkStar from '../assets/imgs/epark-star.svg';
import eparkCheckmark from '../assets/imgs/epark-checkmark.svg';
import eparkMinus from '../assets/imgs/epark-minus.svg';
import eparkPlus from '../assets/imgs/epark-plus.svg';
import eparkParkingSelected from '../assets/imgs/epark-parking-selected.svg';
import eparkStreetSelected from '../assets/imgs/epark-street-selected.svg';
import eparkBumperSelected from '../assets/imgs/epark-bumper_selected.svg';
import eparkPin from '../assets/imgs/epark-pin.svg';
import eparkOffAppProviderIcon from '../assets/imgs/epark-off-app-provider-icon.svg';
import eparkNoOptionSelected from '../assets/imgs/epark-noneOption.svg';

import atlasLogo from '../assets/imgs/atlas-logo.png';
import kavakMxLogo from '../assets/imgs/kavak-mx-logo.png';
import cluppLogo from '../assets/imgs/clupp-logo.png';
import crabiLogo from '../assets/imgs/crabi-logo.svg';
import afirmeLogo from '../assets/imgs/afirme-logo.svg';
import quanLogo from '../assets/imgs/quan-logo.png';

import { ACURA, CORPORATE_ACCOUNTS, HONDA, MAZDA, ON_DEMAND_ACCOUNT_ID } from './constants';

import { setPushOptIn } from '../context/actions';
import OneSignal from 'react-onesignal';
import {
	AppContext,
	Branches,
	ContactPhone,
	CustomerServicePhone,
	CorporateAccount,
	DispatchFunction,
	ServiceTypesObject,
	Situation,
	TRACKS,
	homeCategories,
	Driver,
	Account,
	Integration,
	ON_SUCCESS_DYNAMIC_PARAMS,
} from '../interfaces';
import HeliosClient from '../api/Helios';
import i18n from './i18n';
import { getOnDemandAccount } from '../screens/options/helper';

export enum PLACE_SITUATION_PIN {
	bottom = 'bottom',
	middle = 'middle',
	top = 'top',
}

interface Images {
	logo: string;
	logoDark: string;
	headerLogo: string;
	vehicleVial: string;
	situationPin: string;
	permissionPin: string;
	offAppProviderIcon: string;
	starImage: string;
	headsetImage: string;
	successCheckmark?: string;
	minusSign?: string;
	plusSign?: string;
	parking?: string;
	parkingSelected?: string;
	street?: string;
	streetSelected?: string;
	bumper?: string;
	bumperSelected?: string;
	noOption?: string;
	noOptionSelected?: string;
	appIcon?: string;
	placeSituationPin: PLACE_SITUATION_PIN;
	cameraIcon?: string;
}

export const bucketsByBranch = {
	[Branches.PR]: {
		bucketName: S3_BUCKET_NAME_PR,
		bucketRegion: S3_BUCKET_REGION_2,
	},
	[Branches.PTY]: {
		bucketName: S3_BUCKET_NAME_PAN,
		bucketRegion: S3_BUCKET_REGION_2,
	},
	[Branches.CRI]: {
		bucketName: S3_BUCKET_NAME_CR,
		bucketRegion: S3_BUCKET_REGION_1,
	},
	[Branches.CO]: {
		bucketName: '',
		bucketRegion: '',
	},
	[Branches.MX]: {
		bucketName: '',
		bucketRegion: '',
	},
};

const defaultImages = {
	logo: ConnectLogo,
	logoDark: ConnectLogo,
	headerLogo: ConnectLogo,
	vehicleVial: vehicleVial,
	situationPin: situationPin,
	placeSituationPin: PLACE_SITUATION_PIN.middle,
	permissionPin: marker,
	starImage: starConnectFull,
	headsetImage: headset,
	successCheckmark: successCheckmark,
	minusSign: minusSign,
	plusSign: plusSign,
	parking: parking,
	parkingSelected: parkingSelected,
	street: street,
	streetSelected: streetSelected,
	bumper: bumper,
	bumperSelected: bumperSelected,
	noOption: noOption,
	noOptionSelected: noOptionSelected,
	cameraIcon: cameraIcon,
	offAppProviderIcon: connectOffAppProviderIcon,
};

export const isTrackingRoute = () => {
	const isTrackingRoute = document.getElementsByClassName('tracking-route');
	return isTrackingRoute?.length;
};

export const getImagesByBrand = (brand: string): Images => {
	if (ONLY_TRACKING_ACCOUNTS.includes(brand) && !isTrackingRoute()) {
		brand = 'default';
	}

	switch (brand) {
		case 'lafise':
			return {
				logo: lafiseLogo,
				logoDark: lafiseLandingLogo,
				headerLogo: lafiseHeaderLogo,
				vehicleVial: lafiseVial,
				situationPin: lafisePin,
				permissionPin: lafisePin,
				offAppProviderIcon: lafiseOffAppProviderIcon,
				starImage: starLafiseFull,
				headsetImage: lafiseHeadset,
				successCheckmark: lafiseCheckMark,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
			};
		case 'multiple-asistencia-en-viaje':
		case 'inspecciones-csm':
		case 'multiple-asistencia-en-el-hogar':
			return {
				logo: csmLogo,
				logoDark: csmLogoDark,
				headerLogo: csmHeaderLogo,
				vehicleVial: csmVial,
				situationPin: csmPin,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: csmPin,
				offAppProviderIcon: csmOffAppProviderIcon,
				starImage: starCSMFull,
				headsetImage: csmHeadset,
				successCheckmark: csmCheckmark,
				minusSign: csmMinus,
				plusSign: csmPlus,
				parking: parking,
				parkingSelected: csmParkingSelected,
				street: street,
				streetSelected: csmStreetSelected,
				bumper: bumper,
				bumperSelected: csmBumperSelected,
				noOption: noOption,
				noOptionSelected: csmNoOptionSelected,
				cameraIcon: mmmCameraIcon,
			};
		case 'sura':
		case 'sura-co':
		case 'kavak':
		case 'auteco':
			return {
				logo: suraLogo,
				logoDark: suraLogo,
				headerLogo: suraHeaderLogo,
				vehicleVial: suraVial,
				situationPin: suraPin,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: suraPin,
				offAppProviderIcon: suraOffAppProviderIcon,
				starImage: starSuraFull,
				headsetImage: suraHeadset,
				successCheckmark: suraCheckmark,
				minusSign: suraMinus,
				plusSign: suraPlus,
				parking: parking,
				parkingSelected: suraParkingSelected,
				street: street,
				streetSelected: suraStreetSelected,
				bumper: bumper,
				bumperSelected: suraBumperSelected,
				noOption: noOption,
				noOptionSelected: suraNoOptionSelected,
			};
		case 'qualitas':
			return {
				logo: qualitasLogo,
				logoDark: qualitasLogo,
				headerLogo: qualitasHeaderLogo,
				vehicleVial: qualitasVial,
				situationPin: qualitasPin,
				offAppProviderIcon: qualitasOfAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: qualitasPin,
				starImage: starQualitasFull,
				headsetImage: qualitasHeadset,
				successCheckmark: qualitasCheckmark,
			};
		case 'assa':
			return {
				logo: assaLogo,
				logoDark: assaLogo,
				headerLogo: assaHeaderLogo,
				vehicleVial: assaVial,
				situationPin: assaPin,
				offAppProviderIcon: assaOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: assaPin,
				starImage: starAssaFull,
				headsetImage: suraHeadset,
				successCheckmark: assaCheckmark,
				minusSign: assaMinus,
				plusSign: assaPlus,
				parking: parking,
				parkingSelected: assaParkingSelected,
				street: street,
				streetSelected: assaStreetSelected,
				bumper: bumper,
				bumperSelected: assaBumperSelected,
				noOption: noOption,
				noOptionSelected: assaNoOptionSelected,
			};
		case 'human-advantage':
			return {
				logo: humanAdvantageLogo,
				logoDark: humanAdvantageLogoDark,
				headerLogo: humanAdvantageHeaderLogo,
				vehicleVial: humanAdvantageVial,
				situationPin: humanAdvantagePin,
				offAppProviderIcon: humanAdvantageOffappProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: humanAdvantagePin,
				starImage: starHumanAdvantageFull,
				headsetImage: humanAdvantageHeadset,
				successCheckmark: humanAdvantageCheckmark,
				minusSign: humanAdvantageMinus,
				plusSign: humanAdvantagePlus,
				parking: parking,
				parkingSelected: humanAdvantageParkingSelected,
				street: street,
				streetSelected: humanAdvantageStreetSelected,
				bumper: bumper,
				bumperSelected: humanAdvantageBumperSelected,
				noOption: noOption,
				noOptionSelected: humanAdvantageNoOptionSelected,
			};
		case 'honda':
			return {
				logo: hondaLogo,
				logoDark: hondaLogoDark,
				headerLogo: hondaHeaderLogo,
				vehicleVial: hondaVial,
				situationPin: hondaPin,
				offAppProviderIcon: hondaOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: hondaPin,
				starImage: starhondaFull,
				headsetImage: hondaHeadset,
				successCheckmark: hondaCheckmark,
				minusSign: hondaMinus,
				plusSign: hondaPlus,
				parking: parking,
				parkingSelected: hondaParkingSelected,
				street: street,
				streetSelected: hondaStreetSelected,
				bumper: bumper,
				bumperSelected: hondaBumperSelected,
				noOption: noOption,
				noOptionSelected: hondaNoOptionSelected,
				appIcon: hondaAppIcon,
			};
		case 'acura':
			return {
				logo: acuraLogo,
				logoDark: acuraLogoDark,
				headerLogo: acuraHeaderLogo,
				vehicleVial: acuraVial,
				situationPin: acuraPin,
				offAppProviderIcon: acuraOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: acuraPin,
				starImage: staracuraFull,
				headsetImage: acuraHeadset,
				successCheckmark: acuraCheckmark,
				minusSign: acuraMinus,
				plusSign: acuraPlus,
				parking: parking,
				parkingSelected: acuraParkingSelected,
				street: street,
				streetSelected: acuraStreetSelected,
				bumper: bumper,
				bumperSelected: acuraBumperSelected,
				noOption: noOption,
				noOptionSelected: acuraNoOptionSelected,
				appIcon: acuraAppIcon,
			};
		case 'mazda':
			return {
				logo: mazdaLogo,
				logoDark: mazdaLogoDark,
				headerLogo: mazdaHeaderLogo,
				vehicleVial: mazdaVial,
				situationPin: mazdaPin,
				offAppProviderIcon: mazdaOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: mazdaPin,
				starImage: starmazdaFull,
				headsetImage: mazdaHeadset,
				successCheckmark: mazdaCheckmark,
				minusSign: mazdaMinus,
				plusSign: mazdaPlus,
				parking: parking,
				parkingSelected: mazdaParkingSelected,
				street: street,
				streetSelected: mazdaStreetSelected,
				bumper: bumper,
				bumperSelected: mazdaBumperSelected,
				noOption: noOption,
				noOptionSelected: mazdaNoOptionSelected,
				appIcon: mazdaAppIcon,
			};
		case 'lincoln':
			return {
				logo: lincolnLogo,
				logoDark: lincolnLogo,
				headerLogo: lincolnLogo,
				vehicleVial: lincolnVial,
				situationPin: lincolnPin,
				offAppProviderIcon: lincolnOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: lincolnPin,
				starImage: lincolnStar,
				headsetImage: lincolnHeadset,
				successCheckmark: lincolnCheckmark,
				minusSign: lincolnMinus,
				plusSign: lincolnPlus,
				parking: parking,
				parkingSelected: lincolnParkingSelected,
				street: street,
				streetSelected: lincolnStreetSelected,
				bumper: bumper,
				bumperSelected: lincolnBumperSelected,
				noOption: noOption,
				noOptionSelected: lincolnNoOptionSelected,
				appIcon: lincolnLogo,
			};
		case 'mmm':
		case 'mmm-empleados':
			return {
				logo: mmmLogo,
				logoDark: mmmLandingLogo,
				headerLogo: mmmHeaderLogo,
				vehicleVial: vehicleVial,
				situationPin: mmmPin,
				offAppProviderIcon: mmmOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: mmmPin,
				starImage: startmmmFull,
				headsetImage: mmmHeadSet,
				successCheckmark: mmmCheckmark,
				minusSign: mmmMinus,
				plusSign: mmmPlus,
				parking: parking,
				parkingSelected: mmmParkingSelected,
				street: street,
				streetSelected: mmmStreetSelected,
				bumper: bumper,
				bumperSelected: mmmBumperSelected,
				noOption: noOption,
				noOptionSelected: mmmNoOptionSelected,
				appIcon: mmmAppIcon,
				cameraIcon: mmmCameraIcon,
			};
		case 'sss-auto':
		case 'sss-home':
			return {
				logo: sssAutoLogo,
				logoDark: sssAutoLogo,
				headerLogo: sssAutoLogo,
				vehicleVial: sssAutoVial,
				situationPin: sssAutoPin,
				offAppProviderIcon: sssAutoOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: sssAutoPin,
				starImage: sssAutoStar,
				headsetImage: sssAutoHeadset,
				successCheckmark: sssAutoCheckmark,
				minusSign: sssAutoMinus,
				plusSign: sssAutoPlus,
				parking: parking,
				parkingSelected: sssAutoParkingSelected,
				street: street,
				streetSelected: sssAutoStreetSelected,
				bumper: bumper,
				bumperSelected: sssAutoBumperSelected,
				noOption: noOption,
				noOptionSelected: sssAutoNoOptionSelected,
				appIcon: sssAutoLogo,
			};
		case 'banesco':
			return {
				logo: banescoLogo,
				logoDark: banescoLogo,
				headerLogo: banescoHeaderLogo,
				vehicleVial: banescoVial,
				situationPin: banescoPin,
				offAppProviderIcon: banescoOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: banescoPin,
				starImage: starbanescoFull,
				headsetImage: banescoHeadset,
				successCheckmark: banescoCheckmark,
				minusSign: banescoMinus,
				plusSign: banescoPlus,
				parking: parking,
				parkingSelected: banescoParkingSelected,
				street: street,
				streetSelected: banescoStreetSelected,
				bumper: bumper,
				bumperSelected: banescoBumperSelected,
				noOption: noOption,
				noOptionSelected: banescoNoOptionSelected,
				appIcon: banescoLogo,
			};
		case 'acerta':
			return {
				logo: acertaLogo,
				logoDark: acertaLogo,
				headerLogo: acertaHeaderLogo,
				vehicleVial: acertaVial,
				situationPin: acertaPin,
				offAppProviderIcon: acertaOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: acertaPin,
				starImage: staracertaFull,
				headsetImage: acertaHeadset,
				successCheckmark: acertaCheckmark,
			};
		case 'seguros-mundial':
			return {
				logo: segurosMundialLogo,
				logoDark: segurosMundialLogo,
				headerLogo: segurosMundialLogo,
				vehicleVial: segurosMundialVial,
				situationPin: segurosMundialPin,
				offAppProviderIcon: segurosMundialOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: segurosMundialPin,
				starImage: segurosMundialStar,
				headsetImage: segurosMundialHeadset,
				successCheckmark: segurosMundialCheckmark,
				minusSign: segurosMundialMinus,
				plusSign: segurosMundialPlus,
				parking: parking,
				parkingSelected: segurosMundialParkingSelected,
				street: street,
				streetSelected: segurosMundialStreetSelected,
				bumper: bumper,
				bumperSelected: segurosMundialBumperSelected,
				noOption: noOption,
				noOptionSelected: segurosMundialNoOptionSelected,
				appIcon: segurosMundialLogo,
			};
		case 'ricardo-perez':
			return {
				logo: ricardoPerezLogo,
				logoDark: ricardoPerezLogo,
				headerLogo: ricardoPerezLogo,
				vehicleVial: ricardoPerezVial,
				situationPin: ricardoPerezPin,
				offAppProviderIcon: ricardoPerezOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: ricardoPerezPin,
				starImage: ricardoPerezStar,
				headsetImage: ricardoPerezHeadset,
				successCheckmark: ricardoPerezCheckmark,
				minusSign: ricardoPerezMinus,
				plusSign: ricardoPerezPlus,
				parking: parking,
				parkingSelected: ricardoPerezParkingSelected,
				street: street,
				streetSelected: ricardoPerezStreetSelected,
				bumper: bumper,
				bumperSelected: ricardoPerezBumperSelected,
				noOption: noOption,
				noOptionSelected: ricardoPerezNoOptionSelected,
				appIcon: ricardoPerezLogo,
			};
		case 'optima-seguros':
			return {
				logo: optimaSegurosLogo,
				logoDark: optimaSegurosLogo,
				headerLogo: optimaSegurosLogo,
				vehicleVial: optimaSegurosVial,
				situationPin: optimaSegurosPin,
				offAppProviderIcon: optimaSegurosOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: optimaSegurosPin,
				starImage: optimaSegurosStar,
				headsetImage: optimaSegurosHeadset,
				successCheckmark: optimaSegurosCheckmark,
				minusSign: optimaSegurosMinus,
				plusSign: optimaSegurosPlus,
				parking: parking,
				parkingSelected: optimaSegurosParkingSelected,
				street: street,
				streetSelected: optimaSegurosStreetSelected,
				bumper: bumper,
				bumperSelected: optimaSegurosBumperSelected,
				noOption: noOption,
				noOptionSelected: optimaSegurosNoOptionSelected,
				appIcon: optimaSegurosLogo,
			};
		case 'kia':
			return {
				logo: kiaLogo,
				logoDark: kiaLogo,
				headerLogo: kiaLogo,
				vehicleVial: kiaVial,
				situationPin: kiaPin,
				offAppProviderIcon: kiaOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: kiaPin,
				starImage: kiaStar,
				headsetImage: kiaHeadset,
				successCheckmark: kiaCheckmark,
				minusSign: kiaMinus,
				plusSign: kiaPlus,
				parking: parking,
				parkingSelected: kiaParkingSelected,
				street: street,
				streetSelected: kiaStreetSelected,
				bumper: bumper,
				bumperSelected: kiaBumperSelected,
				noOption: noOption,
				noOptionSelected: kiaNoOptionSelected,
				appIcon: kiaLogo,
			};
		case 'lexus':
			return {
				logo: lexusLogo,
				logoDark: lexusLogo,
				headerLogo: lexusLogo,
				vehicleVial: lexusVial,
				situationPin: lexusPin,
				offAppProviderIcon: lexusOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: lexusPin,
				starImage: lexusStar,
				headsetImage: lexusHeadset,
				successCheckmark: lexusCheckmark,
				minusSign: lexusMinus,
				plusSign: lexusPlus,
				parking: parking,
				parkingSelected: lexusParkingSelected,
				street: street,
				streetSelected: lexusStreetSelected,
				bumper: bumper,
				bumperSelected: lexusBumperSelected,
				noOption: noOption,
				noOptionSelected: lexusNoOptionSelected,
				appIcon: lexusLogo,
			};
		case 'infiniti':
			return {
				logo: infinitiLogo,
				logoDark: infinitiLogo,
				headerLogo: infinitiLogo,
				vehicleVial: infinitiVial,
				situationPin: infinitiPin,
				offAppProviderIcon: infinitiOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: infinitiPin,
				starImage: infinitiStar,
				headsetImage: infinitiHeadset,
				successCheckmark: infinitiCheckmark,
				minusSign: infinitiMinus,
				plusSign: infinitiPlus,
				parking: parking,
				parkingSelected: infinitiParkingSelected,
				street: street,
				streetSelected: infinitiStreetSelected,
				bumper: bumper,
				bumperSelected: infinitiBumperSelected,
				noOption: noOption,
				noOptionSelected: infinitiNoOptionSelected,
				appIcon: infinitiLogo,
			};
		case 'mitsubishi':
			return {
				logo: mitsubishiLogo,
				logoDark: mitsubishiLogo,
				headerLogo: mitsubishiLogo,
				vehicleVial: mitsubishiVial,
				situationPin: mitsubishiPin,
				offAppProviderIcon: mitsubishiOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: mitsubishiPin,
				starImage: mitsubishiStar,
				headsetImage: mitsubishiHeadset,
				successCheckmark: mitsubishiCheckmark,
				minusSign: mitsubishiMinus,
				plusSign: mitsubishiPlus,
				parking: parking,
				parkingSelected: mitsubishiParkingSelected,
				street: street,
				streetSelected: mitsubishiStreetSelected,
				bumper: bumper,
				bumperSelected: mitsubishiBumperSelected,
				noOption: noOption,
				noOptionSelected: mitsubishiNoOptionSelected,
				appIcon: mitsubishiLogo,
			};
		case 'hyundai':
			return {
				logo: hyundaiLogo,
				logoDark: hyundaiLogo,
				headerLogo: hyundaiLogo,
				vehicleVial: hyundaiVial,
				situationPin: hyundaiPin,
				offAppProviderIcon: hyundaiOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: hyundaiPin,
				starImage: hyundaiStar,
				headsetImage: hyundaiHeadset,
				successCheckmark: hyundaiCheckmark,
				minusSign: hyundaiMinus,
				plusSign: hyundaiPlus,
				parking: parking,
				parkingSelected: hyundaiParkingSelected,
				street: street,
				streetSelected: hyundaiStreetSelected,
				bumper: bumper,
				bumperSelected: hyundaiBumperSelected,
				noOption: noOption,
				noOptionSelected: hyundaiNoOptionSelected,
				appIcon: hyundaiLogo,
			};
		case 'toyota':
			return {
				logo: toyotaLogo,
				logoDark: toyotaLogo,
				headerLogo: toyotaLogo,
				vehicleVial: toyotaVial,
				situationPin: toyotaPin,
				offAppProviderIcon: toyotaOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: toyotaPin,
				starImage: toyotaStar,
				headsetImage: toyotaHeadset,
				successCheckmark: toyotaCheckmark,
				minusSign: toyotaMinus,
				plusSign: toyotaPlus,
				parking: parking,
				parkingSelected: toyotaParkingSelected,
				street: street,
				streetSelected: toyotaStreetSelected,
				bumper: bumper,
				bumperSelected: toyotaBumperSelected,
				noOption: noOption,
				noOptionSelected: toyotaNoOptionSelected,
				appIcon: toyotaLogo,
			};
		case 'nissan':
			return {
				logo: nissanLogo,
				logoDark: nissanLogo,
				headerLogo: nissanLogo,
				vehicleVial: nissanVial,
				situationPin: nissanPin,
				offAppProviderIcon: nissanOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: nissanPin,
				starImage: nissanStar,
				headsetImage: nissanHeadset,
				successCheckmark: nissanCheckmark,
				minusSign: nissanMinus,
				plusSign: nissanPlus,
				parking: parking,
				parkingSelected: nissanParkingSelected,
				street: street,
				streetSelected: nissanStreetSelected,
				bumper: bumper,
				bumperSelected: nissanBumperSelected,
				noOption: noOption,
				noOptionSelected: nissanNoOptionSelected,
				appIcon: nissanLogo,
			};
		case 'general-de-seguros':
			return {
				logo: generalDeSegurosLogo,
				logoDark: generalDeSegurosLogo,
				headerLogo: generalDeSegurosLogo,
				vehicleVial: generalDeSegurosVial,
				situationPin: generalDeSegurosPin,
				offAppProviderIcon: generalDeSegurosOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: generalDeSegurosPin,
				starImage: generalDeSegurosStar,
				headsetImage: generalDeSegurosHeadset,
				successCheckmark: generalDeSegurosCheckmark,
				minusSign: generalDeSegurosMinus,
				plusSign: generalDeSegurosPlus,
				parking: parking,
				parkingSelected: generalDeSegurosParkingSelected,
				street: street,
				streetSelected: generalDeSegurosStreetSelected,
				bumper: bumper,
				bumperSelected: generalDeSegurosBumperSelected,
				noOption: noOption,
				noOptionSelected: generalDeSegurosNoOptionSelected,
				appIcon: generalDeSegurosLogo,
			};
		case 'internacional-de-seguros':
			return {
				logo: internacionalDeSegurosLogo,
				logoDark: internacionalDeSegurosLogo,
				headerLogo: internacionalDeSegurosLogo,
				vehicleVial: internacionalDeSegurosVial,
				situationPin: internacionalDeSegurosPin,
				offAppProviderIcon: internacionalDeSegurosOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: internacionalDeSegurosPin,
				starImage: internacionalDeSegurosStar,
				headsetImage: internacionalDeSegurosHeadset,
				successCheckmark: internacionalDeSegurosCheckmark,
				minusSign: internacionalDeSegurosMinus,
				plusSign: internacionalDeSegurosPlus,
				parking: parking,
				parkingSelected: internacionalDeSegurosParkingSelected,
				street: street,
				streetSelected: internacionalDeSegurosStreetSelected,
				bumper: bumper,
				bumperSelected: internacionalDeSegurosBumperSelected,
				noOption: noOption,
				noOptionSelected: internacionalDeSegurosNoOptionSelected,
				appIcon: internacionalDeSegurosLogo,
			};
		case 'hdi':
			return {
				logo: hdiLogo,
				logoDark: hdiLogo,
				headerLogo: hdiLogo,
				vehicleVial: hdiVial,
				situationPin: hdiPin,
				offAppProviderIcon: hdiOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: hdiPin,
				starImage: hdiStar,
				headsetImage: hdiHeadset,
				successCheckmark: hdiCheckmark,
				minusSign: hdiMinus,
				plusSign: hdiPlus,
				parking: parking,
				parkingSelected: hdiParkingSelected,
				street: street,
				streetSelected: hdiStreetSelected,
				bumper: bumper,
				bumperSelected: hdiBumperSelected,
				noOption: noOption,
				noOptionSelected: hdiNoOptionSelected,
				appIcon: hdiLogo,
			};
		case 'fedpa':
			return {
				logo: fedpaLogo,
				logoDark: fedpaLogo,
				headerLogo: fedpaLogo,
				vehicleVial: fedpaVial,
				situationPin: fedpaPin,
				offAppProviderIcon: fedpaOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: fedpaPin,
				starImage: fedpaStar,
				headsetImage: fedpaHeadset,
				successCheckmark: fedpaCheckmark,
				minusSign: fedpaMinus,
				plusSign: fedpaPlus,
				parking: parking,
				parkingSelected: fedpaParkingSelected,
				street: street,
				streetSelected: fedpaStreetSelected,
				bumper: bumper,
				bumperSelected: fedpaBumperSelected,
				noOption: noOption,
				noOptionSelected: fedpaNoOptionSelected,
				appIcon: fedpaLogo,
			};
		case 'asc':
			return {
				logo: ascLogo,
				logoDark: ascLogo,
				headerLogo: ascLogo,
				vehicleVial: ascVial,
				situationPin: ascPin,
				offAppProviderIcon: ascOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: ascPin,
				starImage: ascStar,
				headsetImage: ascHeadset,
				successCheckmark: ascCheckmark,
				minusSign: ascMinus,
				plusSign: ascPlus,
				parking: parking,
				parkingSelected: ascParkingSelected,
				street: street,
				streetSelected: ascStreetSelected,
				bumper: bumper,
				bumperSelected: ascBumperSelected,
				noOption: noOption,
				noOptionSelected: ascNoOptionSelected,
				appIcon: ascLogo,
			};
		case 'kinto-one':
			return {
				...defaultImages,
				logo: kintoOneLogo,
				logoDark: kintoOneLogo,
				headerLogo: kintoOneLogo,
				vehicleVial: kintoOneVial,
				starImage: kintoOneStar,
			};
		case 'atlas':
			return {
				...defaultImages,
				logo: atlasLogo,
				logoDark: atlasLogo,
				headerLogo: atlasLogo,
			};
		case 'afirme':
			return {
				...defaultImages,
				logo: afirmeLogo,
				logoDark: afirmeLogo,
				headerLogo: afirmeLogo,
			};
		case 'kavak-mx':
			return {
				...defaultImages,
				logo: kavakMxLogo,
				logoDark: kavakMxLogo,
				headerLogo: kavakMxLogo,
			};
		case 'clupp':
			return {
				...defaultImages,
				logo: cluppLogo,
				logoDark: cluppLogo,
				headerLogo: cluppLogo,
			};
		case 'crabi':
			return {
				...defaultImages,
				logo: crabiLogo,
				logoDark: crabiLogo,
				headerLogo: crabiLogo,
			};
		case 'quan':
			return {
				...defaultImages,
				logo: quanLogo,
				logoDark: quanLogo,
				headerLogo: quanLogo,
			};
		case 'autochilango':
			return {
				...defaultImages,
				logo: autochilangoLogo,
				logoDark: autochilangoLogo,
				headerLogo: autochilangoLogo,
				situationPin: autochilangoPin,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: autochilangoPin,
				appIcon: autochilangoLogo,
				vehicleVial: autochilangoVial,
				offAppProviderIcon: autochilangoOffAppProviderIcon,
				starImage: autochilangoStar,
				headsetImage: autochilangoHeadset,
				successCheckmark: autochilangoCheckmark,
				minusSign: autochilangoMinus,
				plusSign: autochilangoPlus,
				parking: parking,
				parkingSelected: autochilangoParkingSelected,
				street: street,
				streetSelected: autochilangoStreetSelected,
				bumper: bumper,
				bumperSelected: autochilangoBumperSelected,
				noOption: noOption,
				noOptionSelected: autochilangoNoOptionSelected,
			};
		case 'epark':
			return {
				logo: eparkLogo,
				logoDark: eparkLogo,
				headerLogo: eparkLogo,
				vehicleVial: eparkVial,
				situationPin: eparkPin,
				offAppProviderIcon: eparkOffAppProviderIcon,
				placeSituationPin: PLACE_SITUATION_PIN.bottom,
				permissionPin: eparkPin,
				starImage: eparkStar,
				headsetImage: eparkHeadset,
				successCheckmark: eparkCheckmark,
				minusSign: eparkMinus,
				plusSign: eparkPlus,
				parking: parking,
				parkingSelected: eparkParkingSelected,
				street: street,
				streetSelected: eparkStreetSelected,
				bumper: bumper,
				bumperSelected: eparkBumperSelected,
				noOption: noOption,
				noOptionSelected: eparkNoOptionSelected,
				appIcon: eparkLogo,
			};
		default:
			return {
				...defaultImages,
			};
	}
};

export const parseObjToQuery = (obj: any): string => {
	const params = Object.keys(obj).map((key) => {
		return `${key}=${obj[key]}`;
	});
	return `?${params.join('&')}`;
};

export const parseFromStringToDataType = (value: string) => {
	if (!isNaN(+value)) return +value;
	if (value === 'true') return true;
	if (value === 'false') return false;
	return value;
};

export const parseQueryToObj = <T = any>(query: string): any => {
	query = query.replace('?', '');
	const pairs = query.split('&');
	const obj: any = {};
	pairs.forEach((p) => {
		const [key, value] = p.split('=');
		obj[key] = parseFromStringToDataType(value);
	});

	return obj as T;
};

export const parseToHelios = (ctx: AppContext): any => {
	const firstName = ctx.clientForm.fullName.split(' ')[0];
	const lastName = ctx.clientForm.fullName.substring(firstName.length).trim();
	const sourceData = getSourceData(ctx.brand, !!ctx.userExternalParams?.isFromWebview);
	const notes = buildNotes(ctx);
	const policyNumber = ctx.userExternalParams.policyNumber;

	let privacyPolicyAcceptanceDate = null;
	if (ctx.clientForm.privacyPolicyAcceptanceDate) {
		privacyPolicyAcceptanceDate = new Date(ctx.clientForm.privacyPolicyAcceptanceDate).toISOString();
	}

	return {
		serviceId: ctx.service?._id ?? null,
		// eslint-disable-next-line @typescript-eslint/camelcase
		account_id: ctx.account.salesforce,
		status: 'Queued',
		situation: ctx.situation,
		category: ctx.category || undefined,
		clientName: firstName,
		clientLastName: lastName,
		clientPhone1: ctx.clientForm.phone,
		clientEmail: ctx.clientForm.email,
		vehPlate: ctx.clientForm.plate.toUpperCase(),
		vehMake: ctx.clientForm.make,
		vehModel: ctx.clientForm.model,
		vehYear: ctx.clientForm.year,
		vehColor: ctx.clientForm.color,
		creationDate: Date.now(),
		sitLatitude: ctx.locations.situation.address ? ctx.locations.situation.lat : undefined,
		sitLongitude: ctx.locations.situation.address ? ctx.locations.situation.lng : undefined,
		sitAddress: ctx.locations.situation.address,
		sitReferenceAddress: !ctx.locations.situation.address ? ctx.referenceAddressForm.fullAddress : undefined,
		sitPlaceId: ctx.locations.situation.address ? ctx.locations.situation.placeId : undefined,
		destLatitude: ctx.locations.destination.lat,
		destLongitude: ctx.locations.destination.lng,
		destAddress: ctx.locations.destination.address,
		destPlaceId: ctx.locations.destination.placeId,
		price: ctx.customerFee,
		paymentMethod: ctx.payment.paymentMethod,
		cardIdStripe: ctx.payment.cardIdStripe,
		chargeIdStripe: ctx.payment.chargeIdStripe,
		customerIdStripe: ctx.payment.customerIdStripe,
		currency: 'usd',
		isCovered: false,
		// eslint-disable-next-line @typescript-eslint/camelcase
		email_payment: ctx.payment.emailPayment,
		notes,
		fromConnectApp: true,
		sourceData,
		privacyPolicyAcceptanceDate,
		externalId: ctx.service.external.id ?? null,
		schedule: ctx.service.schedule ?? null,
		customerContract: ctx.customerContract ?? null,
		pushOptIn: ctx.pushOptIn,
		isFromCheckLimit: ctx.brand !== ctx.previousAccount?.name,
		isMMMHOD: ctx.isMMMHOD,
		DIDNumber: ctx.campaign?.name,
		policyNumber: policyNumber,
	};
};

const buildNotes = ({ notes, homeNotes, situation, serviceTypes }: AppContext): string => {
	let noteStr = 'From Client: ';

	const serviceType = serviceTypes[situation]?.type;
	if (serviceType === 'home') {
		noteStr += `${homeNotes.date}. ${homeNotes.availability}.`;
	} else noteStr += `${notes}`;
	return noteStr;
};

export const loadAthMovilScript = (): void => {
	const jqueryScript = document.createElement('script');
	jqueryScript.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js';
	jqueryScript.type = 'text/javascript';
	jqueryScript.async = true;
	jqueryScript.addEventListener('load', () => {
		const athMovilScript = document.createElement('script');
		athMovilScript.src = 'https://www.athmovil.com/api/js/v3/athmovilV3.js';
		document.body.append(athMovilScript);
	});
	document.body.append(jqueryScript);
};

export const formatDate = (date: Date): string => {
	return dayjs(date).format('MM/DD/YYYY');
};

/**
 * It searches i18n files to check if there is a label for the situation, if not,
 * then check the serviceTypes list to find the correct label, if non is find, then
 * display 'No Label'
 * @param {string} situation - The situation name
 * @param {ServiceType[]} serviceTypes - This is an array of objects that contains the service types.
 * @returns A string
 */
export const getSituationLabel = (situation: Situation, serviceTypes?: ServiceTypesObject): string => {
	const label = i18n.get(`utils.situation.${situation}`, '');
	if (label) {
		return label;
	}
	const serviceType = serviceTypes?.[situation];
	return serviceType ? serviceType.label_es ?? serviceType.label : i18n.get('utils.situation.assistance', 'Asistencia');
};

/**
 * It searches i18n files to check if there is a label for the category, if not,
 * then check the serviceTypes list to find the correct label, if non is find, then
 * display 'No Label'
 * @param {string} situation - The situation name
 * @param {ServiceType[]} serviceTypes - This is an array of objects that contains the service types.
 * @returns A string
 */
export const getCategoryLabel = (
	category: homeCategories,
	situation?: Situation,
	serviceTypes?: ServiceTypesObject
): string => {
	const label = i18n.get(`utils.category.${category}`, '');
	if (label) {
		return label;
	}
	const serviceType = serviceTypes?.[situation as string];
	return serviceType?.category ? serviceType.category : 'No Label';
};

export function getCorporateAccount(domain: string): CorporateAccount {
	switch (true) {
		// Aqui iria el dominio que el cliente vaya a utilizar.
		case domain.includes(HONDA):
			return {
				status: true,
				account: '5c9d123f7332c86ef58e1bcb',
				brand: 'honda',
			};
		case domain.includes(ACURA):
			return {
				status: true,
				account: '5c9d123f7332c86ef58e1c20',
				brand: 'acura',
			};
		case domain.includes(MAZDA):
			return {
				status: true,
				account: '5c9d123f7332c86ef58e1bc5',
				brand: 'mazda',
			};

		default:
			return {
				status: false,
				account: '',
				brand: '',
			};
	}
}

const phones: Record<string, ContactPhone> = {
	'multiple-asistencia-en-viaje': { whatsapp: '17872307550', phone: '7872307550' },
	'multiple-asistencia-en-el-hogar': { whatsapp: '17872307550', phone: '7872307550' },
	'human-advantage': { whatsapp: '17872003199', phone: '7872003189' },
	honda: { whatsapp: '17872003199', phone: '7879190234' },
	acura: { whatsapp: '17872003199', phone: '7879190235' },
	mazda: { whatsapp: '17872003199', phone: '7879190236' },
	'connect-assistance': { whatsapp: '17872003199', phone: '7879995050' },
	'multi-auto': { whatsapp: '17872003199', phone: '7872003191' },
	'sss-auto': { whatsapp: '17872003199', phone: '8662857295' },
	'sss-home': { whatsapp: '17872003199', phone: '8662857295' },
	mmm: { whatsapp: '', phone: '7876998584' },
	'mmm-empleados': { whatsapp: '', phone: '7876998587' },
	connect: { whatsapp: '17872003199', phone: '7879995050' },
	'connect-home-repair-ondemand-pr': { whatsapp: '7879995050', phone: '7879995050' },
	volkswagen: { whatsapp: '17872003199', phone: '7872003542' },
	assa: { whatsapp: '50625046000', phone: '8008002772' },
	lincoln: { whatsapp: '17872003199', phone: '7872003512' },
	'optima-seguros': { whatsapp: '17872003199', phone: '7876253951' },
	kia: { whatsapp: '17872003199', phone: '7879995050' },
	lexus: { whatsapp: '17872003199', phone: '7879995050' },
	infiniti: { whatsapp: '17872003199', phone: '7879995050' },
	mitsubishi: { whatsapp: '17872003199', phone: '7879995050' },
	hyundai: { whatsapp: '17872003199', phone: '7879995050' },
	toyota: { whatsapp: '17872003199', phone: '7879995050' },
	nissan: { whatsapp: '17872003199', phone: '7879995050' },
	asc: { phone: '7872003583' },
};

const ptyPhones: Record<string, ContactPhone> = {
	'connect-assistance': { whatsapp: '50769009000', phone: '50769009000' },
	connect: { whatsapp: '50769009000', phone: '50769009000' },
	sura: { whatsapp: '50763287872', phone: '8007872' },
	banesco: { whatsapp: '50769785973', phone: '5073782675' },
	acerta: { whatsapp: '50763253030', phone: '5073073000' },
	'general-de-seguros': { whatsapp: '50769009000', phone: '5073072626' },
	'ricardo-perez': { whatsapp: '50769800600', phone: '5073680600', serviceCoordination: '5073680600' },
	'internacional-de-seguros': { whatsapp: '50766662881', phone: '2652881' },
	fedpa: { whatsapp: '50765700700', phone: '3782659' },
	default: { whatsapp: '50769009000', phone: '50769009000' },
};

const coPhones: Record<string, ContactPhone> = {
	'sura-co': { whatsapp: '15143850', phone: '15143850' },
	kavak: { whatsapp: '15143850', phone: '15143850' },
	vanti: { whatsapp: '', phone: '6013143851' },
	auteco: { whatsapp: '', phone: '6015143854' },
	'seguros-mundial': { whatsapp: '', phone: '6014322350' },
	hdi: { whatsapp: '3212039109', phone: '6017953719' },
	quan: { urlWhatsapp: 'https://wa.link/zi0bs5', phone: '+5714432570' },
	default: { whatsapp: '+5714432578', phone: '6014322350' },
};

const crPhones: Record<string, ContactPhone> = {
	assa: { whatsapp: '50625032772', phone: '50625032772' },
	epark: { whatsapp: '50625046043', phone: '50625046043' },
	default: { whatsapp: '50689582626', phone: '50689582626' },
};

const mxPhones: Record<string, ContactPhone> = {
	crabi: { whatsapp: '525588527287', phone: '8009990160' },
	autochilango: { whatsapp: '525631081726', phone: '5596895402' },
	default: { whatsapp: '5215631081726', phone: '5588547397' },
};

const serviceTypePhones: any = {
	mmm: { home: { phone: '7872003541' }, road: { phone: '7872003513' }, claims: { phone: '7872003513' } },
	'mmm-empleados': { home: { phone: '7876998587' }, road: { phone: '7876998587' }, claims: { phone: '7876998587' } },
	'optima-seguros': { home: { phone: '7876253951' }, road: { phone: '7877652125' }, claims: { phone: '7877652125' } },
	hdi: { home: { phone: '+576017953712' }, road: { phone: '+576017953719' }, claims: { phone: '+576017953719' } },
};

export const customerServicePhones: Record<string, CustomerServicePhone> = {
	mmm: { phone: '18663335470' },
};

export function getContactPhone(brand: string, account?: Account): ContactPhone {
	let contactPhone: ContactPhone;

	if (account?.branch === Branches.PTY) {
		contactPhone = ptyPhones[brand] ?? ptyPhones['default'];
	} else if (account?.branch === Branches.CO) {
		contactPhone = coPhones[brand] ?? coPhones['default'];
	} else if (account?.branch === Branches.CRI) {
		contactPhone = crPhones[brand] ?? crPhones['default'];
	} else if (account?.branch === Branches.MX) {
		let contactMxPhone;
		if (account?.phone) {
			contactMxPhone = {
				whatsapp: mxPhones[brand]?.whatsapp || mxPhones.default.whatsapp,
				phone: account.phone,
			};
		}
		if (mxPhones[brand]) {
			contactMxPhone = mxPhones[brand];
		}
		if (!contactMxPhone) {
			contactMxPhone = mxPhones.default;
		}
		contactPhone = contactMxPhone;
	} else {
		contactPhone = phones[brand];
	}

	if (account?.whatsappPhone) {
		contactPhone.whatsapp = account?.whatsappPhone;
	}

	return contactPhone;
}

export const setPushEnable = async (dispatch: DispatchFunction): Promise<void> => {
	setPushOptIn(dispatch, await OneSignal.isPushNotificationsEnabled());
};

export function getServiceTypeContactPhone(brand: string, serviceType = 'road'): string {
	return serviceTypePhones?.[brand]?.[serviceType]?.phone || '';
}

export const extractDomain = (url: string): string => {
	const { hostname } = new URL(url);
	return hostname;
};

export const searchAccount = async (accountId: string): Promise<any> => {
	const {
		data: { data },
	} = await new HeliosClient().getAccount(accountId);
	return data;
};

export const redirectTo = (history: any, isCorporate: boolean, brand: string): void => {
	if (isCorporate || CORPORATE_ACCOUNTS.includes(brand)) return history.replace('/corporate/home');
	history.replace('/services');
};

export const getDomainParams = (
	url: string,
	locationSearch: string,
	branch: string | null
): {
	accountId: string;
	brandName: string;
	isCorporateStatus: boolean;
	plate: string | null;
	fullName: string | null;
	isFromWebview: boolean | null;
	track: TRACKS | null;
	isFromCheckLimit: boolean | null;
	campaign: string | null;
	policyNumber: string | null;
	event: string | null;
} => {
	const domainInfo = getCorporateAccount(extractDomain(url));
	const queryParams = new URLSearchParams(locationSearch);

	let accountId: string;
	let brandName: string | null;
	let isCorporateStatus = false;
	const plate = queryParams.get('plate');
	const fullName = queryParams.get('fullName');
	const isFromWebview = queryParams.get('isFromWebview') === 'true';
	const track = queryParams.get('track') as TRACKS;
	const isFromCheckLimit = queryParams.get('isFromCheckLimit') === 'true';
	const campaign = queryParams.get('campaign');
	const policyNumber = queryParams.get('policyNumber');
	const event = queryParams.get('event');

	if (domainInfo.status) {
		accountId = domainInfo.account || ON_DEMAND_ACCOUNT_ID;
		brandName = domainInfo.brand;
		isCorporateStatus = true;
	} else {
		accountId = queryParams.get('account') || getOnDemandAccount(branch || 'Puerto Rico')?._id;
		brandName = queryParams.get('brand') || getOnDemandAccount(branch || 'Puerto Rico')?.brand;
	}

	return {
		accountId,
		brandName,
		isCorporateStatus,
		plate,
		fullName,
		isFromWebview,
		isFromCheckLimit,
		track,
		campaign,
		policyNumber,
		event,
	};
};

export const convertHexToRGBA = (hexCode: string, opacity = 1): string => {
	let hex = hexCode.replace('#', '');

	if (hex.length === 3) {
		hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
	}

	const r = parseInt(hex.substring(0, 2), 16);
	const g = parseInt(hex.substring(2, 4), 16);
	const b = parseInt(hex.substring(4, 6), 16);

	/* Backward compatibility for whole number based opacity values. */
	if (opacity > 1 && opacity <= 100) {
		opacity = opacity / 100;
	}

	return `rgba(${r},${g},${b},${opacity})`;
};

export const getCountryCode = (branch: string): string => {
	switch (branch) {
		case Branches.CRI:
			return 'CRC';
		case Branches.PR:
			return 'PRI';
		case Branches.PTY:
			return 'PAN';
		default:
			return 'CRC';
	}
};

export const getFileExtension = (type: string): string => {
	if (type.includes('png')) {
		return '.png';
	} else if (type.includes('jpg')) {
		return '.jpg';
	} else if (type.includes('jpeg')) {
		return '.jpeg';
	} else if (type.includes('mp4')) {
		return '.mp4';
	} else {
		console.warn('Unknown extension', type);
		const guess = type.split('/')[1];

		return '.' + (guess || 'file'); // try our best effort, else fallback to .file
	}
};

export const transformToInvertImage = async (currentFile: File): Promise<string> => {
	const buffer: any = await currentFile.arrayBuffer();
	const readImage = await Jimp.read(buffer);
	const convert = readImage.quality(30).invert().brightness(-0.09);

	return await convert.getBase64Async(Jimp.MIME_JPEG);
};

export const updateHeadTags = (brand: string): void => {
	let brandForUrl = WHITELABEL_BRANDS.includes(brand) ? brand : 'default';
	if (['sura-co', 'kavak', 'auteco'].includes(brand)) {
		brandForUrl = 'sura';
	}

	let favicon = document.querySelector("link[rel~='icon']") as HTMLLinkElement | null;
	if (!favicon) {
		favicon = document.createElement('link');
		favicon.rel = 'icon';
		document.getElementsByTagName('head')[0].appendChild(favicon);
	}
	favicon.href = `/${brandForUrl}-favicon.ico`;

	let manifest = document.querySelector("link[rel~='manifest']") as HTMLLinkElement | null;
	if (!manifest) {
		manifest = document.createElement('link');
		manifest.rel = 'manifest';
		document.getElementsByTagName('head')[0].appendChild(favicon);
	}
	manifest.href = `/manifest-${brandForUrl}.json`;

	let appTouch = document.querySelector("link[rel~='apple-touch-icon']") as HTMLLinkElement | null;
	if (!appTouch) {
		appTouch = document.createElement('link');
		appTouch.rel = 'apple-touch-icon';
		document.getElementsByTagName('head')[0].appendChild(favicon);
	}
	appTouch.href = `/${brandForUrl}-logo.png`;
};

export const capitalizeWordsOfString = (str: string): string => {
	const arr = str.split(' ');
	return arr
		.map((element: string) => {
			return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase();
		})
		.join(' ');
};

export const isInspectionCRPA = (situation: string | undefined, branch: Branches): boolean => {
	return Boolean(situation === Situation.INSPECTION && [Branches.CRI, Branches.PTY].includes(branch));
};

export const getMediaIdBranch = (branch: Branches): MediaId => {
	switch (branch) {
		case Branches.CRI:
			return MediaId.COINSPECT_CRC;
		case Branches.PTY:
			return MediaId.COINSPECT_PAN;
		case Branches.PR:
			return MediaId.COINSPECT_PR;
		default:
			return MediaId.COINSPECT_CRC;
	}
};

export const getBrandsWithDarkText = () => ['optima-seguros', 'internacional-de-seguros', 'hdi', 'fepda'];

export const getBrandSourceName = (brand: string): string | null => {
	switch (brand) {
		case 'multiple-asistencia-en-viaje':
		case 'inspecciones-csm':
			return 'csm';
		case 'sss-auto':
		case 'sss-home':
			return 'sss';
		case 'ricardo-perez':
			return 'ricardo-perez';
		default:
			return 'connect';
	}
};

const getSourceData = (brand: string, fromWebview: boolean) => {
	const userAgentParsed: UserAgentParser.IResult = UserAgentParser(navigator.userAgent);
	const connectAppSource = brand ? getBrandSourceName(brand) : 'connect';

	const userAgent: {
		userAgent: string;
		os: UserAgentParser.IOS;
		browser: UserAgentParser.IBrowser;
		device?: UserAgentParser.IDevice;
	} = {
		userAgent: userAgentParsed.ua,
		os: userAgentParsed.os,
		browser: userAgentParsed.browser,
	};

	if (userAgentParsed.device?.model) {
		userAgent.device = userAgentParsed.device;
	}

	return {
		type: 'connect_app',
		userAgent,
		connectAppSource: fromWebview ? `${connectAppSource}_webview` : connectAppSource,
	};
};

export const canDisplayCommentsAndPicturesScreen = (
	serviceNumber: number,
	brand: Brand,
	currentTrack: TRACKS,
	situation: Situation
) => {
	return (
		serviceNumber &&
		(currentTrack === TRACKS.HOME || (brand === Brand.MMM && situation === Situation.GLASS_REPLACEMENT))
	);
};

export const renderTipView = (brand: string, branch: Branches, serviceType: TRACKS): boolean => {
	return CONNECT_BRAND_NAMES.includes(brand) && branch === Branches.PR && serviceType !== TRACKS.HOME;
};

export const validateServiceTracking = (serviceId: string) => {
	const services = localStorage?.services ? JSON.parse(localStorage?.services) : null;

	if (!services) {
		localStorage.setItem(
			'services',
			JSON.stringify({
				[serviceId]: true,
			})
		);

		return false;
	} else if (services[serviceId]) {
		return true;
	} else {
		services[serviceId] = true;
		localStorage.setItem('services', JSON.stringify(services));

		return false;
	}
};

export const isDriverActive = (driver: Driver) => {
	return Boolean(driver?.lat && driver?.lng);
};

export function isNotNull<T>(value: T | null): value is T {
	return value !== null;
}

export const createOnSubmitServiceUrl = (service: any, integrations?: Integration) => {
	let url: URL;
	// If this account does not have integrations, it should return to the original workflow
	if (!integrations?.onSuccessRedirect) {
		return;
	}

	// If the onSuccessRedirect starts with / means its needs to be added to the original path
	if (integrations?.onSuccessRedirect?.startsWith('/')) {
		url = new URL(integrations.onSuccessRedirect, window.location.origin);
	} else {
		url = new URL(integrations.onSuccessRedirect);
	}

	// Adds dynamic params to the current url as query params
	for (const key of integrations.onSuccessDynamicParams) {
		if (key === ON_SUCCESS_DYNAMIC_PARAMS.SERVICE_NUMBER) {
			url.searchParams.append(ON_SUCCESS_DYNAMIC_PARAMS.SERVICE_NUMBER, service.eventId);
		}
	}

	return url.href;
};

export const getCampaignByName = async (campaign: string) => {
	const { data: campaignResponse } = await new HeliosClient().getCampaignByName(campaign ?? '');

	if (!campaignResponse.status) return null;

	return campaignResponse.data;
};

export const getRedirectPathByAccount = (sfIdAccount: string, defaultPath: string, serviceStatus: string): string => {
	if (defaultPath === 'survey' && ACCOUNTS_WITH_DISABLED_IN_HOUSE_SURVEY.includes(sfIdAccount)) {
		return 'finished';
	}

	if (ACCOUNTS_WITH_DISABLED_IN_HOUSE_SURVEY_TO_SOCIAL.includes(sfIdAccount) && ['Finished'].includes(serviceStatus)) {
		return 'social';
	}

	return defaultPath;
};
