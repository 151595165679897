import React, { FunctionComponent } from 'react';
import { getImagesByBrand } from '../../utils/Utils';

import starEmpty from '../../assets/imgs/Star_vector.svg';

import './Star.scss';

interface StarProps {
	selected?: boolean;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
	brand: string;
}

const Star: FunctionComponent<StarProps> = ({ selected = false, onClick, brand }): JSX.Element => {
	return (
		<img
			className="star-icon"
			src={selected ? `${getImagesByBrand(brand).starImage}` : `${starEmpty}`}
			alt="Star"
			onClick={onClick}
		/>
	);
};

export default Star;
