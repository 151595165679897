import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    transform: scale(0);
    opacity: 1;
  }
  30% {
    transform: scale(0.30);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
`;

const PulseIndicatorContainer = styled.div`
	width: 60px;
	height: 60px;
	position: absolute;
	left: -31px;
	top: -22px;
	z-index: 0;
`;

const Circle1 = styled.div`
	opacity: 0;
	width: 60px;
	height: 60px;
	border-radius: 60px;
	background: ${({ theme }) => theme.secondaryColor};
	position: absolute;
	top: 0;
	left: 0;
	animation-name: ${pulse};
	animation-duration: 4s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	animation-delay: 0;
`;

const Circle2 = styled(Circle1)`
	animation-delay: 1.3s;
`;

const Circle3 = styled(Circle1)`
	animation-delay: 2.6s;
`;

const S = {
	PulseIndicatorContainer,
	Circle1,
	Circle2,
	Circle3,
};

export default S;
