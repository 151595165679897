import React from 'react';
import { useLottie } from 'lottie-react';
import coninspectionData from '../../../lotties/coninspection.json';
import './Animation.scss';

const CoinspectionAnimation = (): JSX.Element => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: coninspectionData,
	};

	const { View } = useLottie(defaultOptions);

	return (
		<div className="tracking-animation">
			<div className="lottie-container">
				<div>{View}</div>
			</div>
			<div className="text-animation">
				<p>
					Te invitamos a documentar algunos datos de tu accidente mientras esperas al inspector para que ahorres tiempo
					y continúes con tu día lo antes posible.
				</p>
			</div>
		</div>
	);
};

export default CoinspectionAnimation;
