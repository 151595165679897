import React, { FunctionComponent } from 'react';
import { ClientForm } from '../../../interfaces';
import './VehicleInfo.scss';
import { capitalizeWordsOfString } from '../../../utils/Utils';

interface VehicleInfoProps {
	clientInfo: ClientForm;
}

const VehicleInfo: FunctionComponent<VehicleInfoProps> = ({ clientInfo }) => {
	const getVehicleInfoText = (): string => {
		const { make, model, plate, year } = clientInfo;
		let textInfo = '';
		if (make) {
			textInfo += `${make} `;
		}

		if (model) {
			textInfo += `${model} `;
		}

		if (year) {
			if (make || model) {
				textInfo += `- ${year} `;
			} else {
				textInfo += `${year} `;
			}
		}

		if (textInfo) {
			textInfo += '- ';
		}

		textInfo += `Tablilla: ${plate}`;

		return textInfo;
	};

	return (
		<div className="vehicle-info">
			<p>{capitalizeWordsOfString(clientInfo.fullName)}</p>
			<p>{getVehicleInfoText()}</p>
			{clientInfo.vin && <p>VIN: {clientInfo.vin}</p>}
			<p>Teléfono: {clientInfo.phone}</p>
		</div>
	);
};

export default VehicleInfo;
