import React, { FunctionComponent, useState } from 'react';
import './Animation.scss';
import infoIcon from '../../../assets/imgs/info_icon-white.svg';
import { useHistory } from 'react-router';

interface ConinspectionInfoProps {
	className?: string;
}

const ConinspectionInfo: FunctionComponent<ConinspectionInfoProps> = ({ className }): JSX.Element => {
	const history = useHistory();
	const buttonDoc = 'button-doc';
	const footerDoc = 'footer-doc-accident';
	const [showInfo, setShowInfo] = useState(true);
	const [classButton, setClassButton] = useState(buttonDoc);
	const [classFooterDoc, setClassFooterDoc] = useState(footerDoc + ' ' + className);

	const closeBanner = () => {
		setClassButton(buttonDoc + ' hide-button');
		setClassFooterDoc(footerDoc + ' hide-footer-doc-accident');
		setShowInfo(false);
	};

	const openDoc = () => {
		history.push('/tracking/coninspection');
	};

	return (
		<div>
			<div className={classFooterDoc}>
				{showInfo && (
					<div>
						<button className="close-button" onClick={closeBanner}>
							X
						</button>

						<div className="info-text">
							<div>
								<img src={infoIcon} alt=""></img>
							</div>

							<div>
								<p>Ayúdanos a documentar tu accidente</p>
							</div>
						</div>
					</div>
				)}
				<button className={classButton} onClick={openDoc}>
					DOCUMENTAR ACCIDENTE
				</button>
			</div>
		</div>
	);
};

export default ConinspectionInfo;
