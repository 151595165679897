import { useEffect } from 'react';

function strokeColorBrand(brand: string): string {
	switch (brand) {
		case 'lafise':
			return '#0a3e2a';
		case 'multiple-asistencia-en-viaje':
		case 'inspecciones-csm':
			return '#006785';
		case 'sura':
		case 'sura-co':
		case 'kavak':
		case 'auteco':
			return '#023598';
		case 'qualitas':
			return '#792A82';
		case 'assa':
			return '#203a6e';
		case 'human-advantage':
			return '#93c855';
		default:
			return '#585858';
	}
}

function useFitMapToBounds(
	map: google.maps.Map | null,
	position1?: google.maps.LatLngLiteral | null,
	position2?: google.maps.LatLngLiteral | null
) {
	useEffect(() => {
		if (map && position1?.lat && position1?.lng && position2?.lat && position2?.lng) {
			const bounds = new window.google.maps.LatLngBounds();
			bounds.extend(position1);
			bounds.extend(position2);
			map.fitBounds(bounds);
		}
	}, [map, position1, position2]);
}

export { strokeColorBrand, useFitMapToBounds };
