import React, { FunctionComponent, useContext } from 'react';
import i18n from '../../../utils/i18n';
import cn from 'classnames';
import { staticOptions } from './index';
import { Context } from '../../../context/Context';
import { AppContext } from '../../../interfaces';
import { TRACKS } from '../../../interfaces';

import './styles.scss';

interface StaticOptionsProps {
	option: staticOptions;
	optionsLength: number;
}

const StaticOptions: FunctionComponent<StaticOptionsProps> = ({ option, optionsLength }): JSX.Element | null => {
	const { currentTrack } = useContext(Context) as AppContext;

	switch (option) {
		case staticOptions.currentLocation:
			return <span>{i18n.get('location.search.option_current_location', 'Utilizar localización actual')}</span>;
		case staticOptions.manualLocation:
			return <span>{i18n.get('location.search.option_choose_manual_address', 'Ingresar dirección manualmente')}</span>;
		case staticOptions.chooseOnMap:
			return (
				<span
					className={cn('location-search__last-option', {
						'border-bottom-primary-light-line': currentTrack === TRACKS.HOME ? optionsLength > 3 : optionsLength > 2,
					})}
				>
					{i18n.get('location.search.option_choose_on_map', 'Elija en el mapa')}
				</span>
			);
	}
	return null;
};

export default StaticOptions;
