function isValidLatLng(latLng: Partial<google.maps.LatLngLiteral | google.maps.LatLng>) {
	const latitude = latLng.lat;
	const longitude = latLng.lng;
	return (
		(typeof latitude === 'number' && typeof longitude === 'number') ||
		(typeof latitude === 'function' && typeof longitude === 'function')
	);
}

export { isValidLatLng };
