import React, { createContext, useReducer } from 'react';
import { Action, AppContext, homeCategories, Integration, PermissionType, Situation } from '../interfaces';
import { reducer } from './reducer';
import { ON_DEMAND_ACCOUNT_ID } from '../utils/constants';

const twoDaysFromNowDate = new Date().setDate(new Date().getDate() + 2);

export const Context = createContext({});

export const cleanState = {
	brand: 'connect',
	passengers: 0,
	notes: '',
	answers: [],
	category: '' as homeCategories,
	situation: '' as Situation,
	serviceType: '',
	homeNotes: {
		subService: '',
		availability: '',
		date: twoDaysFromNowDate,
		comments: '',
	},
	clientForm: {
		fullName: '',
		phone: '',
		plate: '',
		year: '',
		make: '',
		model: '',
		color: '',
	},
	referenceAddressForm: {
		address1: '',
		address2: '',
		city: '',
		zipCode: '',
		country: '',
		fullAddress: '',
	},
	locations: {
		permission: PermissionType.NOT_ASKED,
		provider: { lat: 0.0, lng: 0.0, address: '' },
		situation: { lat: 0.0, lng: 0.0, address: '' },
		destination: { lat: 0.0, lng: 0.0, address: '' },
	},
	customerFee: 0,
	distance: 0,
	service: {
		account: '',
		accountPhone: '',
		branch: 'Puerto Rico',
		serviceNumber: null,
		price: 0,
		taxRate: 0,
		date: new Date(),
		endDate: new Date(),
		situation: '',
		payments: [],
		driver: {
			branch: '',
			distance: '',
			email: '',
			eta: '',
			iconset: '',
			inspectorId: '',
			lat: '',
			lng: '',
			name: '',
			pendingSituation: '',
			phone: '',
			services: '',
			status: '',
			vehicleType: '',
			_id: '',
		},
		flags: { fromConnectApp: null },
		salesforce: {
			serviceId: '',
		},
		surveys: { answered: false },
		surplus: 0,
		external: { id: '' },
		schedule: '',
		serviceType: '',
	},
	payment: {
		customerIdStripe: '',
		chargeIdStripe: '',
		cardIdStripe: '',
		amount: 0,
		paymentMethod: '',
		emailPayment: '',
		tips: false,
		tipAmount: 3,
		tipPaymentType: '',
	},
	account: {
		name: 'Connect PR OnDemand',
		_id: ON_DEMAND_ACCOUNT_ID,
		smsName: '',
		branch: 'Puerto Rico',
		phone: '',
		salesforce: '',
	},
	pushOptIn: false,
	serviceTypes: {},
	isMMMHOD: false,
	integrations: {} as Integration,
};

export const AppContextProvider = (props: { state: any; children: any }): JSX.Element => {
	return <Context.Provider value={props.state}>{props.children}</Context.Provider>;
};

const AppState = (props: any): JSX.Element => {
	const dispatch = (action: Action): void => _dispatch(action);
	const stateBackup = JSON.parse(localStorage.getItem('state') || '{}');
	const data: AppContext = {
		dispatch,
		...cleanState,
		...stateBackup,
	};

	const [state, _dispatch] = useReducer(reducer, data);
	localStorage.setItem('state', JSON.stringify(state));

	return <AppContextProvider state={state}>{props.children} </AppContextProvider>;
};

export const { Consumer } = Context;

export default AppState;
