import { useState, useEffect } from 'react';
import moment from 'moment';
import { isAndroid, isIOS } from 'react-device-detect';

export const checkForMobile = () => {
	const standalone = (window.navigator as any).standalone;
	if (standalone) {
		return false;
	}
	const today: Date = moment().toDate();
	const lastPrompt = moment(localStorage.getItem('installPrompt'));
	const days = moment(today).diff(lastPrompt, 'days');
	const isMobile = isIOS || isAndroid;
	const prompt = (isNaN(days) || days > 30) && isMobile;

	if (prompt && 'localStorage' in window) {
		localStorage.setItem('installPrompt', today.toString());
	}

	return prompt;
};

const useIsMobile = () => {
	const [isMobile, setIsMobile] = useState({});

	useEffect(() => {
		setIsMobile(checkForMobile());
		return () => console.log('CLEANUP INSTALL PROMPT', isMobile);
		// eslint-disable-next-line
	}, []);

	return isMobile;
};

export default useIsMobile;
