import React from 'react';

import StarRatingInput from './StarRatingInput';
import NumericInput from './NumericInput';
import YesNoInput from './YesNoInput';
import SliderInput from './SliderInput';
import RatingRadioButton from './RatingRadioButton';
import Textarea from './Textarea';
import { SurveyInputTypes } from '../../../interfaces';

interface SurveyInputRendererProps {
	type: SurveyInputTypes;
	onChange: (value: any) => void;
	value: string | number | boolean;
	brand: string;
	name: string;
}

const SurveyInput: React.FC<SurveyInputRendererProps> = ({ onChange, value, brand, type, name }) => {
	const renderChoiceContent = (): React.ReactNode => {
		switch (type) {
			case SurveyInputTypes.START_RATING:
				return <StarRatingInput value={value as number} onChange={onChange} brand={brand} />;
			case SurveyInputTypes.YES_NO:
				return <YesNoInput value={value as boolean} onChange={onChange} />;
			case SurveyInputTypes.NUMERIC_STEPPER:
				return <NumericInput value={value as number} onChange={onChange} />;
			case SurveyInputTypes.SLIDER:
				return <SliderInput onChange={onChange} value={value as number} />;
			case SurveyInputTypes.TEXTAREA:
				return <Textarea onChange={onChange} value={value as string} />;
			case SurveyInputTypes.RATING_BUTTON:
				return <RatingRadioButton onChange={onChange} name={name} />;
			default:
				return null;
		}
	};

	return <>{renderChoiceContent()}</>;
};

export default SurveyInput;
