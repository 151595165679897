import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import moment from 'moment';
import { useHistory } from 'react-router';
import HeliosClient from '../../api/Helios';
import Modal from '../../components/common/Modal';
import CallModal from '../../components/tracking/driver/CallModal';
import DriverContact from '../../components/tracking/driver/Contact';
import DriverDetails from '../../components/tracking/driver/Details';
import DriverNoteModal from '../../components/tracking/driver/NoteModal';
import TrackingHeader from '../../components/tracking/Header';
import TrackingReceipt from '../../components/tracking/Receipt';
import { Context } from '../../context/Context';
import { ActionType, Service, AppContext, TRACKS, TripStatus } from '../../interfaces';
import { ACTIVE_TRIP_STATUS, LOADING_TRIP_STATUS, TRIP_STATUS_ORDER } from '../../utils/constants';
import TrackingAnimationContent from '../../components/tracking/TrackingAnimationContent';
import { getRedirectPathByAccount, isDriverActive } from '../../utils/Utils';
import i18n from '../../utils/i18n';
import useDriverLocation from '../../hooks/useDriverLocation';
import { setProviderCoords } from '../../context/actions';

import './Tracking.scss';

const LIBRARIES = ['places' as const, 'geometry' as const];
const TIME_TO_REQUEST = 17400;

const getInitialCurrentStatus = (service: Service) => {
	const nextDefaultStatus = isDriverActive(service.driver) ? TripStatus.ACCEPTED : TripStatus.ON_ROUTE;
	return service.trip?.status && ACTIVE_TRIP_STATUS.includes(service.trip.status)
		? service.trip.status
		: nextDefaultStatus;
};

const TrackingScreen = (): JSX.Element => {
	const { brand, service, dispatch, payment, integrationTracking } = useContext(Context) as AppContext;

	const history = useHistory();
	const [showNotesModal, setShowNotesModal] = useState<boolean>(false);
	const [showCallModal, setShowCallModal] = useState<boolean>(false);
	const [currentTripStatus, setCurrentTripStatus] = useState<TripStatus>(() => getInitialCurrentStatus(service));
	useDriverLocation();

	const updateCurrentTripStatus = useCallback(
		(newStatus: TripStatus): void => {
			if (TRIP_STATUS_ORDER[newStatus] > TRIP_STATUS_ORDER[currentTripStatus]) {
				setCurrentTripStatus(newStatus);
			}
		},
		[currentTripStatus]
	);

	useEffect(() => {
		const fetchService = async (): Promise<void> => {
			try {
				const {
					data: { data },
				} = await new HeliosClient().getService(service._id);
				updateCurrentTripStatus(data.trip.status);

				if (currentTripStatus !== data?.trip.status) {
					let driver = data?.driver;
					// If updating the same service with the same driver, prevent erasing previously
					// obtained eta and position for driver
					if (
						data?.driver?.id === service?.driver?.id &&
						typeof service?.driver?.lat === 'number' &&
						typeof service?.driver?.lng === 'number'
					) {
						driver = { ...service?.driver, ...data?.driver };
					}

					dispatch({ type: ActionType.SET_SERVICE, data: { ...data, driver } });
					// if transitioning to on route state, update provider location
					if (data?.trip.status === TripStatus.ON_ROUTE && data?.locations.provider) {
						setProviderCoords(data.locations.provider, dispatch);
					}
				}

				if (['Cancelled', 'Void'].includes(data.status)) history.replace('/cancelled');

				if (LOADING_TRIP_STATUS.includes(data.trip?.status)) history.replace('/tracking/loading');

				if (['Finished', 'Pending Audit'].includes(data.status) || data.trip.status === TripStatus.FINISHED) {
					dispatch({ type: ActionType.SET_SERVICE, data });

					if (integrationTracking?.onSuccessRedirect) {
						window.location.href = integrationTracking.onSuccessRedirect;
					} else {
						const redirectPath = getRedirectPathByAccount(data.sfIdAccount, 'survey', data.status);
						history.replace(`/${redirectPath}`);
					}
				}
			} catch (error) {
				console.error('Error fetching service: ', error);
			}
		};
		if (service._id) {
			fetchService();
		}
		const timer = setInterval(fetchService, TIME_TO_REQUEST);

		return (): void => clearInterval(timer);
		// eslint-disable-next-line
	}, [service.serviceNumber, service._id, dispatch, history, updateCurrentTripStatus, currentTripStatus, brand]);

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GMAPS_KEY || '',
		preventGoogleFontsLoading: true,
		libraries: LIBRARIES,
		version: '3',
	});

	const openNotesModal = (): void => setShowNotesModal(true);
	const dismissNotesModal = (): void => setShowNotesModal(false);
	const dismissCallModal = (): void => setShowCallModal(false);

	const estimatedTime = service.driver?.eta >= 0 ? moment().add(service.driver.eta, 'seconds').format('h:mma') : null;

	const getTitle = (): string | React.ReactNode => {
		switch (currentTripStatus) {
			case TripStatus.ACCEPTED: {
				const name = service.driver?.name || i18n.get('tracking.trip.accepted.default_driver_name', 'El chofer');
				return <>{i18n.get('tracking.trip.accepted.title', `${name} \n pronto estará contigo`, [name])}</>;
			}
			case TripStatus.ON_ROUTE:
				return i18n.get('tracking.trip.on_route.title', '¡Tu asistencia va en camino!');
			case TripStatus.ARRIVED:
			case TripStatus.TOWED:
				return i18n.get('tracking.trip.arrived.title', 'Brindando asistencia');
		}
		return '';
	};

	const getSubtitle = (): string => {
		switch (currentTripStatus) {
			case TripStatus.ON_ROUTE:
				return estimatedTime
					? i18n.get('tracking.trip.on_route.eta', `Hora estimada: ${estimatedTime}`, [estimatedTime])
					: '';
		}
		return '';
	};

	return (
		<div className="tracking-screen app-background-color tracking-route">
			<TrackingHeader title={getTitle()} subtitle={getSubtitle()} currentStatus={currentTripStatus} />
			<TrackingAnimationContent
				driver={service.driver}
				mapLoaded={isLoaded}
				currentStatus={currentTripStatus}
				brand={brand}
				branch={service.branch}
				situation={service.situation}
				serviceType={service.serviceType as TRACKS}
			/>
			<div className="drawer-handle" />
			<DriverDetails />
			<div className="divider" />
			{isDriverActive(service?.driver) ? <DriverContact openNotesModal={openNotesModal} /> : null}
			<TrackingReceipt service={service} customerFee={payment.amount} brand={brand} />
			{showCallModal && (
				<Modal dismiss={dismissCallModal}>
					<CallModal dismiss={dismissCallModal} />
				</Modal>
			)}
			{showNotesModal && (
				<Modal containerClassName={'modal__container--notes'} dismiss={dismissNotesModal}>
					<DriverNoteModal dismiss={dismissNotesModal} />
				</Modal>
			)}
		</div>
	);
};

export default TrackingScreen;
