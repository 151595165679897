import React, { Fragment, FunctionComponent } from 'react';
import providerImg from '../../assets/imgs/connect-provider.png';
import homeImg from '../../assets/imgs/home_icon.svg';
import cancelX from '../../assets/imgs/Cancel_button_icon-01.png';
import i18n from '../../utils/i18n';

interface CancelModalProps {
	brand: string;
	dismiss: () => void;
	onCancelServiceClick: () => void;
}

const CancelServiceModal: FunctionComponent<CancelModalProps> = ({ brand, dismiss, onCancelServiceClick }) => {
	const imgToDisplay = brand === 'connect-home-repair-ondemand-pr' ? homeImg : providerImg;

	return (
		<Fragment>
			<div className="modal__container__header">
				<img onClick={dismiss} className="modal__container__header-cancel" src={cancelX} alt="Cancel" />
			</div>
			<h4 className="app-font-bold">{i18n.get('cancel_modal.title', '¿Desea cancelar su servicio?')}</h4>
			<p className="app-font-light">{i18n.get('cancel_modal.sub_title', 'Sus detalles no serán guardados')}</p>
			<figure className="modal__container__figure">
				<img width="200" height="200" className="modal__container__image" src={imgToDisplay} alt="Provider" />
			</figure>
			<button
				onClick={onCancelServiceClick}
				className="btn-primary modal__container__button modal__container__button--cancel"
			>
				{i18n.get('cancel_modal.btn_cancel', 'Cancelar servicio')}
			</button>
			<button onClick={dismiss} className="modal__container__button">
				{i18n.get('cancel_modal.btn_continue', 'Continuar servicio')}
			</button>
		</Fragment>
	);
};

export default CancelServiceModal;
