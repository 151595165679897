import { Marker, Polyline, OverlayView } from '@react-google-maps/api';
import React, { FunctionComponent } from 'react';
import { Location, TripStamp } from '../../../../interfaces';
import { getImagesByBrand } from '../../../../utils/Utils';
import { Brand } from '../../../../utils/constants';
import { getThemeByAccount } from '../../../../styled/stylesByAccount';
import { useProgressCurve } from './use-progress-curve';
import { useProgressCurveAnimation } from './use-progress-curve-animation';
import { useFitMapToBounds } from '../utils';
import S from './styles';

const PROVIDER_PIN_SIZE = 35;
const PROVIDER_PIN_ANCHOR = PROVIDER_PIN_SIZE / 2;

const SITUATION_PIN_SIZE = 75;
const SITUATION_PIN_ANCHOR_X = 40;
const SITUATION_PIN_ANCHOR_Y = 69;

interface OnRouteOffAppProps {
	map: google.maps.Map | null;
	brand: Brand;
	situationLocation: Location;
	providerLocation: Location;
	simulatedEta: number;
	tripStamps: TripStamp[];
}

const OnRouteOffApp: FunctionComponent<OnRouteOffAppProps> = ({
	map,
	brand,
	situationLocation,
	providerLocation,
	simulatedEta,
	tripStamps,
}): JSX.Element => {
	const { progressCurvePoints } = useProgressCurve(providerLocation, situationLocation);
	const { completedProgressCurvePoints } = useProgressCurveAnimation(progressCurvePoints, tripStamps, simulatedEta);
	useFitMapToBounds(map, providerLocation, situationLocation);

	const imagesByBrand = getImagesByBrand(brand);
	const theme = getThemeByAccount(brand);
	return (
		<>
			{providerLocation?.lat && (
				<Marker
					zIndex={4}
					position={providerLocation}
					icon={{
						anchor: new window.google.maps.Point(PROVIDER_PIN_ANCHOR, PROVIDER_PIN_ANCHOR),
						url: imagesByBrand.offAppProviderIcon,
						scaledSize: new window.google.maps.Size(PROVIDER_PIN_SIZE, PROVIDER_PIN_SIZE),
					}}
				/>
			)}
			{situationLocation?.lat && (
				<>
					<OverlayView mapPaneName={OverlayView.MAP_PANE} position={situationLocation}>
						<S.PulseIndicatorContainer>
							<S.Circle1 />
							<S.Circle2 />
							<S.Circle3 />
						</S.PulseIndicatorContainer>
					</OverlayView>
					<Marker
						zIndex={10}
						position={situationLocation}
						icon={{
							anchor: new window.google.maps.Point(SITUATION_PIN_ANCHOR_X, SITUATION_PIN_ANCHOR_Y),
							url: imagesByBrand.permissionPin,
							scaledSize: new window.google.maps.Size(SITUATION_PIN_SIZE, SITUATION_PIN_SIZE),
						}}
					/>
				</>
			)}
			{Boolean(providerLocation?.lat && situationLocation?.lat) && (
				<>
					<Polyline
						path={progressCurvePoints}
						options={{ geodesic: true, strokeColor: '#001D3D', strokeWeight: 2, strokeOpacity: 1, zIndex: 4 }}
					></Polyline>
					<Polyline
						path={completedProgressCurvePoints}
						options={{ geodesic: true, strokeColor: theme.primaryColor, strokeWeight: 6, strokeOpacity: 1, zIndex: 5 }}
					></Polyline>
				</>
			)}
		</>
	);
};

export default OnRouteOffApp;
