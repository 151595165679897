import Axios, { AxiosResponse, AxiosStatic } from 'axios';
import HttpClient from './HttpClient';
import { GenerateDownloadUrlResponse } from '../interfaces/s3-manager-service';

const S3_MANAGER_SERVICE_URL = process.env.REACT_APP_S3_MANAGER_SERVICE_URL || '';
const S3_MANAGER_SERVICE_API_KEY = process.env.REACT_APP_S3_MANAGER_SERVICE_API_KEY || '';

export const AXIOS_CONFIG = {
	headers: {
		'Content-Type': 'application/json',
		'x-api-key': S3_MANAGER_SERVICE_API_KEY,
	},
};

export default class S3ManagerService extends HttpClient {
	constructor(axios: AxiosStatic = Axios) {
		super(axios, S3_MANAGER_SERVICE_URL);
	}

	async generateDownloadUrl(url: string): Promise<AxiosResponse<GenerateDownloadUrlResponse>> {
		return await this.post(`/generate-download-url`, { url }, AXIOS_CONFIG);
	}
}
