import {
	MEMBER_ACCOUNT_ID,
	MEMBER_ACCOUNT_PTY_ID,
	ON_DEMAND_ACCOUNT_ID,
	ON_DEMAND_ACCOUNT_PTY_ID,
} from '../../utils/constants';

export const getOnDemandAccount = (branch: string): { _id: string; brand: string } => {
	switch (branch) {
		case 'Panama':
			return { _id: ON_DEMAND_ACCOUNT_PTY_ID, brand: 'connect' };
		case 'Puerto Rico':
		default:
			return { _id: ON_DEMAND_ACCOUNT_ID, brand: 'connect' };
	}
};

export const getMemberAccount = (branch: string): { _id: string; brand: string } => {
	switch (branch) {
		case 'pty':
			return { _id: MEMBER_ACCOUNT_PTY_ID, brand: 'connect-assistance' };
		case 'pr':
		default:
			return { _id: MEMBER_ACCOUNT_ID, brand: 'connect-assistance' };
	}
};
