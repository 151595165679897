import React from 'react';
import S from './styles';

interface SurveyScoreInputProps {
	value: number;
	onChange: (value: number | number[]) => void;
	scoreRangeMin?: number;
	scoreRangeMax?: number;
}

const SliderInput = ({
	value,
	onChange,
	scoreRangeMin = 0,
	scoreRangeMax = 10,
}: SurveyScoreInputProps): JSX.Element => {
	return (
		<S.SliderInput
			value={value || 0}
			onChange={(_event, value) => onChange(value)}
			valueLabelDisplay="on"
			step={1}
			marks
			min={scoreRangeMin}
			max={scoreRangeMax}
		/>
	);
};

export default SliderInput;
