import {
	ActionType,
	AddressType,
	Campaign,
	DispatchFunction,
	ReferenceAddressForm,
	ServiceType,
	StripePayment,
	TRACKS,
} from '../interfaces/';
import i18N from '../utils/i18n';

const NON_DIGITS = /[^\d]/g;

export const setBrand = (brand: string | null = 'connect', dispatch: DispatchFunction): void => {
	i18N.init(brand?.toString());
	dispatch({ type: ActionType.SET_BRAND, data: brand || 'connect' });
};

export const setPhoneNumber = (phoneNumber: string, dispatch: DispatchFunction): void => {
	dispatch({ type: ActionType.SET_PHONE_NUMBER, data: phoneNumber });
};

export const setMMMHOD = (isMMMHOD: boolean, dispatch: DispatchFunction): void => {
	dispatch({ type: ActionType.SET_MMM_HOD, data: isMMMHOD });
};

export const setCampaign = (campaign: Campaign | null, dispatch: DispatchFunction): void => {
	dispatch({ type: ActionType.SET_CAMPAIGN, data: campaign });
};

export const setuserExternalParams = (
	identifier: string | null,
	fullName: string | null,
	isFromWebview: boolean | null,
	policyNumber: string | null,
	dispatch: DispatchFunction
): void => {
	dispatch({ type: ActionType.SET_USER_EXTERNAL_PARAMS, data: { identifier, fullName, isFromWebview, policyNumber } });
};

export const setAccount = (data: any, dispatch: DispatchFunction): void => {
	dispatch({ type: ActionType.SET_ACCOUNT, data });
};

export const setCurrentTrack = (data: TRACKS | null, dispatch: DispatchFunction): void => {
	dispatch({ type: ActionType.SET_CURRENT_TRACK, data: data || TRACKS.ROAD });
};

export const setActionType = (actionType: string, dispatch: DispatchFunction): void => {
	dispatch({ type: ActionType.SET_ACTION_TYPE, data: actionType });
};

export const clearAll = (dispatch: DispatchFunction): void => {
	dispatch({ type: ActionType.CLEAR_ALL });
};

export const setSecondStopClientInfo = (data: any, dispatch: DispatchFunction): void => {
	dispatch({
		type: ActionType.SECOND_STOP_FILL_FORM,
		data: {
			fullName: `${data.firstname} ${data.lastname}`,
			phone: data.phone1.replace(NON_DIGITS, ''),
			plate: data.plate,
			year: data.year,
			make: data.make,
			model: data.model,
			color: data.color,
		},
	});
};

export const setProviderCoords = (situation: any, dispatch: DispatchFunction): void => {
	dispatch({
		type: ActionType.SET_COORDS,
		data: {
			lat: situation?.lat,
			lng: situation?.lng,
			placeId: situation?.placeId,
			addressType: AddressType.PROVIDER,
		},
	});
};

export const setSituationCoords = (situation: any, dispatch: DispatchFunction): void => {
	dispatch({
		type: ActionType.SET_COORDS,
		data: {
			lat: situation?.lat,
			lng: situation?.lng,
			placeId: situation?.placeId,
			addressType: AddressType.SITUATION,
		},
	});
};

export const setDestinationCoords = (destination: any, dispatch: DispatchFunction): void => {
	dispatch({
		type: ActionType.SET_COORDS,
		data: {
			lat: destination?.lat,
			lng: destination?.lng,
			placeId: destination?.placeId,
			addressType: AddressType.DESTINATION,
		},
	});
};

export const setServiceData = (service: any, dispatch: DispatchFunction, queryParam: string | null): void => {
	dispatch({
		type: ActionType.SET_SERVICE,
		data: queryParam === 'survey' ? { ...service, endDate: new Date().toISOString() } : service,
	});
};

export const selectService = (data: string | null, dispatch: DispatchFunction): void => {
	dispatch({ type: ActionType.SET_SITUATION, data });
};

export const selectCategory = (data: string | undefined, dispatch: DispatchFunction): void => {
	dispatch({ type: ActionType.SET_CATEGORY, data });
};

export const saveNote = (dispatch: DispatchFunction, data: string): void => {
	dispatch({ type: ActionType.SET_NOTES, data });
};

export const saveHomeAvailability = (dispatch: DispatchFunction, data: string): void => {
	dispatch({ type: ActionType.SAVE_HOME_AVAILABILITY, data });
};

export const saveHomeDate = (dispatch: DispatchFunction, data: string | Date): void => {
	dispatch({ type: ActionType.SAVE_HOME_DATE, data });
};

export const setPassengers = (dispatch: DispatchFunction, data: number): void => {
	if (data >= 0) dispatch({ type: ActionType.SET_PASSENGERS, data });
};

export const saveAnswers = (dispatch: DispatchFunction, data: any): void => {
	const selectedIdx: number[] = [];
	data.forEach((question: any, idx: number) => {
		if (question.isSelected) selectedIdx.push(idx);
	});
	dispatch({ type: ActionType.SAVE_ANSWERS, data: selectedIdx });
};

export const setCustomerFee = (dispatch: DispatchFunction, data: any): void => {
	dispatch({ type: ActionType.SET_CUSTOMER_FEE, data });
};

export const setLocationPermission = (dispatch: DispatchFunction, data: any): void => {
	dispatch({ type: ActionType.SET_LOCATION_PERMISSION, data });
};

export const savePayment = (data: StripePayment, dispatch: DispatchFunction): void => {
	dispatch({ type: ActionType.SAVE_PAYMENT, data });
};

export const setClientInfo = (data: any, dispatch: DispatchFunction): void => {
	dispatch({ type: ActionType.SET_CLIENT_INFO, data });
};

export const setCustomerContract = (data: any, dispatch: DispatchFunction): void => {
	dispatch({ type: ActionType.SET_CUSTOMER_CONTRACT, data });
};

export const setPushOptIn = (dispatch: DispatchFunction, data: any): void => {
	dispatch({ type: ActionType.SET_PUSH_OPT_IN, data });
};

export const setServiceTypes = (dispatch: DispatchFunction, data: ServiceType[]): void => {
	dispatch({ type: ActionType.SET_SERVICE_TYPES, data });
};

export const setCheckLimitErrors = (dispatch: DispatchFunction, data: string[]): void => {
	dispatch({ type: ActionType.SET_CHECK_LIMIT_ERRORS, data });
};

export const setReferenceAddressForm = (data: ReferenceAddressForm, dispatch: DispatchFunction): void => {
	dispatch({ type: ActionType.SET_REFERENCE_ADDRESS_FORM, data });
};

export const cleanSituation = (dispatch: DispatchFunction): void => {
	dispatch({
		type: ActionType.SET_ADDRESS,
		data: { addressType: AddressType.SITUATION, prettyAddress: '', placeId: '', address: '' },
	});
};

export const cleanReferenceAddress = (dispatch: DispatchFunction): void => {
	dispatch({
		type: ActionType.SET_REFERENCE_ADDRESS_FORM,
		data: { fullAddress: '', address1: '', address2: '', city: '', country: '', zipCode: '' },
	});
};

export const setToken = (token: string | null = '', dispatch: DispatchFunction): void => {
	dispatch({ type: ActionType.SET_TOKEN, data: token });
};

export const setIntegrations = (data: any, dispatch: DispatchFunction): void => {
	dispatch({ type: ActionType.SET_INTEGRATIONS, data });
};
