import React, { FunctionComponent, useCallback, useContext, useEffect, useState, lazy, Suspense } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import cn from 'classnames';
import { ThemeProvider as MUIThemePRovider, Theme } from '@material-ui/core/styles';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Context } from '../context/Context';
import { AppContext, ActionType, AddressType } from '../interfaces';
import ServicesScreen from './services/Services';
import TowServiceScreen from './towService/TowService';
import TrackingPermission from './tracking/Permission';
import LocationPointOnMap from './location/PointOnMap';
import LandingPage from './LandingPage/LandingPage';
import HomeService from './HomeService/HomeService';
import { MMMLanding, MMMCallback } from './LandingPageMMM/MMMLanding';
import useWatchPosition from '../hooks/useWatchPosition';
import { STRIPE_PR_KEY } from '../utils/constants';
import { CorporateGuard, DispatchGuard, SurveyGuard } from '../utils/Guard';
import CorporateHome from './corporate/home/home';
import Options from './options/Options';
import GeoTextPermissionScreen from './geotext/GeoTextPermissionScreen';
import GeoTextMapScreen from './geotext/GeoTextMapScreen';
import GeoTextConfirmationComponent from './geotext/GeoTextConfirmationScreen';
import GlobalStyle from '../styled';
import { getThemeByAccount, buildMaterialUITheme } from '../styled/stylesByAccount';
import { updateHeadTags } from '../utils/Utils';
import LandingPageMMM from './LandingPageMMM/LandingPageMMM';
import Loader from '../components/common/Loader';
import TrackingLoadingScreen from './tracking/Loading';
import CancelledScreen from './cancelled/Cancelled';
import TrackingScreen from './tracking/Tracking';
import LocationScreen from './location/Location';
import SurveyScreen from './survey/Survey';
import SocialScreen from './social/Social';

interface ScreensRootProps {
	show: boolean;
}
const PaymentScreen = lazy(() => import('./payment/Payment'));
const ClientInfo = lazy(() => import('./clientInfo/ClientInfo'));
const CorporateServices = lazy(() => import('./corporate/services/services'));
const Tips = lazy(() => import('./tips/Tips'));
const CorporateCenters = lazy(() => import('./corporate/serviceCenters/serviceCenters'));
const CommentsAndPictures = lazy(() => import('./CommentsAndPictures/CommentsAndPictures'));
const GeoTextErrorScreen = lazy(() => import('./geotext/GeoTextErrorScreen'));
const Plate = lazy(() => import('./plate/Plate'));
const SurveyFeedback = lazy(() => import('./surveyFeedback/SurveyFeedback'));
const HelpScreen = lazy(() => import('./help/Help'));
const ReferenceAddress = lazy(() => import('./location/ReferenceAddress'));
const ServiceDetailsScreen = lazy(() => import('./serviceDetails/ServiceDetails'));
const CoinspectionScreen = lazy(() => import('./conispection/CoinspectionScreen'));
const OtherOptions = lazy(() => import('./otherOptions/OtherOptions'));
const ServiceSummary = lazy(() => import('./ServiceSummary/ServiceSummary'));
const TowQuestions = lazy(() => import('./towQuestions/TowQuestions'));
const SplashScreen = lazy(() => import('./splash/Splash'));

const dispatchedGuard = (component: JSX.Element) => (): JSX.Element => <DispatchGuard component={component} />;

const surveyGuard = (component: JSX.Element) => (): JSX.Element => <SurveyGuard component={component} />;

const corporaterGuard = (component: JSX.Element) => (): JSX.Element => <CorporateGuard component={component} />;

const ScreensRoot: FunctionComponent<ScreensRootProps> = ({ show }): JSX.Element => {
	const { dispatch, brand, service, account } = useContext(Context) as AppContext;
	const [currentTheme, setCurrentTheme] = useState(getThemeByAccount(brand));
	const [materialUITheme, setMaterialUITheme] = useState<Theme>(buildMaterialUITheme(currentTheme));
	const { setWatchPositionConfig } = useWatchPosition();

	const history = useHistory();

	const handleClearApp = useCallback(() => {
		const redirectTo = service?.flags?.fromConnectApp ? `/?account=${account._id}&brand=${brand}` : '/';
		dispatch({ type: ActionType.CLEAR_ALL });
		history.replace(redirectTo);
	}, [account, brand, dispatch, history, service]);

	useEffect(() => {
		const theme = getThemeByAccount(brand);
		const materialUITheme = buildMaterialUITheme(theme);
		setCurrentTheme(theme);
		setMaterialUITheme(materialUITheme);
		updateHeadTags(brand);
	}, [brand]);

	useEffect(() => {
		// Si no tienes nada, o tienes un servicio corrupto, borra el cache
		if (!service || !service.flags || !account) {
			handleClearApp();
			return;
		}
	}, [service, account, handleClearApp]);

	const handleResetClick = (): void => {
		handleClearApp();
	};

	const stripe = loadStripe(STRIPE_PR_KEY);
	const classname = cn(brand, {
		'app-no-display': !show,
		app: show,
	});

	return (
		<StyledThemeProvider theme={currentTheme}>
			<MUIThemePRovider theme={materialUITheme}>
				<div className={`app-background-color ${classname}`}>
					<GlobalStyle />
					<Suspense fallback={<Loader />}>
						<Switch>
							<Route path="/corporate/home" render={corporaterGuard(<CorporateHome />)} />
							<Route path="/corporate/centers" component={CorporateCenters} />
							<Route path="/corporate/services" component={CorporateServices} />
							<Route path="/tips" render={surveyGuard(<Tips />)} />
							<Route path="/plate" render={dispatchedGuard(<Plate />)} />
							<Route path="/other-options/:option" component={OtherOptions} />
							<Route path="/options" component={Options} />
							<Route path="/get-service/:id" component={LandingPage} />
							<Route path="/mmm-openid/login-legacy" component={LandingPageMMM} />
							<Route path="/mmm-openid/login" component={MMMLanding} />
							<Route path="/mmm-openid/callback" component={MMMCallback} />
							<Route path="/comments" render={dispatchedGuard(<CommentsAndPictures />)} />
							<Route path="/clients" component={ClientInfo} />
							<Route path="/services/tow/questions" render={dispatchedGuard(<TowQuestions />)} />
							<Route path="/services/tow" render={dispatchedGuard(<TowServiceScreen />)} />
							<Route path="/services/home" render={dispatchedGuard(<HomeService />)} />
							<Route path="/services" render={dispatchedGuard(<ServicesScreen />)} />
							<Route
								path="/location/situation"
								render={dispatchedGuard(<LocationPointOnMap addressType={AddressType.SITUATION} />)}
							/>
							<Route
								path="/location/destination"
								render={dispatchedGuard(<LocationPointOnMap addressType={AddressType.DESTINATION} />)}
							/>
							<Route path="/location/reference" render={dispatchedGuard(<ReferenceAddress />)} />
							<Route path="/location/summary" render={dispatchedGuard(<LocationScreen />)} />
							<Route path="/location" render={dispatchedGuard(<LocationScreen />)} />
							<Route
								path="/payment"
								render={(): JSX.Element => (
									<Elements stripe={stripe}>
										<PaymentScreen />
									</Elements>
								)}
							/>
							<Route
								path="/cancelled"
								render={(): JSX.Element => <CancelledScreen handleResetClick={handleResetClick} />}
							/>
							<Route
								path="/finished"
								render={(): JSX.Element => <CancelledScreen handleResetClick={handleResetClick} />}
							/>
							<Route path="/service-details" component={ServiceDetailsScreen} />
							<Route path="/service-summary" component={ServiceSummary} />
							<Route path="/tracking/loading" component={TrackingLoadingScreen} />
							<Route
								path="/tracking/permission"
								render={(): JSX.Element => <TrackingPermission setWatchPositionConfig={setWatchPositionConfig} />}
							/>
							<Route path="/tracking/coninspection" component={CoinspectionScreen} />
							<Route path="/tracking" component={TrackingScreen} />
							<Route path="/survey" render={surveyGuard(<SurveyScreen handleResetClick={handleResetClick} />)} />
							<Route
								path="/feedback"
								render={(): JSX.Element => <SurveyFeedback handleResetClick={handleResetClick} />}
							/>
							<Route path="/social" render={(): JSX.Element => <SocialScreen handleResetClick={handleResetClick} />} />
							<Route path="/help" component={HelpScreen} />

							<Route path="/geotext/landing" component={LandingPage} />
							<Route path="/geotext/permission/:roomId" component={GeoTextPermissionScreen} />
							<Route path="/geotext/map/:roomId" component={GeoTextMapScreen} />
							<Route path="/geotext/error/location" component={GeoTextErrorScreen} />
							<Route path="/geotext/error/roomid" component={GeoTextErrorScreen} />
							<Route path="/geotext/confirmation" component={GeoTextConfirmationComponent} />

							<Route path="/" component={SplashScreen} />
						</Switch>
					</Suspense>
				</div>
			</MUIThemePRovider>
		</StyledThemeProvider>
	);
};

export default ScreensRoot;
