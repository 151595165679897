import React, { useEffect, useState } from 'react';
import TrackingMap from './Map';
import EvaluationAnimation from './animations/EvaluationAnimation';
import TipAnimation from './animations/TipAnimation';
import { Branches, Driver, TRACKS, TripStatus } from '../../interfaces';
import './TrackingAnimationContent.scss';
import ConinspectionInfo from './animations/coinspectionInfo';
import { isInspectionCRPA, renderTipView } from '../../utils/Utils';

interface TrackingAnimationContent {
	mapLoaded: boolean;
	currentStatus?: TripStatus;
	brand: string;
	branch?: string;
	situation?: string;
	serviceType?: TRACKS;
	driver: Driver;
}

const ANIMATIONS_TIME = 8700;
const INDEX_TRACKING = 0;
const INDEX_EVALUATION = 1;
const INDEX_TIP = 2;

const TrackingAnimationContent = ({
	mapLoaded,
	currentStatus,
	brand,
	branch,
	situation,
	serviceType,
	driver,
}: TrackingAnimationContent): JSX.Element | null => {
	const [currentAnimation, setCurrentAnimation] = useState<number>(0);
	const isInspectionCRIPA = isInspectionCRPA(situation, branch as Branches);

	useEffect(() => {
		const setCurrentRender = async (): Promise<void> => {
			const serviceHasTip = renderTipView(brand, branch as Branches, serviceType as TRACKS);
			if ((!serviceHasTip && currentAnimation === 1) || currentAnimation === 2) {
				setCurrentAnimation(0);
			} else {
				setCurrentAnimation(currentAnimation + 1);
			}
		};

		const timer = setInterval(() => setCurrentRender(), ANIMATIONS_TIME);

		if (currentStatus !== TripStatus.ACCEPTED) {
			clearInterval(timer);
		}

		return (): void => timer && clearInterval(timer);
	}, [currentStatus, currentAnimation, brand, branch, serviceType]);

	const getVisibility = (number: number): string => {
		// if the currentStatus is accepted it only shows the map
		if (currentStatus !== TripStatus.ACCEPTED) {
			return number === 0 ? 'visible' : 'hidden';
		}

		if (currentAnimation === number) {
			return 'visible';
		}
		return 'hidden';
	};

	return (
		<div className="tracking-animation-content">
			<div className={`animation ${getVisibility(INDEX_TRACKING)} `} data-testid="tracking-map-animation">
				<TrackingMap mapLoaded={mapLoaded} currentStatus={currentStatus} />
				{isInspectionCRIPA && <ConinspectionInfo />}
			</div>
			<div className={`animation ${getVisibility(INDEX_EVALUATION)} `} data-testid="evaluation-animation">
				{currentAnimation === INDEX_EVALUATION ? <EvaluationAnimation /> : null}
			</div>
			<div className={`animation ${getVisibility(INDEX_TIP)} `} data-testid="tip-animation">
				{currentAnimation === INDEX_TIP ? <TipAnimation /> : null}
			</div>
		</div>
	);
};

export default TrackingAnimationContent;
