import React, { FunctionComponent } from 'react';
import './Header.scss';

interface HeaderProps {
	title: string;
	subTitle?: string | JSX.Element | undefined;
	showLine?: boolean;
}

const Header: FunctionComponent<HeaderProps> = ({ title, subTitle, showLine = true }): JSX.Element => {
	return (
		<div className="header-component">
			<div className="header-component__header">
				<h2>{title}</h2>
				{subTitle && typeof subTitle === 'string' ? (
					<pre className="app-font font-size-subtitle">{subTitle}</pre>
				) : subTitle ? (
					subTitle
				) : null}
			</div>
			{showLine && <div className="primary-light-line header-component__line" />}
		</div>
	);
};

export default Header;
