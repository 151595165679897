import * as React from 'react';
import CommonStyles from './styles';

export default function CircularIndeterminate() {
	return (
		<CommonStyles.BoxLoader className="box-loader">
			<CommonStyles.CircularLoader className="loader" />
		</CommonStyles.BoxLoader>
	);
}
