import React, { useContext } from 'react';
import Breadcrumbs from '../../components/common/Breadcrumbs';
import { AppContext } from '../../interfaces';
import { Context } from '../../context/Context';

import { getImagesByBrand } from '../../utils/Utils';
import { setPassengers } from '../../context/actions';
import i18n from '../../utils/i18n';
import { useHistory } from 'react-router';
import { Footer } from '../../components/common/footer';
import Header from '../../components/common/Header';

import './TowService.scss';

function TowServiceScreen(): JSX.Element {
	const { passengers, dispatch, brand } = useContext(Context) as AppContext;
	const history = useHistory();

	const handleConfirmClick = async (): Promise<void> => {
		history.push('/services/tow/questions');
	};

	return (
		<div className="service-tow">
			<Breadcrumbs />
			<Header title={i18n.get('screens.tow.service.title', '¿Cuántos pasajeros viajan contigo?')}></Header>
			<div className="service-tow__container body-container">
				<div className="service-tow__container__counter app-font-semi-bold bg-white title-color">
					<span>{i18n.get('screens.tow.service.lbl_passengers', 'Pasajeros')}</span>
					<img
						width="25"
						height="25"
						onClick={(): void => setPassengers(dispatch, passengers - 1)}
						src={getImagesByBrand(brand).minusSign}
						alt="Minus Sign"
					/>
					<span className="counter title-color">{passengers}</span>
					<img
						width="25"
						height="25"
						onClick={(): void => setPassengers(dispatch, passengers + 1)}
						src={getImagesByBrand(brand).plusSign}
						alt="Plus Sign"
					/>
				</div>
			</div>
			<Footer.Root>
				<Footer.Button onClick={handleConfirmClick} variant="primary">
					{i18n.get('screens.tow.service.btn_continue', 'Continuar')}
				</Footer.Button>
			</Footer.Root>
		</div>
	);
}

export default TowServiceScreen;
