import React, { useEffect, useRef, FunctionComponent } from 'react';
import cn from 'classnames';
import './Modal.scss';

interface ModalProps {
	dismiss: () => void;
	children: React.ReactNode;
	windowClassName?: string;
	containerClassName?: string;
}

const Modal: FunctionComponent<ModalProps> = (props: ModalProps) => {
	const windowRef: any = useRef(null);
	const classNameWindow = cn('modal__window', props.windowClassName);
	const classNameContainer = cn('modal__container', props.containerClassName);

	useEffect(() => {
		// Verifies of click is outside modal and closes it
		const handleOutsideClick = (ev: MouseEvent): void => {
			if (!windowRef.current.contains(ev.target)) props.dismiss();
		};

		document.addEventListener('mousedown', handleOutsideClick);
		return (): void => document.removeEventListener('mousedown', handleOutsideClick);
	}, [props]);

	return (
		<div className="modal">
			<div className={classNameWindow} ref={windowRef}>
				<div className={classNameContainer}>{props.children}</div>
			</div>
		</div>
	);
};

export default Modal;
