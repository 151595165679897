import Axios, { AxiosResponse, AxiosStatic } from 'axios';
import { COMMON_API } from '../utils/constants';
import HttpClient from './HttpClient';

export default class BaseClient extends HttpClient {
	constructor(axios: AxiosStatic = Axios) {
		super(axios, COMMON_API);
	}

	async getUserInfo(data: any): Promise<AxiosResponse> {
		return await this.post('/api/mmm-openid/token', data);
	}
}
