import React, { FunctionComponent, useEffect, useState } from 'react';
import { Driver, Location } from '../../../../interfaces';
import { Brand } from '../../../../utils/constants';
import { strokeColorBrand, useFitMapToBounds } from '../utils';
import MapCurveMarker from './MapCurveMarker';

interface TripAcceptedProps {
	map: google.maps.Map | null;
	brand: Brand;
	situation?: Location;
	driver?: Driver;
}

const TripAccepted: FunctionComponent<TripAcceptedProps> = ({ map, brand, situation, driver }): JSX.Element | null => {
	useFitMapToBounds(map, driver, situation);
	const [mapProjection, setMapProjection] = useState<google.maps.Projection | null>(null);
	const [mapZoom, setMapZoom] = useState<number>(12);

	const projectionChanged = (mapParam: google.maps.Map): void => {
		const currentMapProjection = mapParam.getProjection();
		if (currentMapProjection) {
			setMapProjection(currentMapProjection);
		}
	};

	const onZoomChanged = (mapParam: google.maps.Map): void => {
		const zoom = mapParam.getZoom();
		if (zoom) {
			setMapZoom(zoom);
		}
	};

	useEffect(() => {
		let mapEventListener: google.maps.MapsEventListener | null = null;

		if (map) {
			projectionChanged(map);
			onZoomChanged(map);
			mapEventListener = window.google.maps.event.addListener(map, 'bounds_changed', () => {
				projectionChanged(map);
				onZoomChanged(map);
			});
		}

		return () => {
			if (mapEventListener) {
				window.google.maps.event.removeListener(mapEventListener);
			}
		};
	}, [map]);

	if (!situation?.lat || !situation?.lng || !driver?.lat || !driver?.lng) {
		return null;
	}

	return (
		<MapCurveMarker
			position1={new window.google.maps.LatLng(driver.lat, driver.lng)}
			position2={new window.google.maps.LatLng(situation.lat, situation.lng)}
			mapProjection={mapProjection}
			zoom={mapZoom}
			brand={brand}
			strokeColorBrand={strokeColorBrand}
		/>
	);
};

export default TripAccepted;
