import { useState, useEffect } from 'react';

const useNetwork = () => {
	const [isOnline, setNetwork] = useState(window.navigator.onLine);

	const updateNetwork = (): void => {
		setNetwork(window.navigator.onLine);
	};

	useEffect(() => {
		window.addEventListener('offline', updateNetwork);

		return (): void => {
			window.removeEventListener('offline', updateNetwork);
		};
	});

	return isOnline;
};

export default useNetwork;
