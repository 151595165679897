import React, { useEffect, useState } from 'react';
import { Branches, Service } from '../../interfaces';
import ReceivedServiceAnimation from '../../components/tracking/animations/ReceivedServiceAnimation';
import CoinspectionAnimation from './animations/coinspectionAnimation';
import './LoadingAnimationContent.scss';
import ConinspectionInfo from './animations/coinspectionInfo';
import { isDriverActive, isInspectionCRPA } from '../../utils/Utils';

const ANIMATIONS_TIME = 9000;
const INDEX_CONINSPECTION = 0;
const INDEX_RECEIVED_SERVICE = 1;

interface LoadingAnimationContent {
	service: Service;
	brand: string;
}

const LoadingAnimationContent = ({ service, brand }: LoadingAnimationContent): JSX.Element => {
	const isInspectionCRIPA = isInspectionCRPA(service.situation, service.branch as Branches);
	const initAnimation = isInspectionCRIPA ? 0 : 1;

	const [currentAnimation, setCurrentAnimation] = useState<number>(initAnimation);

	useEffect(() => {
		const setCurrentRender = async (): Promise<void> => {
			if (currentAnimation === 0) {
				setCurrentAnimation(1);
			} else if (currentAnimation === 1) {
				setCurrentAnimation(initAnimation);
			}
		};

		if (!isInspectionCRIPA) {
			const timer = setInterval(() => setCurrentRender(), ANIMATIONS_TIME);
			return (): void => clearInterval(timer);
		}
	}, [currentAnimation, initAnimation, isInspectionCRIPA]);

	const getVisibility = (number: number): string => {
		if (currentAnimation === number) {
			return 'visible';
		}
		return 'hidden';
	};

	return (
		<div className="loading-animation-content">
			<div className={`animation ${getVisibility(0)} `} data-testid="coninspection-service-animation">
				{currentAnimation === INDEX_CONINSPECTION ? <CoinspectionAnimation /> : null}
			</div>
			<div className={`animation ${getVisibility(1)} `} data-testid="received-service-animation">
				{currentAnimation === INDEX_RECEIVED_SERVICE ? (
					<ReceivedServiceAnimation brand={brand} hideProviderText={!isDriverActive(service.driver)} />
				) : null}
			</div>
			{isInspectionCRIPA && <ConinspectionInfo className="bottom-doc" />}
		</div>
	);
};

export default LoadingAnimationContent;
