import Axios, { AxiosResponse, AxiosStatic, AxiosRequestConfig } from 'axios';
import { HELIOS_REGULAR_KEY } from '../utils/constants';

export const AXIOS_CONFIG = {
	headers: {
		Authorization: HELIOS_REGULAR_KEY,
	},
};

export default class HttpClient {
	constructor(private axios: AxiosStatic = Axios, private baseUrl: string) {}

	protected async get<T>(url: string, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
		return this.axios.get(`${this.baseUrl}${url}`, { headers: { ...AXIOS_CONFIG.headers, ...options?.headers } });
	}
	protected async post<T>(url: string, data: any, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
		return this.axios.post(`${this.baseUrl}${url}`, data, {
			headers: { ...AXIOS_CONFIG.headers, ...options?.headers },
		});
	}
	protected async put<T>(url: string, data: any, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
		return this.axios.put(`${this.baseUrl}${url}`, data, { headers: { ...AXIOS_CONFIG.headers, ...options?.headers } });
	}
	protected async patch<T>(url: string, data: any, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
		return this.axios.patch(`${this.baseUrl}${url}`, data, {
			headers: { ...AXIOS_CONFIG.headers, ...options?.headers },
		});
	}
	protected async delete<T>(url: string, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
		return this.axios.delete(`${this.baseUrl}${url}`, { headers: { ...AXIOS_CONFIG.headers, ...options?.headers } });
	}
}
