import Geocode from 'react-geocode';

Geocode.setApiKey(process.env.REACT_APP_GMAPS_KEY || '');

// Tiny wrapper/file to provide single entry-point to the .setApiKey call above.
export const reverseGeocode = async (lat: string, lng: string): Promise<{ address: string; placeId: string }> => {
	return Geocode.fromLatLng(lat, lng).then(findBestAddress);
};

const findBestAddress = ({
	results,
}: {
	results: { formatted_address: string; place_id: string }[];
}): { address: string; placeId: string } => {
	return { address: results[0].formatted_address, placeId: results[0].place_id };
};
