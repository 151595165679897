import { useCallback, useState, useEffect, useRef } from 'react';
import { getDistance } from 'geolib';
import { Driver, Location } from '../../../../interfaces';
import { getImagesByBrand } from '../../../../utils/Utils';
import { Brand } from '../../../../utils/constants';

const updateMarkerRotation = (path: google.maps.LatLng[], brand: string): void => {
	if (path.length > 1) {
		const rotation = window.google?.maps?.geometry?.spherical.computeHeading(path[0], path[1]);
		document
			.querySelector(`[src*="${getImagesByBrand(brand).vehicleVial}"]`)
			?.setAttribute('style', `transform: rotate(${rotation - 90}deg); transition: transform 0.5s ease`);
	}
};

function useOnRouteTracking(
	map: google.maps.Map | null,
	pathway: google.maps.LatLng[],
	onPathwayUpdate: (pathway: google.maps.LatLng[]) => void,
	driver: Driver,
	situationLocation: Location,
	brand: Brand
) {
	const mapEventListener = useRef<google.maps.MapsEventListener | null>(null);
	const [previousCoords, setPreviousCoords] = useState<{ lat: number; lng: number }>({
		lat: driver.lat,
		lng: driver.lng,
	});
	const [autoZoomSet, setAutoZoomSet] = useState<boolean>(false);
	const [shouldRequestDirections, setShouldRequestDirections] = useState<boolean>(true);
	const etaInMinutes = driver?.eta >= 0 ? Math.round(driver.eta / 60) : null;

	// Gets directions pathway (turn by turn route)
	const onDirectionsResult = useCallback(
		async (result: google.maps.DirectionsResult | null): Promise<void> => {
			const newPathway = result?.routes[0]?.overview_path || [];
			setShouldRequestDirections(false);
			updateMarkerRotation(newPathway, brand);
			onPathwayUpdate(newPathway);
		},
		[setShouldRequestDirections, onPathwayUpdate, brand]
	);

	useEffect(() => {
		// Update Directions pathway as driver moves
		if (driver.lat === previousCoords.lat && driver.lng === previousCoords.lng) return;
		if (!previousCoords?.lat || !previousCoords?.lng || !driver?.lat || !driver?.lng) return;
		setPreviousCoords({ lat: driver.lat, lng: driver.lng });

		const deltas = [];
		for (const point of pathway) deltas.push(getDistance(driver, { lat: point.lat(), lng: point.lng() }));

		const min = Math.min(...deltas);
		const i = deltas.findIndex((d) => d === min);

		if (i === -1 || deltas[i] > 200) {
			setShouldRequestDirections(true);
		} else {
			if (i > 0) {
				const newPathway = pathway.slice(i, pathway.length);
				updateMarkerRotation(newPathway, brand);
				onPathwayUpdate(newPathway);
			}
		}
	}, [driver, pathway, previousCoords, brand, onPathwayUpdate]);

	useEffect(() => {
		// Update Map Zoom as driver moves
		if (map && pathway.length && !autoZoomSet && driver.lat && situationLocation.lat) {
			setPreviousCoords({ lat: driver.lat, lng: driver.lng });
			const bounds = new window.google.maps.LatLngBounds();
			bounds.extend(pathway?.[pathway?.length - 1] || situationLocation);
			bounds.extend(pathway?.[0] || driver);

			map.fitBounds(bounds);
			mapEventListener.current = window.google.maps.event.addListenerOnce(map, 'bounds_changed', function () {
				map.setCenter(pathway?.[pathway?.length - 1] || situationLocation);
				const newbounds = map?.getBounds();
				if (!newbounds?.contains(pathway?.[0] || driver)) {
					const zoom = map.getZoom();
					if (zoom) {
						map.setZoom(zoom - 1);
					}
				}
			});
			setAutoZoomSet(true);
		}
	}, [map, pathway, situationLocation, driver, previousCoords, brand, autoZoomSet]);

	useEffect(() => {
		// clear listeners on unmount
		return () => {
			if (mapEventListener.current) {
				window.google.maps.event.removeListener(mapEventListener.current);
			}
		};
	}, []);

	return {
		etaInMinutes,
		shouldRequestDirections,
		onDirectionsResult,
	};
}

export { useOnRouteTracking };
