import { useEffect, useContext } from 'react';
import { Context } from '../context/Context';
import { TIME_TO_REQUEST_DRIVER_LOCATION } from '../utils/constants';
import HeliosClient from '../api/Helios';
import { ActionType, AppContext } from '../interfaces';

const useDriverLocation = () => {
	const { service, dispatch } = useContext(Context) as AppContext;
	useEffect(() => {
		const fetchDriverLocation = async (): Promise<void> => {
			if (service.driver?.id) {
				const {
					data: { data },
				} = await new HeliosClient().getDriverLocation(service.driver.id);
				if (data) {
					dispatch({ type: ActionType.SET_SERVICE_DRIVER, data });
				}
			}
		};

		const timer = setInterval(fetchDriverLocation, TIME_TO_REQUEST_DRIVER_LOCATION);

		return (): void => clearInterval(timer);
	}, [service.driver, dispatch]);
};

export default useDriverLocation;
