import React, { FunctionComponent, useContext } from 'react';
import { useHistory } from 'react-router';

import asistenciaImg from '../../../assets/imgs/honda_asistencia_icon.svg';
import serviciosImg from '../../../assets/imgs/honda_servicio_icon.svg';
import centrosImg from '../../../assets/imgs/honda_centros_icon.svg';
import contactoImg from '../../../assets/imgs/honda_contacto_icon.svg';

import './home.scss';
import AppBanner from '../../../components/common/AppBanner';

import useIsMobile from '../../../hooks/useIsMobile';
import { getImagesByBrand } from '../../../utils/Utils';
import { Context } from '../../../context/Context';
import { AppContext } from '../../../interfaces';
import i18n from '../../../utils/i18n';

const CorporateHome: FunctionComponent = (): JSX.Element => {
	const { brand } = useContext(Context) as AppContext;
	const prompt = useIsMobile();
	const history = useHistory();

	const menuClick = (section: string) => {
		return history.push(section);
	};

	return (
		<div className="corporate-home">
			<div className="corporate-home__content">
				<img src={getImagesByBrand(brand).logo} alt="Logo" height="80" />
				<h2>{i18n.get('screens.corporate.home.title', 'Asistencia en Carretera')}</h2>
				<div className="corporate-home__content-menu">
					<div className="corporate-home__content-menu-items" onClick={() => menuClick('/services')}>
						<img src={asistenciaImg} alt="logo" width="60" />
						<span>{i18n.get('screens.corporate.home.btn_request_assistance', 'Solicitar Asistencia')}</span>
					</div>

					<div className="corporate-home__content-menu-items" onClick={() => menuClick('/corporate/services')}>
						<img src={serviciosImg} alt="logo" width="60" />
						<span>{i18n.get('screens.corporate.home.btn_services', 'Servicios')}</span>
					</div>

					<div className="corporate-home__content-menu-items" onClick={() => menuClick('/corporate/centers')}>
						<img src={centrosImg} alt="logo" width="60" />
						<span>{i18n.get('screens.corporate.home.btn_centers', 'Centros')}</span>
					</div>

					<div className="corporate-home__content-menu-items" onClick={() => menuClick('/help')}>
						<img src={contactoImg} alt="logo" width="60" />
						<span>{i18n.get('screens.corporate.home.btn_contact', 'Contacto')}</span>
					</div>
				</div>
			</div>
			{prompt && <AppBanner />}
		</div>
	);
};

export default CorporateHome;
