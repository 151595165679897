import React, { FunctionComponent, memo } from 'react';
import { DirectionsService, DirectionsRenderer } from '@react-google-maps/api';

interface DirectionsRequestProps {
	origin: string;
	destination: string;
	onResult: (result: google.maps.DirectionsResult | null) => void;
	omitRequest?: boolean;
}

const DirectionsRequest: FunctionComponent<DirectionsRequestProps> = ({
	origin,
	destination,
	onResult,
	omitRequest,
}): JSX.Element | null => {
	if (omitRequest) return null;
	return (
		<DirectionsService
			callback={onResult}
			options={{
				travelMode: 'DRIVING' as any,
				origin,
				destination,
			}}
		/>
	);
};

const DirectionsRendererWrap: FunctionComponent<{ directions: any }> = ({ directions }): JSX.Element => (
	<DirectionsRenderer
		directions={directions}
		options={{
			suppressMarkers: true,
			polylineOptions: { strokeColor: '#585858', strokeWeight: 5, strokeOpacity: 1 },
		}}
	/>
);

export const MemoizedDirections = memo(DirectionsRequest); // to prevent unnecesary re-renders and
export const MemoizedDirectionsRenderer = memo(DirectionsRendererWrap); // to prevent unnecesary re-renders and
