import React, { FunctionComponent, useContext } from 'react';

import phoneIcon from '../../../assets/imgs/phone_icon.png';
import './Contact.scss';
import { Context } from '../../../context/Context';
import { AppContext } from '../../../interfaces';
import i18n from '../../../utils/i18n';
import { getServiceTypeContactPhone } from '../../../utils/Utils';
import { MMM } from '../../../utils/constants';

interface DriverContactProps {
	openNotesModal: () => void;
}

const DriverContact: FunctionComponent<DriverContactProps> = ({ openNotesModal }): JSX.Element => {
	const { service, brand } = useContext(Context) as AppContext;

	const accountPhone = brand === MMM ? getServiceTypeContactPhone(MMM, service.serviceType) : service.accountPhone;

	return (
		<div className="driver-contact">
			<button className="call">
				<a href={`tel:${accountPhone}`}>
					<img className="driver-contact__img" src={phoneIcon} alt="phone" />
				</a>
			</button>
			<button className="notes" onClick={openNotesModal}>
				{i18n.get('tracking.driver.contact.placeholder', 'Envía nota al chofer...')}
			</button>
		</div>
	);
};

export default DriverContact;
