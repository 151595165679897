export function generateRandomString() {
	const array = new Uint32Array(28);
	window.crypto.getRandomValues(array);
	return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join('');
}

export function sha256(plain: any) {
	const encoder = new TextEncoder();
	const data = encoder.encode(plain);
	return window.crypto.subtle.digest('SHA-256', data);
}

export function base64urlencode(str: any) {
	return btoa(String.fromCharCode.apply(null, new Uint8Array(str) as any))
		.replace(/\+/g, '-')
		.replace(/\//g, '_')
		.replace(/=+$/, '');
}

export async function pkceChallengeFromVerifier(v: string) {
	const hashed = await sha256(v);
	return base64urlencode(hashed);
}

export function createQueryParams(obj: any) {
	return Object.keys(obj)
		.map((key) => key + '=' + obj[key])
		.join('&');
}
