import React, { FunctionComponent, useContext } from 'react';

import marker from '../../assets/imgs/marker.svg';
import facebook from '../../assets/imgs/Social_Icons-01.svg';
import instagram from '../../assets/imgs/Social_Icons-02.svg';
import cancelX from '../../assets/imgs/Cancel_button_icon-01.png';

import './Social.scss';
import { Context } from '../../context/Context';
import { AppContext } from '../../interfaces';
import { WHITELABEL_BRANDS } from '../../utils/constants';
import i18n from '../../utils/i18n';

interface SocialScreenProps {
	handleResetClick: () => void;
}

const SocialScreen: FunctionComponent<SocialScreenProps> = ({ handleResetClick }): JSX.Element => {
	const {
		brand,
		service: {
			flags: { fromConnectApp },
		},
	} = useContext(Context) as AppContext;
	return (
		<div className="social tracking-route">
			{fromConnectApp && !WHITELABEL_BRANDS.includes(brand) && (
				<img onClick={handleResetClick} className="social__cancel" src={cancelX} alt="cancel" />
			)}

			<h2 className="social__header">{i18n.get('screens.social.title', '¡Comparta su experiencia!')}</h2>
			<img className="social__marker" src={marker} alt="Connect marker" />
			<div className="social__icons">
				<a href="https://www.facebook.com/connectassistancepr">
					<img src={facebook} alt="facebook logo" />
				</a>
				<a href="https://www.instagram.com/connectassistancepr/">
					<img src={instagram} alt="instagram logo" />
				</a>
			</div>
		</div>
	);
};

export default SocialScreen;
