import React, { useContext } from 'react';
import { Situation, AppContext } from '../../../../interfaces';
import i18n from '../../../../utils/i18n';
import { Context } from '../../../../context/Context';
import { getContactPhone } from '../../../../utils/Utils';
import S from './styles';

interface Props {
	situation: Situation;
	open: boolean;
	onClose: () => void;
}

const CallDrawer = ({ situation, open, onClose }: Props): JSX.Element => {
	const { account, brand } = useContext(Context) as AppContext;

	const contactPhone = getContactPhone(brand, account);
	const phone = contactPhone?.serviceCoordination || '';

	let serviceTypeStr = '';
	const serviceTypeName = i18n.get(`screens.services.call_drawer.${situation}`, '');
	if (serviceTypeName) {
		serviceTypeStr = ` de ${serviceTypeName}`;
	}

	return (
		<S.Drawer open={open} onClose={onClose} anchor="bottom">
			<p>
				{i18n.get(
					'screens.services.call_drawer.title',
					'¿Deseas comunicarte con nosotros para coordinar un servicio?',
					[serviceTypeStr]
				)}
			</p>
			<S.CallButton className="btn-primary btn-footer">
				<S.CallLink href={`tel:${phone}`}>{i18n.get('utils.call', 'Llamar')}</S.CallLink>
			</S.CallButton>
			<button className="btn-secondary btn-footer" onClick={onClose}>
				{i18n.get('utils.cancel', 'Cancelar')}
			</button>
		</S.Drawer>
	);
};

export { CallDrawer };
