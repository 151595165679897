import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Context } from '../../../context/Context';
import { AddressType, AppContext, BranchIsoCode, Location, PermissionType, TRACKS } from '../../../interfaces';
import { updateLatLng } from '../Utils';
import i18n from '../../../utils/i18n';
import { reverseGeocode } from '../Geocoder';
import { GEOLOCATION_OPTIONS } from '../../../utils/constants';
import LocationPermissionModal from '../../common/LocationPermission';
import StaticOptions from './StaticOptions';
import DynamicOptions from './DynamicOptions';

import './styles.scss';

interface LocationSearchProps {
	addressType: AddressType;
	currentLocation?: Location;
}

export enum staticOptions {
	currentLocation = 'currentLocation',
	manualLocation = 'manualLocation',
	chooseOnMap = 'chooseOnMap',
}

export interface AutocompleteOption {
	placeId: string;
	description: string;
	mainText?: string;
	secondaryText?: string;
	matchesMainText?: { length: number; offset: number }[];
	staticOption?: staticOptions;
}

const STATIC_OPTIONS_LIST_HOME = [
	{ staticOption: staticOptions.currentLocation, description: '', placeId: '' },
	{ staticOption: staticOptions.manualLocation, description: '', placeId: '' },
	{ staticOption: staticOptions.chooseOnMap, description: '', placeId: '' },
];

const STATIC_OPTIONS_LIST_ROAD = [
	{ staticOption: staticOptions.currentLocation, description: '', placeId: '' },
	{ staticOption: staticOptions.chooseOnMap, description: '', placeId: '' },
];

const LocationSearch: FunctionComponent<LocationSearchProps> = ({ addressType, currentLocation }): JSX.Element => {
	const {
		dispatch,
		currentTrack,
		account,
		locations: { permission },
	} = useContext(Context) as AppContext;

	const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
		apiKey: process.env.REACT_APP_GMAPS_KEY,
	});

	const [value, setValue] = React.useState<AutocompleteOption>({ description: '', placeId: '' });
	const [place, setPlace] = React.useState<google.maps.places.PlaceResult | null>(null);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [permissionModalOpen, setPermissionModalOpen] = useState<boolean>(false);

	const history = useHistory();

	const updatePosition = async (position: Position): Promise<void> => {
		setPermissionModalOpen(false);
		const coordinates = {
			latLng: { lat: (): number => position.coords.latitude, lng: (): number => position.coords.longitude },
		};

		const location = await reverseGeocode(position.coords.latitude.toString(), position.coords.longitude.toString());
		const option = { placeId: location.placeId, description: location.address, mainText: location.address };
		setValue(option);
		await updateLatLng(coordinates, addressType, dispatch, null, reverseGeocode);
		setLoading(false);
	};

	useEffect(() => {
		if (!place) return;

		const location = place.geometry?.location;
		updateLatLng(
			{
				latLng: location,
				placeId: place.place_id,
			},
			addressType,
			dispatch,
			place.name,
			reverseGeocode
		);
	}, [place, addressType, dispatch]);

	useEffect(() => {
		const updateCurrentValue = async (lat: number, lng: number): Promise<void> => {
			const location = await reverseGeocode(lat.toString(), lng.toString());
			const option = { placeId: location.placeId, description: location.address, mainText: location.address };
			setValue(option);
		};
		if (currentLocation?.lat && currentLocation?.lng && currentLocation.address) {
			updateCurrentValue(currentLocation?.lat, currentLocation?.lng);
		}
	}, [currentLocation]);

	const setCurrentLocation = (): void => {
		if (permission === PermissionType.NOT_ASKED) {
			setPermissionModalOpen(true);
		} else {
			setLoading(true);
			navigator.geolocation.getCurrentPosition(
				updatePosition,
				() => {
					setLoading(false);
					console.log('error');
				},
				GEOLOCATION_OPTIONS
			);
		}
	};

	const handleStaticOptions = (staticOption: staticOptions): void => {
		switch (staticOption) {
			case staticOptions.currentLocation:
				setCurrentLocation();
				break;
			case staticOptions.manualLocation:
				history.push('/location/reference');
				break;
			case staticOptions.chooseOnMap:
				history.push(`/location/${addressType.toLowerCase()}`);
				break;
		}
	};

	const onChange = (newValue: AutocompleteOption): void => {
		if (newValue?.staticOption) {
			handleStaticOptions(newValue.staticOption);
		} else {
			setValue(newValue);
			if (newValue?.placeId) {
				placesService?.getDetails(
					{
						placeId: newValue.placeId,
					},
					(placeDetails) => setPlace(placeDetails)
				);
			}
		}
	};

	const getCustomOption = (place: any): AutocompleteOption => {
		return {
			placeId: place.place_id,
			description: place.description,
			mainText: place.structured_formatting.main_text,
			secondaryText: place.structured_formatting.secondary_text,
			matchesMainText: place.structured_formatting.main_text_matched_substrings,
		};
	};

	const getCountriesForFilter = (): string[] => {
		if (account.branch) {
			return [BranchIsoCode[account.branch]];
		}
		return ['PR', 'CR', 'CO', 'PA'];
	};

	const options = placePredictions.map((place) => getCustomOption(place));
	const placeholder =
		addressType === AddressType.DESTINATION
			? i18n.get('location.search.placeholder_destiny', 'Destino...')
			: i18n.get('location.search.placeholder_location', 'Localización Actual...');

	const getStaticOptions = () => {
		return currentTrack === TRACKS.ROAD ? STATIC_OPTIONS_LIST_ROAD : STATIC_OPTIONS_LIST_HOME;
	};

	return (
		<div className="location-search">
			<Autocomplete
				id={`autocomplete-location-${currentTrack}`}
				fullWidth
				debug={true}
				loading={loading}
				disableClearable={true}
				value={value}
				getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
				filterOptions={(x) => x}
				options={[...getStaticOptions(), ...options]}
				autoComplete
				includeInputInList
				filterSelectedOptions
				onChange={(_: any, newValue: AutocompleteOption) => onChange(newValue)}
				onInputChange={(_, newInputValue) => {
					getPlacePredictions({
						input: newInputValue,
						componentRestrictions: { country: getCountriesForFilter() },
					});
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						label={placeholder}
						variant="outlined"
						fullWidth
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
				renderOption={(option: AutocompleteOption) => {
					if (option.staticOption) {
						return <StaticOptions option={option.staticOption} optionsLength={options.length} />;
					}
					return <DynamicOptions option={option} />;
				}}
			/>
			<LocationPermissionModal
				isVisible={permissionModalOpen}
				onGetLocation={updatePosition}
				onCancel={(): void => setPermissionModalOpen(false)}
			/>
		</div>
	);
};

export default LocationSearch;
