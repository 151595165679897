import React from 'react';

import S from './styles';

interface SurveyRatingRadioButtonProps {
	onChange: (value: number | '') => void;
	name: string;
}

const RatingRadioButton = ({ onChange, name }: SurveyRatingRadioButtonProps): JSX.Element => (
	<>
		<S.DescriptionText>(Donde 0 es nada satisfecho y 10 muy satisfecho)</S.DescriptionText>
		<S.RatingRadioButtonContainer>
			{Array.from({ length: 11 }, (value, i) => (
				<S.RadioButtonLabel key={i}>
					<S.RadioButtonRating
						type="radio"
						key={i}
						name={`radio-button-${name}`}
						value={i}
						onClick={(event) => onChange(event?.currentTarget?.value ? Number(event?.currentTarget?.value) : '')}
					/>
					{i}
				</S.RadioButtonLabel>
			))}
		</S.RatingRadioButtonContainer>
	</>
);

export default RatingRadioButton;
