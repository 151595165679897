import React, { FunctionComponent } from 'react';

import i18n from '../../../utils/i18n';
import { Footer } from '../../common/footer';
import { Service, TRACKS } from '../../../interfaces';

interface SetUpButtonInterface {
	brand: string;
	isSurplus: boolean;
	isHome: boolean;
	actionType: string;
	loading: boolean;
	service: Service;
	receiptAmount: { total: string; tax: string; customerFee: string; surplus: string };
	availability?: string;
	selectedDate?: string | Date;
	onConfirmService: () => void;
	currentTrack: TRACKS;
}

const SetUpButtonComponent: FunctionComponent<SetUpButtonInterface> = ({
	brand,
	isSurplus,
	actionType,
	loading,
	service,
	onConfirmService,
	isHome,
	availability,
	currentTrack,
}) => {
	let btnLabel = i18n.get('bottom_sheet.btn_request_service', 'Solicitar Servicio');

	if (currentTrack === TRACKS.HOME || brand === 'connect' || isSurplus || actionType === 'confirmData')
		btnLabel = i18n.get('bottom_sheet.btn_continue', 'Continuar');

	const disableButton =
		(service.messageConfirmation?.status === 'confirm' && actionType === 'confirmData') || (isHome && !availability);
	return (
		<Footer.Root>
			<Footer.Button disabled={disableButton} onClick={onConfirmService} variant="primary" isLoading={loading}>
				{btnLabel}
			</Footer.Button>
		</Footer.Root>
	);
};

export default SetUpButtonComponent;
