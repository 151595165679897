import React, { useEffect, useState, useCallback, useContext } from 'react';
import cn from 'classnames';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useParams, useHistory } from 'react-router';

import './GeoTextMapScreen.scss';
import { getImagesByBrand } from '../../utils/Utils';
import { Context } from '../../context/Context';
import { AppContext } from '../../interfaces';
import HeliosClient from '../../api/Helios';
import i18n from '../../utils/i18n';
import { GREYLABEL_BRANDS } from '../../utils/constants';

const DEFAULT_COORDS = { lat: 18.42139, lng: -66.073795 };

const GeoTextMapScreen: React.FC = () => {
	// roomId is either a ObjectId of user or a Base64 hash of JSON.stringify({ fromNumber, toNumber });
	const { roomId } = useParams<{ roomId?: string }>();
	const { brand, phoneNumber } = useContext(Context) as AppContext;

	const history = useHistory();
	const [coords, setCoords] = useState(DEFAULT_COORDS);

	const dragEnd = ({ latLng: { lat, lng } }: any): void => {
		sendGeoLocation(lat(), lng());
	};
	const sendGeoLocation = useCallback(
		async (lat: number, lng: number): Promise<void> => {
			setCoords({ lat, lng });
			const address: string = await new HeliosClient().fetchAddress(lat, lng);
			await new HeliosClient().sendGeotextResponse(lat, lng, address, roomId || '', true, phoneNumber);
		},
		[roomId]
	);
	const confirmLocation = () => {
		history.push('/geotext/confirmation');
	};

	const handleGeolocationError = useCallback(
		async (error: PositionError) => {
			console.error(error);
			await new HeliosClient().sendGeotextResponse(null, null, null, roomId || '', false);
			history.push('/geotext/error/location');
		},
		[history, roomId]
	);

	useEffect(() => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(({ coords: { latitude: lat, longitude: lng } }) => {
				sendGeoLocation(lat, lng);
			}, handleGeolocationError);
		}
	}, [history, sendGeoLocation, handleGeolocationError]);

	const isCoBranding = GREYLABEL_BRANDS.includes(brand);
	return (
		<div className="geotext-map-screen tracking-route">
			<div className="geotext-map-screen__logo-container">
				{isCoBranding ? (
					<>
						<img className="logo" src={getImagesByBrand('connect').headerLogo} height="50" alt="logo" />
						<div className="geotext-map-screen__logo-container-separator"></div>
					</>
				) : null}
				<img
					className={cn('logo', { 'logo-co-branding': isCoBranding })}
					src={getImagesByBrand(brand).logo}
					alt="Logo"
					height="50"
					width="130"
				/>
			</div>
			<p className="message" id="advise">
				{i18n.get(
					'screens.geotext.map.title',
					'Confirma tu ubicación, de lo contrario mueve el PIN hacia su ubicación correcta.'
				)}
			</p>
			{/* TODO Use loadScript from parent */}
			<div className="map-container">
				<LoadScript id="script-loader" googleMapsApiKey={process.env.REACT_APP_GMAPS_KEY || ''}>
					<GoogleMap
						id="map"
						zoom={17}
						center={{
							lat: coords.lat,
							lng: coords.lng,
						}}
						options={{ fullscreenControl: false, disableDefaultUI: true }}
					>
						<Marker
							draggable={true}
							position={{
								lat: coords.lat,
								lng: coords.lng,
							}}
							onDragEnd={dragEnd}
						/>
					</GoogleMap>
				</LoadScript>
			</div>
			<button className="button btn-primary btn-footer" onClick={confirmLocation}>
				{i18n.get('screens.geotext.map.btn_confirm', 'Confirmar Localización')}
			</button>
		</div>
	);
};

export default GeoTextMapScreen;
