import { useEffect, useRef, useState } from 'react';
import { TripStamp, TripStatus } from '../../../../interfaces';

const UPDATE_PROGRESS_INTERVAL = 10 * 1000;
const MIN_PROGRESS = 0.05;
const MAX_PROGRESS = 0.95;

function calculateCompletedPercentage(tripStamps: TripStamp[], simulatedEta: number) {
	const initialTime = tripStamps.find((stamp) => stamp.status === TripStatus.NEW)?.time || 0;
	let currentDeltaTime = Date.now() - initialTime;
	if (currentDeltaTime < 0) {
		currentDeltaTime = 0;
	}

	const etaCompletedPercentage = currentDeltaTime / (simulatedEta * 1000);
	let etaCompletedPercentageForUi = etaCompletedPercentage;
	if (etaCompletedPercentage >= MAX_PROGRESS) {
		etaCompletedPercentageForUi = MAX_PROGRESS;
	} else if (etaCompletedPercentage <= MIN_PROGRESS) {
		etaCompletedPercentageForUi = MIN_PROGRESS;
	}

	return etaCompletedPercentageForUi;
}

function calculateCompletedProgressCurvePoints(
	progressCurvePoints: google.maps.LatLng[],
	tripStamps: TripStamp[],
	simulatedEta: number
) {
	const completedPercentage = calculateCompletedPercentage(tripStamps, simulatedEta);
	const numOfProgressCurvePoints = progressCurvePoints.length;
	const numOfCompletedProgressCurvePoints = numOfProgressCurvePoints * completedPercentage;
	const completedProgressCurvePoints = progressCurvePoints.slice(0, numOfCompletedProgressCurvePoints);
	return { completedPercentage, completedProgressCurvePoints };
}

const useProgressCurveAnimation = (
	progressCurvePoints: google.maps.LatLng[],
	tripStamps: TripStamp[],
	simulatedEta: number
) => {
	const timerRef = useRef<number | null>(null);
	const [completedProgressCurvePoints, setCompletedProgressCurvePoints] = useState<google.maps.LatLng[]>([]);
	const [completedPercentage, setCompletedPercentage] = useState<number>(0);

	const updateProgress = (progressCurvePoints: google.maps.LatLng[], tripStamps: TripStamp[], simulatedEta: number) => {
		const { completedPercentage, completedProgressCurvePoints } = calculateCompletedProgressCurvePoints(
			progressCurvePoints,
			tripStamps,
			simulatedEta
		);
		setCompletedPercentage(completedPercentage);
		setCompletedProgressCurvePoints(completedProgressCurvePoints);
	};

	useEffect(() => {
		if (progressCurvePoints.length) {
			updateProgress(progressCurvePoints, tripStamps, simulatedEta);

			timerRef.current = window.setInterval(() => {
				updateProgress(progressCurvePoints, tripStamps, simulatedEta);
			}, UPDATE_PROGRESS_INTERVAL);
		}

		return () => {
			if (typeof timerRef.current === 'number') {
				window.clearInterval(timerRef.current);
				timerRef.current = null;
			}
		};
	}, [progressCurvePoints, tripStamps, simulatedEta]);

	useEffect(() => {
		if (typeof timerRef.current === 'number' && completedPercentage >= MAX_PROGRESS) {
			window.clearInterval(timerRef.current);
			timerRef.current = null;
		}
	}, [completedPercentage]);

	return { completedProgressCurvePoints };
};

export { useProgressCurveAnimation };
