import { Situation } from '../../interfaces';
import headset from '../../assets/imgs/headset.png';
import offRoad from '../../assets/imgs/off-road-truck.png';
import heartPercent from '../../assets/imgs/heart_percent.png';
import provider from '../../assets/imgs/connect-provider.png';
import phone from '../../assets/imgs/CNT_Tech_Illustration_phone.png';
import clock from '../../assets/imgs/connect-clock.svg';

import lafiseHeadset from '../../assets/imgs/lafise-headset.svg';
import lafiseProvider from '../../assets/imgs/lafise-provider.svg';
import lafiseClock from '../../assets/imgs/lafise-clock.svg';
import lafiseThumbs from '../../assets/imgs/lafise-thumbsup.png';
import lafisePhone from '../../assets/imgs/lafise-phone.svg';
import lafiseHeartPercent from '../../assets/imgs/lafise-heart-percent.svg';

import csmThumbs from '../../assets/imgs/csm-thumbsup.svg';
import csmProvider from '../../assets/imgs/csm-provider.svg';
import csmVest from '../../assets/imgs/csm-vest.svg';
import csmHeadset from '../../assets/imgs/csm-headset.svg';
import csmPhone from '../../assets/imgs/csm-phone.svg';

import csmHomeClock from '../../assets/imgs/csm-clock-home.svg';
import csmHomeLove from '../../assets/imgs/csm-love-home.svg';

import suraThumbs from '../../assets/imgs/sura-thumbsup.svg';
import suraProvider from '../../assets/imgs/sura-provider.svg';
import suraClock from '../../assets/imgs/sura-clock.svg';
import suraHeadset from '../../assets/imgs/sura-headset.svg';
import suraPhone from '../../assets/imgs/sura-phone.svg';

import kavakVest from '../../assets/imgs/kavak-vest.svg';
import kavakProvider from '../../assets/imgs/kavak-provider.svg';
import kavakHeartPercent from '../../assets/imgs/kavak-heart-percent.svg';
import kavakHeadset from '../../assets/imgs/kavak-headset.svg';
import kavakPhone from '../../assets/imgs/sura-phone.svg';

import autecoVest from '../../assets/imgs/kavak-vest.svg';
import autecoProvider from '../../assets/imgs/kavak-provider.svg';
import autecoHeartPercent from '../../assets/imgs/kavak-heart-percent.svg';
import autecoHeadset from '../../assets/imgs/kavak-headset.svg';
import autecoPhone from '../../assets/imgs/sura-phone.svg';

import qualitasThumbs from '../../assets/imgs/qualitas-thumbsup.svg';
import qualitasProvider from '../../assets/imgs/qualitas-provider.svg';
import qualitasClock from '../../assets/imgs/qualitas-clock.svg';
import qualitasHeadset from '../../assets/imgs/qualitas-headset.svg';
import qualitasPhone from '../../assets/imgs/qualitas-phone.svg';
import qualitasHeartPercent from '../../assets/imgs/qualitas-heart-percent.svg';

import assaThumbs from '../../assets/imgs/assa-thumbsup.svg';
import assaProvider from '../../assets/imgs/assa-provider.svg';
import assaClock from '../../assets/imgs/assa-clock.svg';
import assaHeadset from '../../assets/imgs/assa-headset.svg';
import assaPhone from '../../assets/imgs/assa-phone.svg';
import assaHeartPercent from '../../assets/imgs/assa-heart-percent.svg';

import humanAdvantageThumbs from '../../assets/imgs/human-advantage-thumbsup.svg';
import humanAdvantageProvider from '../../assets/imgs/human-advantage-provider.svg';
import humanAdvantageVest from '../../assets/imgs/human-advantage-vest.svg';
import humanAdvantageHeadset from '../../assets/imgs/human-advantage-headset.svg';
import humanAdvantagePhone from '../../assets/imgs/human-advantage-phone.svg';

import mmmThumbs from '../../assets/imgs/mmm-thumbsup.svg';
import mmmPhone from '../../assets/imgs/mmm-phone.svg';
import mmmProvider from '../../assets/imgs/mmm-provider.svg';
import mmmVest from '../../assets/imgs/mmm-vest.svg';
import mmmHeadset from '../../assets/imgs/mmm-headset.svg';

import mmmHomeAgent from '../../assets/imgs/mmm-agent-home.svg';
import mmmHomeThumbs from '../../assets/imgs/mmm-thumbsup-home.svg';
import mmmHomeClock from '../../assets/imgs/mmm-clock-home.svg';
import mmmHomeProvider from '../../assets/imgs/mmm-provider-home.svg';
import mmmHomeLove from '../../assets/imgs/mmm-love-home.svg';

import i18n from '../../utils/i18n';

import sssAutoHeadset from '../../assets/imgs/sss-auto-headset.svg';
import sssAutoVest from '../../assets/imgs/sss-auto-vest.svg';
import sssAutoProvider from '../../assets/imgs/sss-auto-provider.svg';
import sssAutoThumbs from '../../assets/imgs/sss-auto-thumbs.svg';
import sssPhone from '../../assets/imgs/sss-phone.svg';

import banescoHeadset from '../../assets/imgs/banesco-headset.svg';
import banescoProvider from '../../assets/imgs/banesco-provider.svg';
import banescoVest from '../../assets/imgs/banesco-vest.svg';
import banescoThumbs from '../../assets/imgs/banesco-thumbsup.svg';
import banescoPhone from '../../assets/imgs/banesco-phone.svg';

import lincolnHeadset from '../../assets/imgs/lincoln-headset.svg';
import lincolnVest from '../../assets/imgs/lincoln-vest.svg';
import lincolnProvider from '../../assets/imgs/lincoln-provider.svg';
import lincolnThumbs from '../../assets/imgs/lincoln-thumbs.svg';
import lincolnPhone from '../../assets/imgs/lincoln-phone.svg';

import acertaHeadset from '../../assets/imgs/acerta-headset.svg';
import acertaProvider from '../../assets/imgs/acerta-provider.svg';
import acertaVest from '../../assets/imgs/acerta-vest.svg';
import acertaThumbs from '../../assets/imgs/acerta-thumbsup.svg';
import acertaPhone from '../../assets/imgs/acerta-phone.svg';

import generalDeSegurosHeadset from '../../assets/imgs/general-de-seguros-headset.svg';
import generalDeSegurosProvider from '../../assets/imgs/general-de-seguros-provider.svg';
import generalDeSegurosVest from '../../assets/imgs/general-de-seguros-vest.svg';
import generalDeSegurosThumbs from '../../assets/imgs/general-de-seguros-thumbs.svg';
import generalDeSegurosPhone from '../../assets/imgs/general-de-seguros-phone.svg';

import connectHomeAgent from '../../assets/imgs/connect-agent-home.svg';
import connectHomeThumbs from '../../assets/imgs/connect-thumbsup-home.svg';
import connectHomeClock from '../../assets/imgs/connect-clock-home.svg';
import connectHomeProvider from '../../assets/imgs/connect-provider-home.svg';
import connectHomeLove from '../../assets/imgs/connect-love-home.svg';

import segurosMundialProvider from '../../assets/imgs/seguros-mundial-survey-provider.svg';
import segurosMundialThumbsUp from '../../assets/imgs/seguros-mundial-survey-thumbs-up.svg';
import segurosMundialAgent from '../../assets/imgs/seguros-mundial-survey-agent.svg';
import segurosMundialExperience from '../../assets/imgs/seguros-mundial-survey-experience.svg';

import ricardoPerezHeadset from '../../assets/imgs/ricardo-perez-headset.svg';
import ricardoPerezVest from '../../assets/imgs/ricardo-perez-vest.svg';
import ricardoPerezProvider from '../../assets/imgs/ricardo-perez-provider.svg';
import ricardoPerezThumbs from '../../assets/imgs/ricardo-perez-thumbs.svg';
import ricardoPerezPhone from '../../assets/imgs/ricardo-perez-phone.svg';

import optimaSegurosHeadset from '../../assets/imgs/optima-seguros-headset.svg';
import optimaSegurosVest from '../../assets/imgs/optima-seguros-vest.svg';
import optimaSegurosProvider from '../../assets/imgs/optima-seguros-provider.svg';
import optimaSegurosThumbs from '../../assets/imgs/optima-seguros-thumbs.svg';
import optimaSegurosPhone from '../../assets/imgs/optima-seguros-phone.svg';

import kiaHeadset from '../../assets/imgs/kia-headset.svg';
import kiaVest from '../../assets/imgs/kia-vest.svg';
import kiaProvider from '../../assets/imgs/kia-provider.svg';
import kiaThumbs from '../../assets/imgs/kia-thumbs.svg';
import kiaPhone from '../../assets/imgs/kia-phone.svg';

import lexusHeadset from '../../assets/imgs/lexus-headset.svg';
import lexusVest from '../../assets/imgs/lexus-vest.svg';
import lexusProvider from '../../assets/imgs/lexus-provider.svg';
import lexusThumbs from '../../assets/imgs/lexus-thumbs.svg';
import lexusPhone from '../../assets/imgs/lexus-phone.svg';

import infinitiHeadset from '../../assets/imgs/infiniti-headset.svg';
import infinitiVest from '../../assets/imgs/infiniti-vest.svg';
import infinitiProvider from '../../assets/imgs/infiniti-provider.svg';
import infinitiThumbs from '../../assets/imgs/infiniti-thumbs.svg';
import infinitiPhone from '../../assets/imgs/infiniti-phone.svg';

import mitsubishiHeadset from '../../assets/imgs/mitsubishi-headset.svg';
import mitsubishiVest from '../../assets/imgs/mitsubishi-vest.svg';
import mitsubishiProvider from '../../assets/imgs/mitsubishi-provider.svg';
import mitsubishiThumbs from '../../assets/imgs/mitsubishi-thumbs.svg';
import mitsubishiPhone from '../../assets/imgs/mitsubishi-phone.svg';

import hyundaiHeadset from '../../assets/imgs/hyundai-headset.svg';
import hyundaiVest from '../../assets/imgs/hyundai-vest.svg';
import hyundaiProvider from '../../assets/imgs/hyundai-provider.svg';
import hyundaiThumbs from '../../assets/imgs/hyundai-thumbs.svg';
import hyundaiPhone from '../../assets/imgs/hyundai-phone.svg';

import toyotaHeadset from '../../assets/imgs/toyota-headset.svg';
import toyotaVest from '../../assets/imgs/toyota-vest.svg';
import toyotaProvider from '../../assets/imgs/toyota-provider.svg';
import toyotaThumbs from '../../assets/imgs/toyota-thumbs.svg';
import toyotaPhone from '../../assets/imgs/toyota-phone.svg';

import nissanHeadset from '../../assets/imgs/nissan-headset.svg';
import nissanProvider from '../../assets/imgs/nissan-provider.svg';
import nissanVest from '../../assets/imgs/nissan-vest.svg';
import nissanThumbs from '../../assets/imgs/nissan-thumbs.svg';
import nissanPhone from '../../assets/imgs/nissan-phone.svg';

import internacionalDeSegurosHeadset from '../../assets/imgs/internacional-de-seguros-headset.svg';
import internacionalDeSegurosVest from '../../assets/imgs/internacional-de-seguros-vest.svg';
import internacionalDeSegurosProvider from '../../assets/imgs/internacional-de-seguros-provider.svg';
import internacionalDeSegurosThumbs from '../../assets/imgs/internacional-de-seguros-thumbs.svg';
import internacionalDeSegurosPhone from '../../assets/imgs/internacional-de-seguros-phone.svg';

import hdiHeadset from '../../assets/imgs/hdi-headset.svg';
import hdiVest from '../../assets/imgs/hdi-vest.svg';
import hdiProvider from '../../assets/imgs/hdi-provider.svg';
import hdiThumbs from '../../assets/imgs/hdi-thumbs.svg';
import hdiPhone from '../../assets/imgs/hdi-phone.svg';

import fedpaHeadset from '../../assets/imgs/fedpa-headset.svg';
import fedpaVest from '../../assets/imgs/fedpa-vest.svg';
import fedpaProvider from '../../assets/imgs/fedpa-provider.svg';
import fedpaThumbs from '../../assets/imgs/fedpa-thumbs.svg';
import fedpaPhone from '../../assets/imgs/fedpa-phone.svg';

import kintoOneVest from '../../assets/imgs/kinto-one-vest.png';
import kintoOnePhone from '../../assets/imgs/kinto-one-phone.png';

import ascHeadset from '../../assets/imgs/asc-headset.svg';
import ascVest from '../../assets/imgs/asc-vest.svg';
import ascProvider from '../../assets/imgs/asc-provider.svg';
import ascThumbs from '../../assets/imgs/asc-thumbs.svg';
import ascPhone from '../../assets/imgs/asc-phone.svg';

import autochilangoHeadset from '../../assets/imgs/autochilango-headset.svg';
import autochilangoVest from '../../assets/imgs/autochilango-vest.svg';
import autochilangoProvider from '../../assets/imgs/autochilango-provider.svg';
import autochilangoThumbs from '../../assets/imgs/autochilango-thumbs.svg';

import eparkHeadset from '../../assets/imgs/epark-headset.svg';
import eparkVest from '../../assets/imgs/epark-vest.svg';
import eparkProvider from '../../assets/imgs/epark-provider.svg';
import eparkThumbs from '../../assets/imgs/epark-thumbs.svg';
import eparkPhone from '../../assets/imgs/epark-phone.svg';

import { Branches } from '../../interfaces';

import { SurveyInputTypes } from '../../interfaces';
import { isNotNull } from '../../utils/Utils';

export interface SurveyQuestion {
	text: string;
	inputType: SurveyInputTypes;
	image?: string;
	type: string;
	cssClass: string;
	defaultValue?: any;
}

function validateProviderName(serviceProvider: string): boolean {
	return [
		'Reembolsos PR',
		'Reembolsos CO',
		'Reembolso PA',
		'Virtual Inspector PR',
		'Inspector Virtual CR',
		'Virtual Inspector PA',
	].includes(serviceProvider);
}

function buildCoordinatorQuestion(image: string): SurveyQuestion {
	return {
		text: i18n.get('survey.questions.coordinator', '¿Cómo calificarías al coordinador de servicio?'),
		inputType: SurveyInputTypes.START_RATING,
		image: image,
		type: 'coordinator',
		cssClass: 'survey-container__survey',
	};
}

export function buildCommentsQuestion(): SurveyQuestion {
	return {
		text: i18n.get('survey.lbl_comments', 'Comentarios adicionales'),
		inputType: SurveyInputTypes.TEXTAREA,
		type: 'comments',
		cssClass: 'survey-container__survey',
	};
}

function buildExperienceQuestion(image: string): SurveyQuestion {
	return {
		text: i18n.get('survey.questions.experience', '¿Cuán probable es que nos recomiende a un amigo o familiar?'),
		inputType: SurveyInputTypes.START_RATING,
		image: image,
		type: 'experience',
		cssClass: 'survey-container__survey',
	};
}

function buildProviderQuestion(image: string, providerName: string): SurveyQuestion | null {
	return validateProviderName(providerName)
		? null
		: {
				text: i18n.get('survey.questions.provider', '¿Cómo calificarías al proveedor de asistencia?'),
				inputType: SurveyInputTypes.START_RATING,
				image: image,
				type: 'provider',
				cssClass: 'survey-container__survey',
		  };
}

function buildProviderArrivalTimeQuestion(image?: string): SurveyQuestion {
	return {
		text: i18n.get(
			'survey.questions.arrival_time',
			'¿En cuánto tiempo (minutos) llegó el proveedor a su localización?'
		),
		inputType: SurveyInputTypes.NUMERIC_STEPPER,
		image: image,
		type: 'arrivalTime',
		cssClass: 'survey-container__survey',
	};
}

function buildMMMProviderQuestion(image: string, providerName: string): SurveyQuestion | null {
	return validateProviderName(providerName)
		? null
		: {
				text: i18n.get('survey.questions.mmmProvider', '¿Cómo calificarías al proveedor de servicio?'),
				inputType: SurveyInputTypes.START_RATING,
				image: image,
				type: 'provider',
				cssClass: 'survey-container__survey',
		  };
}

function buildChalecoQuestion(image: string, driver: any): SurveyQuestion | null {
	return driver.id
		? {
				text: i18n.get(
					'survey.questions.provider_vest',
					'¿Nuestro proveedor lo asistió con un chaleco identificado con nuestro logotipo?'
				),
				inputType: SurveyInputTypes.YES_NO,
				image: image,
				type: 'providerVest',
				cssClass: 'survey-container__survey',
		  }
		: null;
}

function buildConnectAppQuestion(image: string): SurveyQuestion {
	return {
		text: i18n.get('survey.questions.product_experience', '¿Cómo calificarías tu experiencia con esta aplicación?'),
		inputType: SurveyInputTypes.START_RATING,
		image: image,
		type: 'productExperience',
		cssClass: 'survey-container__survey',
	};
}

function buildGentleOperatorQuestion(image: string): SurveyQuestion {
	return {
		text: i18n.get(
			'survey.questions.gentle_operator',
			'¿La persona que atendió tu llamada y coordino el servicio fue amable?'
		),
		inputType: SurveyInputTypes.YES_NO,
		image: image,
		type: 'gentleOperator',
		cssClass: 'survey-container__survey',
	};
}

function buildRecommendationScoreQuestion(image: string): SurveyQuestion {
	return {
		text: i18n.get(
			'survey.questions.recommendation_score',
			'De acuerdo a la experiencia en la atención de la llamada y servicio prestado, en una escala de 1 a 10, siendo 1 mala y 10 excelente, ¿En qué medida recomendarías a Seguros Mundial?'
		),
		inputType: SurveyInputTypes.RATING_BUTTON,
		image: image,
		type: 'recommendationScore',
		cssClass: 'survey-container__survey',
		defaultValue: 0,
	};
}

function buildSegurosMundialProviderQuestion(image: string): SurveyQuestion {
	return {
		text: i18n.get(
			'survey.questions.provider',
			'¿Cómo calificarías la calidad y oportunidad en el servicio recibido por el proveedor de asistencia?'
		),
		inputType: SurveyInputTypes.START_RATING,
		image: image,
		type: 'provider',
		cssClass: 'survey-container__survey',
	};
}

function buildConnectAppHomeProviderSpeed(image: string): SurveyQuestion {
	return {
		text: i18n.get('survey.questions.provider_speed', '¿Nuestro proveedor llegó a tiempo a su cita?'),
		inputType: SurveyInputTypes.YES_NO,
		image,
		type: 'providerSpeed',
		cssClass: 'survey-container__survey',
	};
}

function buildConnectAppHomeUniform(image: string): SurveyQuestion {
	return {
		text: i18n.get('survey.questions.provider_uniform', '¿Nuestro proveedor llegó debidamente uniformado?'),
		inputType: SurveyInputTypes.YES_NO,
		image,
		type: 'providerVest',
		cssClass: 'survey-container__survey',
	};
}

function buildConnectAppHomeRecommend(image: string): SurveyQuestion {
	return {
		text: i18n.get('survey.questions.experience', '¿Cuán probable es que nos recomiende a un amigo o familiar?'),
		inputType: SurveyInputTypes.START_RATING,
		image,
		type: 'experience',
		cssClass: 'survey-container__survey',
	};
}

function buildProductExperience(): SurveyQuestion {
	return {
		text: i18n.get(
			'survey.questions.product_experience',
			'¿Qué tan fácil fue para usted solicitar el servicio de asistencia?'
		),
		inputType: SurveyInputTypes.RATING_BUTTON,
		type: 'productExperienceV2',
		cssClass: 'survey-container__survey',
	};
}

function buildExperienceQuestionV2(): SurveyQuestion {
	return {
		text: i18n.get('survey.questions.experience', '¿Qué tan probable es que nos recomiende a un amigo o familiar?'),
		inputType: SurveyInputTypes.RATING_BUTTON,
		type: 'experienceV2',
		cssClass: 'survey-container__survey',
	};
}

function buildCoordinatorV2Question(): SurveyQuestion {
	return {
		text: i18n.get(
			'survey.questions.coordinator',
			'¿Cuál es su nivel de satisfacción con el coordinador del servicio?'
		),
		inputType: SurveyInputTypes.RATING_BUTTON,
		type: 'coordinatorV2',
		cssClass: 'survey-container__survey',
	};
}

function buildProviderV2Question(): SurveyQuestion {
	return {
		text: i18n.get(
			'survey.questions.provider',
			'¿Cómo evalúa el servicio que le brindó el proveedor atendió su asistencia?'
		),
		inputType: SurveyInputTypes.RATING_BUTTON,
		type: 'providerV2',
		cssClass: 'survey-container__survey',
	};
}

export const getQuestions = (
	brand: string,
	fromConnectApp: boolean,
	branch: string,
	driver: any,
	providerName: string,
	serviceType?: string,
	situation?: Situation
): SurveyQuestion[] => {
	const questions = showQuestions(brand, fromConnectApp, branch, driver, providerName, serviceType, situation);
	return [...questions, buildCommentsQuestion()].filter(isNotNull);
};
export const showQuestions = (
	brand: string,
	fromConnectApp: boolean,
	branch: string,
	driver: any,
	providerName: string,
	serviceType?: string,
	situation?: Situation
): (SurveyQuestion | null)[] => {
	// Default home survey available only for home services in PR
	// since no other branch has home track, also, MMM is excluded
	// since they have their own survey
	if (serviceType === 'home' && branch === Branches.PR && !['mmm', 'mmm-empleados'].includes(brand)) {
		if (brand === 'multiple-asistencia-en-el-hogar') {
			return [
				buildCoordinatorQuestion(csmHeadset),
				buildProviderQuestion(csmThumbs, providerName),
				buildConnectAppHomeProviderSpeed(csmHomeClock),
				buildConnectAppHomeUniform(csmProvider),
				buildConnectAppHomeRecommend(csmHomeLove),
			];
		} else {
			return [
				buildCoordinatorQuestion(connectHomeAgent),
				buildProviderQuestion(connectHomeThumbs, providerName),
				buildConnectAppHomeProviderSpeed(connectHomeClock),
				buildConnectAppHomeUniform(connectHomeProvider),
				buildConnectAppHomeRecommend(connectHomeLove),
			];
		}
	} else {
		switch (brand) {
			case 'lafise':
				if (situation === Situation.TAXI_TO_AIRPORT) {
					return [
						buildCoordinatorQuestion(lafiseHeadset),
						buildProviderQuestion(lafiseProvider, providerName),
						buildExperienceQuestion(lafiseHeartPercent),
						buildConnectAppHomeProviderSpeed(lafiseClock),
					];
				} else {
					return fromConnectApp
						? [buildProviderQuestion(lafiseProvider, providerName), buildConnectAppQuestion(lafisePhone)]
						: [
								buildCoordinatorQuestion(lafiseHeadset),
								buildProviderQuestion(lafiseProvider, providerName),
								driver.id ? buildProviderArrivalTimeQuestion(lafiseClock) : null,
								buildExperienceQuestion(lafiseThumbs),
						  ];
				}
			case 'multiple-asistencia-en-viaje':
			case 'inspecciones-csm':
				return fromConnectApp
					? [buildProviderQuestion(csmProvider, providerName), buildConnectAppQuestion(csmPhone)]
					: [
							buildCoordinatorQuestion(csmHeadset),
							buildProviderQuestion(csmProvider, providerName),
							buildChalecoQuestion(csmVest, driver),
							buildExperienceQuestion(csmThumbs),
					  ];
			case 'sura':
			case 'sura-co':
				return fromConnectApp
					? [buildProviderQuestion(suraProvider, providerName), buildConnectAppQuestion(suraPhone)]
					: [
							buildCoordinatorQuestion(suraHeadset),
							buildProviderQuestion(suraProvider, providerName),
							driver.id ? buildProviderArrivalTimeQuestion(suraClock) : null,
							buildExperienceQuestion(suraThumbs),
					  ];
			case 'kavak':
				return fromConnectApp
					? [buildProviderQuestion(kavakProvider, providerName), buildConnectAppQuestion(kavakPhone)]
					: [
							buildCoordinatorQuestion(kavakHeadset),
							buildProviderQuestion(kavakProvider, providerName),
							buildChalecoQuestion(kavakVest, driver),
							buildExperienceQuestion(kavakHeartPercent),
					  ];
			case 'auteco':
				return fromConnectApp
					? [buildProviderQuestion(autecoProvider, providerName), buildConnectAppQuestion(autecoPhone)]
					: [
							buildCoordinatorQuestion(autecoHeadset),
							buildProviderQuestion(autecoProvider, driver),
							buildChalecoQuestion(autecoVest, driver),
							buildExperienceQuestion(autecoHeartPercent),
					  ];
			case 'qualitas':
				if (situation === Situation.TAXI_TO_AIRPORT) {
					return [
						buildCoordinatorQuestion(qualitasHeadset),
						buildProviderQuestion(qualitasProvider, providerName),
						buildExperienceQuestion(qualitasHeartPercent),
						buildConnectAppHomeProviderSpeed(qualitasClock),
					];
				} else {
					return fromConnectApp
						? [buildProviderQuestion(qualitasProvider, providerName), buildConnectAppQuestion(qualitasPhone)]
						: [
								buildCoordinatorQuestion(qualitasHeadset),
								buildProviderQuestion(qualitasProvider, providerName),
								driver.id ? buildProviderArrivalTimeQuestion(qualitasClock) : null,
								buildExperienceQuestion(qualitasThumbs),
						  ];
				}
			case 'assa':
				if (situation === Situation.TAXI_TO_AIRPORT) {
					return [
						buildCoordinatorQuestion(assaHeadset),
						buildProviderQuestion(assaProvider, providerName),
						buildExperienceQuestion(assaHeartPercent),
						buildConnectAppHomeProviderSpeed(assaClock),
					];
				} else {
					return fromConnectApp
						? [buildProviderQuestion(assaProvider, providerName), buildConnectAppQuestion(assaPhone)]
						: [
								buildCoordinatorQuestion(assaHeadset),
								buildProviderQuestion(assaProvider, providerName),
								driver.id ? buildProviderArrivalTimeQuestion(assaClock) : null,
								buildExperienceQuestion(assaThumbs),
						  ];
				}
			case 'human-advantage':
				return fromConnectApp
					? [buildProviderQuestion(humanAdvantageProvider, providerName), buildConnectAppQuestion(humanAdvantagePhone)]
					: [
							buildCoordinatorQuestion(humanAdvantageHeadset),
							buildProviderQuestion(humanAdvantageProvider, providerName),
							buildChalecoQuestion(humanAdvantageVest, driver),
							buildExperienceQuestion(humanAdvantageThumbs),
					  ];
			case 'mmm':
			case 'mmm-empleados':
				if (situation === Situation.GLASS_REPLACEMENT) {
					return [
						buildCoordinatorQuestion(mmmHeadset),
						buildProviderQuestion(mmmProvider, providerName),
						buildExperienceQuestion(mmmThumbs),
					];
				} else if (serviceType === 'home') {
					return [
						buildCoordinatorQuestion(mmmHomeAgent),
						buildMMMProviderQuestion(mmmHomeThumbs, providerName),
						buildConnectAppHomeProviderSpeed(mmmHomeClock),
						buildConnectAppHomeUniform(mmmHomeProvider),
						buildConnectAppHomeRecommend(mmmHomeLove),
					];
				} else if (fromConnectApp) {
					return [buildProviderQuestion(mmmProvider, providerName), buildConnectAppQuestion(mmmPhone)];
				} else {
					return [
						buildCoordinatorQuestion(mmmHeadset),
						buildProviderQuestion(mmmProvider, providerName),
						buildChalecoQuestion(mmmVest, driver),
						buildExperienceQuestion(mmmThumbs),
					];
				}
			case 'lincoln':
				return fromConnectApp
					? [buildProviderQuestion(lincolnProvider, providerName), buildConnectAppQuestion(lincolnPhone)]
					: [
							buildCoordinatorQuestion(lincolnHeadset),
							buildProviderQuestion(lincolnProvider, providerName),
							buildChalecoQuestion(lincolnVest, driver),
							buildExperienceQuestion(lincolnThumbs),
					  ];
			case 'sss-auto':
			case 'sss-home':
				return fromConnectApp
					? [buildProviderQuestion(sssAutoProvider, providerName), buildConnectAppQuestion(sssPhone)]
					: [
							buildCoordinatorQuestion(sssAutoHeadset),
							buildProviderQuestion(sssAutoProvider, providerName),
							buildChalecoQuestion(sssAutoVest, driver),
							buildExperienceQuestion(sssAutoThumbs),
					  ];
			case 'banesco':
				return fromConnectApp
					? [buildProviderQuestion(banescoProvider, providerName), buildConnectAppQuestion(banescoPhone)]
					: [
							buildCoordinatorQuestion(banescoHeadset),
							buildProviderQuestion(banescoProvider, providerName),
							buildChalecoQuestion(banescoVest, driver),
							buildExperienceQuestion(banescoThumbs),
					  ];
			case 'acerta':
				return fromConnectApp
					? [buildProviderQuestion(acertaProvider, providerName), buildConnectAppQuestion(acertaPhone)]
					: [
							buildCoordinatorQuestion(acertaHeadset),
							buildProviderQuestion(acertaProvider, providerName),
							buildChalecoQuestion(acertaVest, driver),
							buildExperienceQuestion(acertaThumbs),
					  ];
			case 'general-de-seguros':
				return fromConnectApp
					? [
							buildProviderQuestion(generalDeSegurosProvider, providerName),
							buildConnectAppQuestion(generalDeSegurosPhone),
					  ]
					: [
							buildCoordinatorQuestion(generalDeSegurosHeadset),
							buildProviderQuestion(generalDeSegurosProvider, providerName),
							buildChalecoQuestion(generalDeSegurosVest, driver),
							buildExperienceQuestion(generalDeSegurosThumbs),
					  ];
			case 'seguros-mundial':
				return [
					buildRecommendationScoreQuestion(segurosMundialExperience),
					buildExperienceQuestion(segurosMundialThumbsUp),
					buildSegurosMundialProviderQuestion(segurosMundialProvider),
					buildGentleOperatorQuestion(segurosMundialAgent),
				];
			case 'ricardo-perez':
				return fromConnectApp
					? [buildProviderQuestion(ricardoPerezProvider, providerName), buildConnectAppQuestion(ricardoPerezPhone)]
					: [
							buildCoordinatorQuestion(ricardoPerezHeadset),
							buildProviderQuestion(ricardoPerezProvider, providerName),
							buildChalecoQuestion(ricardoPerezVest, driver),
							buildExperienceQuestion(ricardoPerezThumbs),
					  ];
			case 'optima-seguros':
				return fromConnectApp
					? [buildProviderQuestion(optimaSegurosProvider, providerName), buildConnectAppQuestion(optimaSegurosPhone)]
					: [
							buildCoordinatorQuestion(optimaSegurosHeadset),
							buildProviderQuestion(optimaSegurosProvider, providerName),
							buildChalecoQuestion(optimaSegurosVest, driver),
							buildExperienceQuestion(optimaSegurosThumbs),
					  ];
			case 'kia':
				return fromConnectApp
					? [buildProviderQuestion(kiaProvider, providerName), buildConnectAppQuestion(kiaPhone)]
					: [
							buildCoordinatorQuestion(kiaHeadset),
							buildProviderQuestion(kiaProvider, providerName),
							buildChalecoQuestion(kiaVest, driver),
							buildExperienceQuestion(kiaThumbs),
					  ];
			case 'lexus':
				return fromConnectApp
					? [buildProviderQuestion(lexusProvider, providerName), buildConnectAppQuestion(lexusPhone)]
					: [
							buildCoordinatorQuestion(lexusHeadset),
							buildProviderQuestion(lexusProvider, providerName),
							buildChalecoQuestion(lexusVest, driver),
							buildExperienceQuestion(lexusThumbs),
					  ];
			case 'infiniti':
				return fromConnectApp
					? [buildProviderQuestion(infinitiProvider, providerName), buildConnectAppQuestion(infinitiPhone)]
					: [
							buildCoordinatorQuestion(infinitiHeadset),
							buildProviderQuestion(infinitiProvider, providerName),
							buildChalecoQuestion(infinitiVest, driver),
							buildExperienceQuestion(infinitiThumbs),
					  ];
			case 'mitsubishi':
				return fromConnectApp
					? [buildProviderQuestion(mitsubishiProvider, providerName), buildConnectAppQuestion(mitsubishiPhone)]
					: [
							buildCoordinatorQuestion(mitsubishiHeadset),
							buildProviderQuestion(mitsubishiProvider, providerName),
							buildChalecoQuestion(mitsubishiVest, driver),
							buildExperienceQuestion(mitsubishiThumbs),
					  ];
			case 'hyundai':
				return fromConnectApp
					? [buildProviderQuestion(hyundaiProvider, providerName), buildConnectAppQuestion(hyundaiPhone)]
					: [
							buildCoordinatorQuestion(hyundaiHeadset),
							buildProviderQuestion(hyundaiProvider, providerName),
							buildChalecoQuestion(hyundaiVest, driver),
							buildExperienceQuestion(hyundaiThumbs),
					  ];
			case 'toyota':
				return fromConnectApp
					? [buildProviderQuestion(toyotaProvider, providerName), buildConnectAppQuestion(toyotaPhone)]
					: [
							buildCoordinatorQuestion(toyotaHeadset),
							buildProviderQuestion(toyotaProvider, providerName),
							buildChalecoQuestion(toyotaVest, driver),
							buildExperienceQuestion(toyotaThumbs),
					  ];
			case 'nissan':
				return fromConnectApp
					? [buildProviderQuestion(nissanProvider, providerName), buildConnectAppQuestion(nissanPhone)]
					: [
							buildCoordinatorQuestion(nissanHeadset),
							buildProviderQuestion(nissanProvider, providerName),
							buildChalecoQuestion(nissanVest, driver),
							buildExperienceQuestion(nissanThumbs),
					  ];
			case 'internacional-de-seguros':
				return fromConnectApp
					? [
							buildProviderQuestion(internacionalDeSegurosProvider, providerName),
							buildConnectAppQuestion(internacionalDeSegurosPhone),
					  ]
					: [
							buildCoordinatorQuestion(internacionalDeSegurosHeadset),
							buildProviderQuestion(internacionalDeSegurosProvider, providerName),
							buildChalecoQuestion(internacionalDeSegurosVest, driver),
							buildExperienceQuestion(internacionalDeSegurosThumbs),
					  ];
			case 'hdi':
				return fromConnectApp
					? [buildProviderQuestion(hdiProvider, providerName), buildConnectAppQuestion(hdiPhone)]
					: [
							buildCoordinatorQuestion(hdiHeadset),
							buildProviderQuestion(hdiProvider, providerName),
							buildChalecoQuestion(hdiVest, driver),
							buildExperienceQuestion(hdiThumbs),
					  ];
			case 'fedpa':
				return fromConnectApp
					? [buildProviderQuestion(fedpaProvider, providerName), buildConnectAppQuestion(fedpaPhone)]
					: [
							buildCoordinatorQuestion(fedpaHeadset),
							buildProviderQuestion(fedpaProvider, providerName),
							buildChalecoQuestion(fedpaVest, driver),
							buildExperienceQuestion(fedpaThumbs),
					  ];
			case 'kinto-one':
				return [buildChalecoQuestion(kintoOneVest, driver), buildConnectAppQuestion(kintoOnePhone)];
			case 'sos':
				return [
					buildProductExperience(),
					buildCoordinatorV2Question(),
					buildProviderV2Question(),
					buildProviderArrivalTimeQuestion(),
					buildExperienceQuestionV2(),
				];
			case 'sos-transportes-vip':
				return [
					buildCoordinatorV2Question(),
					buildProviderV2Question(),
					buildConnectAppHomeProviderSpeed(''),
					buildExperienceQuestionV2(),
				];
			case 'asc':
				return fromConnectApp
					? [buildProviderQuestion(ascProvider, providerName), buildConnectAppQuestion(ascPhone)]
					: [
							buildCoordinatorQuestion(ascHeadset),
							buildProviderQuestion(ascProvider, providerName),
							buildChalecoQuestion(ascVest, driver),
							buildExperienceQuestion(ascThumbs),
					  ];
			case 'autochilango':
				return fromConnectApp
					? [buildProviderQuestion(autochilangoProvider, providerName), buildConnectAppQuestion(phone)]
					: [
							buildCoordinatorQuestion(autochilangoHeadset),
							buildProviderQuestion(autochilangoProvider, providerName),
							buildChalecoQuestion(autochilangoVest, driver),
							buildExperienceQuestion(autochilangoThumbs),
					  ];
			case 'epark':
				return fromConnectApp
					? [buildProviderQuestion(eparkProvider, providerName), buildConnectAppQuestion(eparkPhone)]
					: [
							buildCoordinatorQuestion(eparkHeadset),
							buildProviderQuestion(eparkProvider, providerName),
							buildChalecoQuestion(eparkVest, driver),
							buildExperienceQuestion(eparkThumbs),
					  ];
			case 'connect-pr-ondemand':
			default:
				if (situation === Situation.TAXI_TO_AIRPORT) {
					return [
						buildCoordinatorQuestion(headset),
						buildProviderQuestion(offRoad, providerName),
						buildExperienceQuestion(heartPercent),
						buildConnectAppHomeProviderSpeed(connectHomeClock),
					];
				} else {
					return fromConnectApp
						? [buildProviderQuestion(provider, providerName), buildConnectAppQuestion(phone)]
						: [
								buildCoordinatorQuestion(headset),
								buildProviderQuestion(offRoad, providerName),
								branch === 'Costa Rica'
									? driver.id
										? buildProviderArrivalTimeQuestion(clock)
										: null
									: branch === 'Colombia'
									? null
									: buildChalecoQuestion(provider, driver),
								buildExperienceQuestion(heartPercent),
						  ];
				}
		}
	}
};
