import React, { Dispatch, FunctionComponent, SetStateAction, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import Breadcrumbs from '../../components/common/Breadcrumbs';
import HeliosClient from '../../api/Helios';
import { reverseGeocode } from '../../components/location/Geocoder';
import { updateLatLng } from '../../components/location/Utils';
import { setLocationPermission, setServiceData } from '../../context/actions';
import { Context } from '../../context/Context';
import { AddressType, AppContext, PermissionType, WatchPositionConfig } from '../../interfaces';
import PermissionIcon from '../../assets/imgs/location-car-icon.svg';
import './Permission.scss';
import i18n from '../../utils/i18n';

interface PermissionTrackingProps {
	setWatchPositionConfig: Dispatch<SetStateAction<WatchPositionConfig>>;
}

const PermissionTracking: FunctionComponent<PermissionTrackingProps> = ({ setWatchPositionConfig }): JSX.Element => {
	const { dispatch, service } = useContext(Context) as AppContext;
	const [selectedOption, setSelectedOption] = useState<string>();
	const history = useHistory();
	const [loading, setLoading] = useState(false);

	const locationSuccessCallback: PositionCallback = async (position) => {
		console.log('Current position:', position);
		const coordinates = {
			latLng: { lat: (): number => position.coords.latitude, lng: (): number => position.coords.longitude },
		};
		await updateLatLng(coordinates, AddressType.SITUATION, dispatch, null, reverseGeocode);
		const { latLng } = coordinates;
		const lat = latLng.lat() as any;
		const lng = latLng.lng() as any;
		const location = await reverseGeocode(lat, lng);
		const {
			data: { data },
		} = await new HeliosClient().updateLocation(service._id, {
			lat,
			lng,
			address: location.address,
			placeId: location.placeId,
			accuracy: position.coords?.accuracy,
		});

		if (data) {
			setServiceData(data, dispatch, null);
			setLocationPermission(dispatch, PermissionType.GRANTED);
			setLoading(false);
			history.replace('/tracking/loading');
		} else {
			setLocationPermission(dispatch, PermissionType.DENIED);
			setLoading(false);
			history.replace('/tracking/loading');
		}
	};

	const locationErrorCallback: PositionErrorCallback = (positionError) => {
		setLoading(false);
		setLocationPermission(dispatch, PermissionType.DENIED);
		history.replace('/tracking/loading');
	};

	const onConfirmClick = (): void => {
		if (selectedOption === 'no') {
			setLocationPermission(dispatch, PermissionType.DENIED);
		} else {
			setLoading(true);
			if (!('geolocation' in navigator)) {
				setLoading(false);
				setLocationPermission(dispatch, PermissionType.DENIED);
				history.replace('/tracking/loading');
			}

			setWatchPositionConfig({
				enableWatchPosition: true,
				successCallback: locationSuccessCallback,
				errorCallback: locationErrorCallback,
			});
		}
		history.replace('/tracking/loading');
	};

	return (
		<div className="location-screen tracking-route">
			<Breadcrumbs onlyShowCloseButton={true} />
			<div className="location-screen__container">
				<figure>
					<img width="300" height="100" src={PermissionIcon} alt="Marker" />
				</figure>
				<div className="location-screen__text">
					<p>{i18n.get('tracking.permission.title', '¿Se encuentra con su vehículo?')}</p>
				</div>

				<div className="location-screen__choices">
					<button
						onClick={() => setSelectedOption('yes')}
						className={classNames('location-screen__choices-btn', {
							'location-screen__choices-btn--selected btn-primary': selectedOption === 'yes',
						})}
					>
						{i18n.get('tracking.permission.btn_yes', 'Sí')}
					</button>
					<button
						onClick={() => setSelectedOption('no')}
						className={classNames('location-screen__choices-btn', {
							'location-screen__choices-btn--selected btn-primary': selectedOption === 'no',
						})}
					>
						{i18n.get('tracking.permission.btn_no', 'No')}
					</button>
				</div>
				{loading && (
					<p className="location-screen__microcopy">
						{i18n.get('tracking.permission.subtitle', 'Estaremos enviando la asistencia a su ubicación actual')}
					</p>
				)}
			</div>
			<div className="location-screen__footer">
				<button
					onClick={onConfirmClick}
					className={classNames('btn-primary btn-footer location-screen__footer-btn', {
						'location-screen__footer-btn--available': selectedOption,
					})}
					disabled={!selectedOption || loading}
				>
					{loading ? (
						<div className="location-screen__loader" />
					) : (
						i18n.get('tracking.permission.btn_confirm', 'Confirmar')
					)}
				</button>
			</div>
		</div>
	);
};

export default PermissionTracking;
