// External imports
import styled from 'styled-components';
import BaseSlider from '@material-ui/core/Slider';

const RatingRadioButtonContainer = styled.div`
	margin-top: 15px;
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
`;

const RadioButtonRating = styled.input`
	width: 20px;
	height: 20px;
	display: block;
	margin: 0px 0px 5px auto;

	&:hover {
		accent-color: ${(props) => props.theme.primaryColor};
	}

	&:checked {
		accent-color: ${(props) => props.theme.primaryColor};
	}
`;

const RadioButtonLabel = styled.label`
	text-align: center;
	display: inline-block;
`;

const DescriptionText = styled.span`
	color: ${(props) => props.theme.lineGray};
	font-size: 12px;
`;

const NumericInputContainer = styled.div`
	display: flex;
	align-items: center;

	input {
		text-align: center;
		margin: 0;
		border: 1px solid ${(props) => props.theme.lineGray};
		border-radius: 8px;
		display: flex;
		width: 112px;
		height: 48px;
		padding: 8px;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		box-sizing: border-box;
		outline: none;
	}
`;

const SliderInput = styled(BaseSlider)`
	margin: 17px 3px 0 3px;
`;

const Textarea = styled.textarea`
	border: 1px solid ${(props) => props.theme.lineGray};
	outline: none;
	width: 98% !important;
`;

const S = {
	SliderInput,
	NumericInputContainer,
	Textarea,
	RatingRadioButtonContainer,
	DescriptionText,
	RadioButtonRating,
	RadioButtonLabel,
};

export default S;
