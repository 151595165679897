export enum Branches {
	PR = 'Puerto Rico',
	PTY = 'Panama',
	CRI = 'Costa Rica',
	CO = 'Colombia',
	MX = 'Mexico',
}

export type BRANCH_VALUES = 'Puerto Rico' | 'Panama' | 'Costa Rica' | 'Colombia';

export const BranchIsoCode = {
	[Branches.PR]: 'PR',
	[Branches.PTY]: 'PA',
	[Branches.CRI]: 'CR',
	[Branches.CO]: 'CO',
	[Branches.MX]: 'MX',
} as const;

export const countryCodeSIC: { [key: string]: number } = {
	[Branches.CRI]: 1,
	[Branches.PTY]: 2,
	[Branches.PR]: 4,
	[Branches.CO]: 5,
};

export enum Situation {
	LOCKSMITH = 'locksmith',
	FLAT_TIRE = 'flatTire',
	FUEL_DELIVERY = 'fuelDelivery',
	JUMP_START = 'jumpStart',
	TOW_BREAKDOWN = 'towBreakdown',
	TOW_TIRE = 'towTire',
	TOW_EXTRACTION = 'towExtraction',
	TOW_COLLISSION = 'towCollission',
	TOW_COLLISION = 'towCollision',
	INSPECTION = 'inspections',
	AMBULANCE = 'ambulance',
	CONDUCTOR_DESIGNADO = 'conductor_designado',
	COORDINACION_REPARACION_NEUMATICO = 'coordinacion_reparacion_neumatico',
	ASISTENCIA_VIAJES_NACIONALES = 'asistencia_viajes_nacionales',
	GLASS_REPLACEMENT = 'glass_replacement',
	TAXI_TO_AIRPORT = 'taxi_to_airport',
}

export enum homeCategories {
	PLUMBING = 'Plumbing',
	HANDYMAN = 'Handyman',
	ELECTRICITY = 'Electricity',
	HOME_LOCKSMITH = 'Home Locksmith',
	GLASSWORKS = 'Glassworks',
	FALL_PREVENTION = 'Fall Prevention',
	TECH_ASSISTANCE = 'Tech Assistance',
	PEST_CONTROL = 'Pest Control',
}

export interface ServiceType {
	id: string;
	name: string;
	label: string;
	label_es?: string;
	type: string;
	fields: Fields;
	primary?: boolean;
	isVial?: boolean;
	schedulable?: boolean;
	isTow?: boolean;
	isInspection?: boolean;
	branches?: Branches[];
	category: string;
}

export enum FieldNames {
	FIRSTNAME = 'firstname',
	LASTNAME = 'lastname',
	OTHERNAME = 'othername',
	PHONE_1 = 'phone1',
	PHONE_2 = 'phone2',
	SERVICE_COST = 'serviceCost',
	SITUATION = 'situation',
	DESTINATION = 'destination',
	VEHICLE = 'vehicle',
	PET = 'pet',
	SCHEDULE = 'schedule',
}

export interface Fields {
	[FieldNames.FIRSTNAME]: Field;
	[FieldNames.LASTNAME]: Field;
	[FieldNames.OTHERNAME]: Field;
	[FieldNames.PHONE_1]: Field;
	[FieldNames.PHONE_2]: Field;
	[FieldNames.SERVICE_COST]: Field;
	[FieldNames.SITUATION]: Field;
	[FieldNames.DESTINATION]: Field;
	[FieldNames.VEHICLE]: VehicleFields;
	[FieldNames.PET]: PetFields;
	[FieldNames.SCHEDULE]: Field;
}

export interface Field {
	name: string;
	label: string;
	type: string;
	visible: boolean;
	required: boolean;
	optional: boolean;
}

export interface PetFields {
	name: Field;
	age: Field;
	breed: Field;
	weight: Field;
	vaccinationCard?: Field;
}

export interface VehicleFields {
	year: Field;
	make: Field;
	model: Field;
	color: Field;
	vin: Field;
	plate: Field;
}

export interface ServiceTypesObject {
	[key: string]: ServiceType;
}

export interface Action<T = any> {
	type: ActionType;
	data?: T;
}

export interface TextMaskCustomProps {
	inputRef: (ref: HTMLInputElement | null) => void;
}

export interface ClientForm {
	fullName: string;
	phone: string;
	email?: string;
	plate: string;
	year: string;
	make: string;
	model: string;
	color: string;
	vin: string;
	mileage: string;
	memberId?: string;
	mbi?: string;
	privacyPolicyAcceptanceDate?: Date | string | null;
}

export interface CustomerImage {
	name: string;
	type: string;
	file: string;
	url: string;
	extension: string;
}

export interface ClientErrors {
	fullName: boolean;
	email?: boolean;
	phone: boolean;
	plate: boolean;
	year: boolean;
	make: boolean;
	model: boolean;
	color: boolean;
}

export interface Location {
	lat: number;
	lng: number;
	address: string;
	placeId: string;
	prettyAddress?: string;
}

export enum PermissionType {
	GRANTED = 'GRANTED',
	DENIED = 'DENIED',
	NOT_ASKED = 'NOT_ASKED',
}

export interface Locations {
	permission: string;
	provider: Location;
	situation: Location;
	destination: Location;
}

export enum ActionType {
	SET_PASSENGERS = 'SET_PASSENGERS',
	SET_CLIENT_INFO = 'CLIENT_INFO',
	SET_CUSTOMER_CONTRACT = 'CUSTOMER_CONTRACT',
	FILL_FORM = 'FILL_FORM',
	SET_SITUATION = 'SET_SITUATION',
	SET_CATEGORY = 'SET_CATEGORY',
	SET_COORDS = 'SET_COORDS',
	SET_ADDRESS = 'SET_ADDRESS',
	SET_CUSTOMER_FEE = 'SET_CUSTOMER_FEE',
	SET_DISTANCE = 'SET_DISTANCE',
	SAVE_PAYMENT = 'SAVE_PAYMENT',
	SET_SERVICE_NUMBER = 'SET_SERVICE_NUMBER',
	SET_SERVICE = 'SET_SERVICE',
	SET_SERVICE_DRIVER = 'SET_SERVICE_DRIVER',
	SET_NOTES = 'SET_NOTES',
	SAVE_ANSWERS = 'SAVE_ANSWERS',
	CLEAR_ALL = 'CLEAR_ALL',
	CLEAR_ALL_FOR_CHECK_LIMIT = 'CLEAR_ALL_FOR_CHECK_LIMIT',
	SET_LOCATION_PERMISSION = 'SET_LOCATION_PERMISSION',
	SET_BRAND = 'SET_BRAND',
	SET_USER_EXTERNAL_PARAMS = 'SET_USER_EXTERNAL_PARAMS',
	SET_ACCOUNT = 'SET_ACCOUNT',
	SECOND_STOP_FILL_FORM = 'SECOND_STOP_FILL_FORM',
	SET_ACTION_TYPE = 'SET_ACTION_TYPE',
	SET_PUSH_OPT_IN = 'SET_PUSH_OPT_IN',
	SAVE_HOME_AVAILABILITY = 'SAVE_HOME_AVAILABILITY',
	SAVE_HOME_DATE = 'SAVE_HOME_DATE',
	SET_SERVICE_TYPES = 'SET_SERVICE_TYPES',
	SET_CURRENT_TRACK = 'SET_CURRENT_TRACK',
	SET_CHECK_LIMIT_ERRORS = 'SET_CHECK_LIMIT_ERRORS',
	SET_REFERENCE_ADDRESS_FORM = 'SET_REFERENCE_ADDRESS_FORM',
	SET_TOKEN = 'SET_TOKEN',
	SET_PHONE_NUMBER = 'SET_PHONE_NUMBER',
	CLEAR_ALL_FOR_MMM_HOD = 'CLEAR_ALL_FOR_MMM_HOD',
	SET_MMM_HOD = 'SET_MMM_HOD',
	CLEAR_CATEGORY = 'CLEAR_CATEGORY',
	SET_CAMPAIGN = 'SET_CAMPAIGN',
	SET_INTEGRATIONS = 'SET_INTEGRATIONS',
}

export interface UserExternalParams {
	identifier: string;
	fullName: string;
	isFromWebview?: boolean;
	policyNumber?: string;
}

export const enum TRACKS {
	ROAD = 'road',
	HOME = 'home',
	CLAIMS = 'claims',
}

export interface AppContext {
	brand: string;
	dispatch: DispatchFunction;
	passengers: number;
	answers: number[];
	homeNotes: HomeNotes;
	notes: string;
	category: homeCategories;
	situation: Situation;
	serviceType: string;
	clientForm: ClientForm;
	locations: Locations;
	customerFee: number;
	distance: number;
	service: Service;
	payment: StripePayment;
	account: Account;
	/** Se mantiene la cuenta para los casos en los que un flujo de alguna cuenta redirige al usuario a otro flujo.
	 * Ejemplo: Un usuario miembro de la cuenta X, quiere un servicio, pero ya llegó al número máximo de servicios que la cuenta le cubre.
	 * Entonces se redirige al usuario al flujo de Connect On Demand, y al pagar se le ofrece un descuento por ser miembro de la cuenta X.
	 */
	previousAccount: Account;
	managementFee: number;
	serviceFees: any;
	actionType: string;
	customerContract: CustomerContract;
	pushOptIn: boolean;
	userExternalParams: UserExternalParams;
	serviceTypes: ServiceTypesObject;
	currentTrack: TRACKS;
	checkLimitErrors: string[];
	referenceAddressForm: ReferenceAddressForm;
	token: string;
	phoneNumber: string;
	isMMMHOD?: boolean;
	campaign: Campaign | null;
	integrationServiceCreation?: Integration;
	integrationTracking?: Integration;
}
interface HomeNotes {
	subService: string;
	availability: string;
	date: string | Date;
}

export interface CustomerContract {
	sf_client_id: string | null; // this is for legacy clients. after all in ETL, we can remove
	contract_id: number | string | null; // The unique_id of this contract
	account: {
		salesforce_id: string;
		name: string;
	};
	customer: {
		full_name: string;
		first_name: string;
		last_name: string;
		phone_number1: string;
		phone_number2: string | null;
		id_card: string; // cedula
	};
	policy: {
		object_type: any | null; // dictates whether vehicle or home key will be present
		symbol: string; // e.g. For MAV-1234001, MAV
		number: string; // e.g. For MAV-1234001, 1234
		unit: string; // e.g. For MAV-1234001, 001
	};
	vehicle?: {
		full_make_model: string;
		make: string;
		model: string;
		year: number;
		vin: string;
		plate: string;
		color: string;
	};
	home?: {
		full_address: string;
	};

	effective_date: Date | undefined;
	expiration_date: Date | undefined;
	effective_date_locale: string;
	expiration_date_locale: string;

	status: any;

	// Object to hold any additional not-necessarily-core data.
	// {memberId: string; mbi: string}; // for MMM services, this is the unique id to identify the contract
	extras: any;
}

export enum AddressType {
	PROVIDER = 'PROVIDER',
	SITUATION = 'SITUATION',
	DESTINATION = 'DESTINATION',
	NONE = 'NONE',
}

interface Route {
	situation: { latitude: number; longitude: number; address: string };
	destination: { latitude: number; longitude: number; address: string };
}

export interface SuggestionRequest {
	account: string;
	branch: string;
	situation: string;
	route: Route;
}

export interface SituationObject {
	image: string;
	name: string;
}

export interface Suggestion {
	_id: string;
	eta: number;
	distance: number;
	situation: number;
	sura: number;
}

export type Suggestions = Suggestion[];

export interface SuggestionResponse {
	drivers: Suggestions;
	request: SuggestionRequest;
}

export type DispatchFunction = (action: Action) => void;

export interface ResponseMessage<T = any> {
	eventId: number; // There is a response which returns this. #shrug 🤷
	serviceId: string;
	status: boolean;
	success: boolean;
	message: boolean;
	data: T;
}

export interface Driver {
	branch: string;
	distance: number;
	email: string;
	eta: number;
	iconset: string;
	inspectorId: string;
	lat: number;
	lng: number;
	name: string;
	pendingSituation: any;
	phone: string;
	services: 0;
	status: string;
	vehicleType: string;
	_id: string;
	id: string;
	vehicleImage: string;
	employeeImage: string;
	bearing: number;
}

export interface Salesforce {
	serviceId: string;
}

export interface Flags {
	onDemand?: any;
	fromConnectApp: boolean;
}

export interface Payment {
	tax: number;
	subtotal: number;
	total: number;
}

export interface TripStamp {
	status: TripStatus;
	time: number;
}

export interface Trip {
	status: TripStatus;
	stamps: TripStamp[];
	simulatedEta?: number;
}

export interface Service {
	account: string;
	accountPhone: string;
	branch: string;
	salesforce: Salesforce;
	serviceNumber: number;
	firstname: string;
	lastname: string;
	_id: string;
	driver: Driver;
	date: Date;
	taxRate: number;
	price: number;
	situation: Situation;
	endDate: Date;
	flags: Flags;
	payments: Payment[];
	surveys: Surveys;
	provider: ServiceProvider;
	surplus: number;
	messageConfirmation?: {
		_id: string;
		status: string;
	};
	external: { id: string };
	schedule: string;
	serviceType?: string;
	trip?: Trip;
	customerContract?: CustomerContract;
	status?: string;
	sfIdAccount?: string;
}

export interface ServiceProvider {
	email: string;
	name: string;
	phone: string;
	providerId: string;
	salesforce: string;
	vendorId: string;
	_id: string;
	id: string;
}

export type Drivers = Driver[];

export interface Surveys {
	answered: boolean;
}

export enum PaymentMethod {
	CASH = 'cash',
	ATH_MOVIL = 'ath_movil',
	CARD = 'card',
}

export enum CancelReasons {
	COMPLICATED = 'Proceso muy complicado',
	PHONE = 'Continué por teléfono',
	NO_SERVICE = 'No necesito servicio',
}

export interface PaymentDetail {
	email: string;
	zipCode: string;
}

export interface PaymentErrors {
	email: string;
	zipCode: string;
}
export interface StripePayment {
	customerIdStripe?: string;
	chargeIdStripe?: string;
	cardIdStripe?: string;
	amount: number;
	paymentMethod: string;
	emailPayment: string;
	tips?: boolean;
	tipAmount?: number;
	tipPaymentType?: string;
	phone?: string;
}

export interface ServiceCategoryInList {
	name: string;
	label: string;
	image: any;
	selectedImage: any;
	Svg: any;
}

export interface Account {
	name?: string;
	_id?: string;
	smsName?: string;
	phone?: string;
	whatsappPhone: string;
	branch?: Branches;
	salesforce?: string;
	managementFee?: number;
	serviceFees?: any;
	homeServicesAllowed?: Array<string>;
	pwaHomeServicesAllowed?: Array<string>;
	otherServicesAllowed: Array<string>;
	pwaRoadServicesAllowed: string[];
	config?: {
		policyLimitVersion?: POLICY_LIMIT_VERSION;
	};
}

export enum POLICY_LIMIT_VERSION {
	POLICY_LIMIT_V1 = 'POLICY_LIMIT_V1',
	POLICY_LIMIT_V2 = 'POLICY_LIMIT_V2',
}

export enum TipAmountButton {
	THREE = 'THREE',
	FIVE = 'FIVE',
	SEVEN = 'SEVEN',
	OTHER = 'OTHER',
}

export interface Centers {
	title: string;
	address: string;
	phone: string;
	lat: number;
	lng: number;
	schedule1: {
		days: string;
		hours: string;
	};
	schedule2: {
		days: string;
		hours: string;
	};
	schedule3: {
		days: string;
		hours: string;
	};
	mapsLink?: string;
}

export interface ContactPhone {
	whatsapp?: string;
	phone: string;
	serviceCoordination?: string;
	urlWhatsapp?: string;
}

export interface CustomerServicePhone {
	phone: string;
}

export interface CorporateAccount {
	status: boolean;
	account: string;
	brand: string;
}

export interface ImageDataExtract {
	DriverFirstName?: string;
	DriverLastName?: string;
	Name?: string;
	IdCard?: string;
	DriverLicenseNumber?: string;
	DriverBirthDate?: string;
	DriverLicenseType?: string;
	DriverLicenseExpirationDate?: string;
	Brand?: string;
	Color?: string;
	InsuredAmount?: string;
	Model?: string;
	Motor?: string;
	Plate?: string;
	Transmission?: string;
	Type?: string;
	Vin?: string;
	Year?: string;
}

export enum TripStatus {
	NEW = 'new',
	ACCEPTED = 'accepted',
	ON_ROUTE = 'on_route',
	ARRIVED = 'arrived',
	TOWED = 'towed',
	FINISHED = 'finished',
	CANCELLED = 'cancelled',
	CANCELLED_BY_DRIVER = 'cancelled_by_driver',
	PENDING_AUDIT = 'pending_audit',
}

export interface ReferenceAddressForm {
	address1: string;
	address2: string;
	city: string;
	zipCode: string;
	country?: Branches;
	fullAddress: string;
}

export interface ReferenceAddressErrors {
	address1: boolean;
	city?: boolean;
	zipCode: boolean;
}

export interface WatchPositionConfig {
	successCallback?: (position: Position) => void;
	errorCallback?: (positionError: PositionError) => void;
	enableWatchPosition: boolean;
}

export enum SurveyInputTypes {
	START_RATING = 'startRating',
	YES_NO = 'yesNo',
	NUMERIC_STEPPER = 'numericStepper',
	SLIDER = 'slider',
	TEXTAREA = 'textarea',
	RATING_BUTTON = 'ratingButton',
}

export interface Integration {
	_id: string;
	account: string;
	division: TRACKS;
	type: INTEGRATION_TYPE;
	disableContractSearch?: boolean;
	disableLegacyPaymentCollection?: boolean;
	onSuccessRedirect?: string;
	legacyEnableReceiptPreview?: boolean;
	onSuccessDynamicParams: string[];
}

export enum INTEGRATION_TYPE {
	CONNECT_APP_SERVICE_CREATION = 'connect-app-service-creation',
	CONNECT_APP_SURVEY = 'connect-app-tracking',
}

export enum ON_SUCCESS_DYNAMIC_PARAMS {
	SERVICE_NUMBER = 'service-number',
}

export enum INTEGRATION_EVENT_TYPE {
	SERVICE_CREATED = 'service-created',
	TRACKING_COMPLETED = 'tracking-completed',
}

export interface HeliosResponse<T = any> {
	status: boolean;
	data: T;
}

export type Campaign = {
	_id: string;
	name: string;
	accounts: string;
	discount?: string;
	restrictedIn?: string[];
	serviceTypes?: Omit<ServiceType, 'fields'>[];
	smsName?: string;
};
