import React, { FunctionComponent } from 'react';
import { Marker, Polyline } from '@react-google-maps/api';

interface MapCurveMarkerProps {
	position1: google.maps.LatLng;
	position2: google.maps.LatLng;
	mapProjection: google.maps.Projection | null;
	zoom: number;
	brand: string;
	strokeColorBrand: (brand: string) => string;
}

const MapCurveMarker: FunctionComponent<MapCurveMarkerProps> = ({
	position1,
	position2,
	mapProjection,
	zoom,
	brand,
	strokeColorBrand,
}): JSX.Element => {
	if (!mapProjection) return <div />;
	const curvature = 0.5;

	const p1 = mapProjection.fromLatLngToPoint(position1);
	const p2 = mapProjection.fromLatLngToPoint(position2);

	// Calculating the arc.
	const e = new window.google.maps.Point(p2!.x - p1!.x, p2!.y - p1!.y), // endpoint
		m = new window.google.maps.Point(e.x / 2, e.y / 2), // midpoint
		o = new window.google.maps.Point(e.y, -e.x), // orthogonal
		c = new window.google.maps.Point(m.x + curvature * o.x, m.y + curvature * o.y); //curve control point

	const pathDef = `M 0,0 q ${c.x},${c.y} ${e.x},${e.y}`;
	const scale = 1 / Math.pow(2, -zoom);

	const symbol = {
		path: pathDef,
		scale: scale,
		strokeWeight: 4,
		strokeOpacity: 0.8,
		strokeColor: strokeColorBrand(brand),
		fillColor: 'none',
	};

	return (
		<>
			<Marker position={position1} clickable={false} icon={symbol} zIndex={1} />
			<Polyline
				path={[position1, position2]}
				options={{
					strokeOpacity: 0.2,
					strokeColor: 'grey',
					icons: [
						{
							icon: {
								path: 'M 0,-2 0,0.5',
								strokeOpacity: 0.1,
								strokeWeight: 3,
								scale: 4,
							},
							offset: '0',
							repeat: '4%',
						},
					],
				}}
			/>
		</>
	);
};

export default MapCurveMarker;
