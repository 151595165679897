import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';

import Breadcrumbs from '../../components/common/Breadcrumbs';
import { ActionType, AddressType, AppContext, Branches, Situation, TRACKS } from '../../interfaces';
import { Context } from '../../context/Context';
import { selectService, selectCategory } from '../../context/actions';

import i18n from '../../utils/i18n';
import { submitSearchContractAndGetRedirect } from '../plate/helper';
import { getServiceTypesFromCategory, getSituationsToDisplay } from './helper';
import Header from '../../components/common/Header';
import { Footer } from '../../components/common/footer';
import { CallDrawer } from './components/CallDrawer';
import { Brand, MMM } from '../../utils/constants';
import OtherServiceHelp from '../../components/common/OtherServiceHelp/OtherServiceHelp';

import './Services.scss';

const ServicesScreen: FunctionComponent = () => {
	const ctx = useContext(Context) as AppContext;
	const {
		category,
		situation,
		brand,
		dispatch,
		userExternalParams,
		serviceTypes,
		currentTrack,
		account,
		previousAccount,
		integrationServiceCreation,
		campaign,
	} = ctx;
	const [service, setService] = useState(false);
	const [loading, setLoading] = useState(false);
	const [callDrawerOpen, setCallDrawerOpen] = useState(false);
	const history = useHistory();

	useEffect(() => {
		if (situation || category) {
			setService(true);
		} else {
			setService(false);
		}
	}, [situation, category]);

	const clearCoords = (): void => {
		// If service not Tow clear Destination fields.
		if (situation !== Situation.TOW_BREAKDOWN) {
			dispatch({
				type: ActionType.SET_COORDS,
				data: { lat: 0.0, lng: 0.0, addressType: AddressType.DESTINATION, placeId: '' },
			});
			dispatch({
				type: ActionType.SET_ADDRESS,
				data: { address: '', placeId: '', addressType: AddressType.DESTINATION, prettyAddress: '' },
			});
		}
	};

	const closeCallDrawer = (): void => {
		setCallDrawerOpen(false);
	};

	const getDefaultSituation = (): string | null => {
		const serviceTypesFromCategory = getServiceTypesFromCategory(
			account,
			previousAccount,
			brand,
			serviceTypes,
			category
		);

		if (serviceTypesFromCategory.length === 1) {
			return serviceTypesFromCategory[0];
		}
		return null;
	};

	const handleContinueClick = async (): Promise<void> => {
		clearCoords();
		if (currentTrack === TRACKS.HOME) {
			await handleHomeRedirect();
		} else {
			await handleRoadRedirect();
		}
	};

	const handleRoadRedirect = async () => {
		if ([Situation.AMBULANCE, Situation.ASISTENCIA_VIAJES_NACIONALES].includes(situation)) {
			setCallDrawerOpen(true);
		} else {
			let url = '';
			if (situation === Situation.TOW_BREAKDOWN) {
				url += '/services/tow';
			} else if (brand !== 'connect' && !integrationServiceCreation?.disableContractSearch) {
				if (userExternalParams?.fullName && userExternalParams?.identifier) {
					const plate = brand === MMM ? '' : userExternalParams.identifier;
					const memberId = brand === MMM ? userExternalParams?.identifier : '';
					setLoading(true);
					url += await submitSearchContractAndGetRedirect(
						ctx,
						dispatch,
						plate,
						userExternalParams.fullName,
						'',
						memberId
					);
					setLoading(false);
				} else {
					url += '/plate';
				}
			} else {
				url = '/clients';
			}
			history.push(url);
		}
	};

	const handleHomeRedirect = async () => {
		let url = '/services/home';
		// If selected category (home track only) has only 1 situation associated then select it
		const defaultSituation = getDefaultSituation();
		if (defaultSituation) {
			selectService(defaultSituation, dispatch);

			if (brand === Brand.HOME_ON_DEMAND_PR || account.branch === Branches.PTY) {
				url = '/location';
			} else if (userExternalParams?.fullName && userExternalParams?.identifier) {
				setLoading(true);

				url = await submitSearchContractAndGetRedirect(
					ctx,
					dispatch,
					'',
					userExternalParams.fullName,
					undefined,
					userExternalParams.identifier
				);
				setLoading(false);
			}
		} else {
			selectService(null, dispatch);
		}

		history.push(url);
	};

	const handleServiceClick = (name: string): void => {
		if (currentTrack === TRACKS.HOME) {
			selectCategory(name, dispatch);
		} else {
			selectService(name, dispatch);
		}
	};

	const optionsToDisplay = getSituationsToDisplay(
		account,
		brand,
		currentTrack,
		serviceTypes,
		previousAccount,
		campaign
	);
	const selection = currentTrack === TRACKS.HOME ? category : situation;
	return (
		<div className="services">
			<Breadcrumbs />
			<Header title={i18n.get('screens.services.title', '¿Qué tipo de servicio requieres?')}></Header>
			<div className="services__container body-container">
				{optionsToDisplay.map(({ name, image, selectedImage, label, Svg }, idx) => (
					<div
						key={idx}
						className={classNames('services__service border-light-primary-color', {
							'bg-primary': selection === name,
						})}
						onClick={(): void => handleServiceClick(name)}
					>
						{image ? (
							<>
								<img
									width="50"
									height="40"
									className={classNames('services__service-icon', {
										'white-color': selection === name,
										'd-block': selection !== name,
										'd-none': selection === name,
									})}
									src={image}
									alt=""
								/>
								{selectedImage ? (
									<img
										width="50"
										height="40"
										className={classNames('services__service-icon', {
											'white-color': selection === name,
											'd-block': selection === name,
											'd-none': selection !== name,
										})}
										src={selectedImage}
										alt=""
									/>
								) : null}
							</>
						) : Svg ? (
							<Svg
								className={classNames('services__service-icon', {
									'svg-color': selection !== name,
									'svg-white': selection === name,
								})}
							/>
						) : null}
						<p
							className={classNames('services__service-label', {
								'white-color': selection === name,
							})}
						>
							{label}
						</p>
					</div>
				))}
				{i18n.get('screens.services.show_other_btn_help') && currentTrack === TRACKS.HOME && <OtherServiceHelp />}
				<div className="services__service-placeholder" />
			</div>
			<Footer.Root>
				<Footer.Button isLoading={loading} disabled={!service} onClick={handleContinueClick} variant="primary">
					{i18n.get('screens.services.btn_continue', 'Continuar')}
				</Footer.Button>
			</Footer.Root>
			<CallDrawer situation={situation} open={callDrawerOpen} onClose={closeCallDrawer} />
		</div>
	);
};

export default ServicesScreen;
