import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import companyLogo from '../../assets/imgs/CNT_Logo_Color_-01 copy.svg';
import i18n from '../../utils/i18n';
import { getMemberAccount, getOnDemandAccount } from './helper';

import './Options.scss';

const Options: FunctionComponent = (): JSX.Element => {
	const history = useHistory();
	const location = useLocation();
	const [branch, setBranch] = useState('');

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		setBranch(queryParams.get('branch')?.toString() || 'pr');
	}, [branch, location.search]);

	const handleMember = (): void => {
		history.push({
			pathname: '/',
			search: `?account=${getMemberAccount(branch)._id}&brand=${getMemberAccount(branch).brand}`,
		});
	};

	const handleVisitor = (): void => {
		history.push({
			pathname: '/',
			search: `?account=${getOnDemandAccount(branch)._id}&brand=${getOnDemandAccount(branch).brand}`,
		});
	};

	return (
		<div className="options_content">
			<img className="logo" src={companyLogo} alt="company logo" />
			<div className="options">
				<h2 className="options__title">
					{i18n.get(
						'screens.options.member.title',
						'Solicita asistencia como Miembro si estás suscrito con Connect Assistance'
					)}
					:
				</h2>
				<div className="options__content">
					<button onClick={handleMember} className="options__btn">
						{i18n.get('screens.options.member.btn_request', 'Solicitar como Miembro')}
					</button>
				</div>
				<h2 className="options__title">
					{i18n.get(
						'screens.options.visitor.title',
						'Solicita asistencia como Visitante si no estás suscrito con Connect Asstitance'
					)}
					:
				</h2>

				<div className="options__content">
					<button onClick={handleVisitor} className="options__btn ">
						{i18n.get('screens.options.visitor.btn_request', 'Solicitar como Visitante')}
					</button>
				</div>
			</div>
		</div>
	);
};

export default Options;
