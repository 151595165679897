import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import TagManager from 'react-gtm-module';
import i18n from './utils/i18n';

import './index.scss';
import App from './App';
import { GTM_ID } from './utils/constants';

Sentry.init({
	dsn: 'https://acaff6dad7dc4f0787d0949cb3c6ce7e@o298640.ingest.sentry.io/6098863',
	integrations: [new Integrations.BrowserTracing({ tracingOrigins: ['*'] })],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
(async () => {
	const state = localStorage.getItem('state');

	if (state) {
		const { brand } = JSON.parse(state);
		await i18n.init(brand); // Reload after refresh page
	}

	const tagManagerArgs = {
		gtmId: GTM_ID,
	};

	TagManager.initialize(tagManagerArgs);

	ReactDOM.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>,
		document.getElementById('root')
	);

	// If you want your app to work offline and load faster, you can change
	// unregister() to register() below. Note this comes with some pitfalls.
	// Learn more about service workers: https://bit.ly/CRA-PWA
})();
