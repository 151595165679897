import { useCallback, useEffect, useState } from 'react';
import { WatchPositionConfig } from '../interfaces';

const MIN_ACCURACY_TO_UPDATE_LOCATION = 20; // meters
const MAX_EXECUTION_TIME = 60000; // duration in milliseconds that the watchPosition will be listening for new locations

const WATCH_POSITION_OPTIONS = {
	enableHighAccuracy: true,
	timeout: 30000,
	maximumAge: 10000,
};

const useWatchPosition = () => {
	const [config, setConfig] = useState<WatchPositionConfig>({ enableWatchPosition: false });

	const clearWatch = (watchId: number): void => {
		watchId && navigator.geolocation.clearWatch(watchId);
	};

	const successCallback = useCallback(
		async (position: Position, watchId: number) => {
			if (position.coords.accuracy > MIN_ACCURACY_TO_UPDATE_LOCATION) {
				return;
			}
			clearWatch(watchId);
			config.successCallback && config.successCallback(position);
		},
		[config]
	);

	const errorCallback = useCallback(
		(positionError: PositionError, watchId: number) => {
			clearWatch(watchId);
			config.errorCallback && config.errorCallback(positionError);
		},
		[config]
	);

	useEffect(() => {
		let watchId: number;

		if (config.enableWatchPosition) {
			watchId = navigator.geolocation.watchPosition(
				(position) => successCallback(position, watchId),
				(positionError) => errorCallback(positionError, watchId),
				WATCH_POSITION_OPTIONS
			);
			setTimeout(() => {
				clearWatch(watchId);
			}, MAX_EXECUTION_TIME);
		}

		return (): void => {
			clearWatch(watchId);
		};
	}, [config.enableWatchPosition, successCallback, errorCallback]);

	return { setWatchPositionConfig: setConfig };
};

export default useWatchPosition;
