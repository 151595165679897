import moment from 'moment';
import React, { FunctionComponent } from 'react';
import receiptBottom from '../../assets/imgs/receipt_bottom.png';
import { Payment, Service } from '../../interfaces';
import i18n from '../../utils/i18n';
import { getSituationIcon } from '../location/Utils';
import { HIDE_RECEIPT_AMOUNT_WHEN_TOTAL_IS_ZERO_BRANDS, HDI } from '../../utils/constants';
import './Receipt.scss';

interface TrackingReceiptProps {
	service: Service;
	customerFee: number;
	brand: string;
}

interface ReceiptAmounts {
	tax: number;
	subtotal: number;
	total: number;
}

const getPayments = (service: Service, customerFee: number): ReceiptAmounts => {
	let tax = 0;
	let total = 0;
	let subtotal = 0;

	/**
	 * Will calculate the amount
	 * if the field of payments from the service
	 * has payment fields
	 */
	if (service.payments.length > 0) {
		for (const payment of service.payments as Payment[]) {
			tax += payment?.tax;
			total += payment?.total;
			subtotal += payment?.subtotal;
		}
	} else {
		// will go either for onDemand or
		// member amounts
		subtotal = customerFee;
		// check if its member
		if (!service.customerContract) {
			tax = getTaxByBranch(service.branch);
			tax = customerFee * tax;
			total = tax + customerFee;
		}
	}
	return {
		subtotal: subtotal / 100,
		tax: tax / 100,
		total: total / 100,
	};
};

const getTaxByBranch = (branch: string): number => {
	switch (branch) {
		case 'Puerto Rico':
			return 0;
		case 'Costa Rica':
			return 0.13;
		case 'Panama':
			return 0.115;
		case 'Mexico':
			return 0.16;
		case 'Colombia':
			return 0.19;
		default:
			return 0;
	}
};

const TrackingReceipt: FunctionComponent<TrackingReceiptProps> = ({
	service,
	customerFee,
	brand,
}): JSX.Element | null => {
	//TODO - Investigate currency internalization for future implementations
	const currencySymbol = service.branch === 'Costa Rica' ? '₡' : '$';
	const { subtotal, tax, total } = getPayments(service, customerFee);
	const serviceDate = moment(service.date).format('MM/DD/YYYY');
	const hideTotal = HIDE_RECEIPT_AMOUNT_WHEN_TOTAL_IS_ZERO_BRANDS.includes(brand) && total === 0;
	const SituationIcon = getSituationIcon(service.situation);
	return (
		<div className="receipt-container">
			<div className="receipt-content">
				{brand !== HDI && <SituationIcon className="situation-icon" />}
				<div className="receipt-header">
					<h3>{i18n.get('tracking.receipt.title', 'Recibo de servicio')}</h3>
					<h3>#{service.serviceNumber}</h3>
				</div>
				<section>
					<span>{i18n.get('tracking.receipt.lbl_date', 'Fecha')}:</span>
					<span>{serviceDate}</span>
				</section>
				{!hideTotal && (
					<>
						<section>
							<span>{i18n.get('tracking.receipt.lbl_subtotal', 'Sub Total')}:</span>
							<span>
								{currencySymbol}
								{subtotal.toFixed(2)}
							</span>
							<span>{i18n.get('tracking.receipt.lbl_taxes', 'Taxes')}:</span>
							<span>
								{currencySymbol}
								{tax.toFixed(2)}
							</span>
						</section>

						<span className="receipt-total">
							{i18n.get('tracking.receipt.lbl_total', 'Total')}:{currencySymbol}
							{total.toFixed(2)}
						</span>
					</>
				)}
			</div>
			<img className="receipt-bottom" src={receiptBottom} alt="receipt bottom" />
		</div>
	);
};

export default TrackingReceipt;
