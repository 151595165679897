import styled from 'styled-components';
import { Drawer as BaseDrawer } from '@material-ui/core';

const Drawer = styled(BaseDrawer)`
	& .MuiDrawer-paperAnchorBottom {
		border-radius: 1em 1em 0 0;
		padding: 20px;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		& > p {
			font-size: 17px;
			margin-bottom: 50px;
		}
	}
`;

const CallButton = styled.button`
	padding: 0;
	margin-bottom: 20px;
`;

const CallLink = styled.a`
	width: 100%;
	height: 100%;
	padding: 11px 0;
	display: inline-block;
	color: inherit;
	text-decoration: none;
`;

const S = {
	Drawer,
	CallButton,
	CallLink,
};

export default S;
