import React, { FunctionComponent, useEffect, useState } from 'react';

import { getQuestions, SurveyQuestion } from './Survey.utils';

import { ServiceProvider, Situation } from '../../interfaces';
import SurveyInput from './SurveyInputs';

interface ConnectAppSurveyState {
	[key: string]: number | boolean | string;
}

interface SurveyContentProps {
	brand: string;
	fromConnectApp: boolean;
	connectAppSurvey: ConnectAppSurveyState;
	setConnectAppSurvey: (connectAppSurvey: ConnectAppSurveyState) => void;
	branch: string;
	driver: any;
	serviceProvider: ServiceProvider;
	serviceType?: string;
	situation?: Situation;
}

const SurveyContent: FunctionComponent<SurveyContentProps> = ({
	brand,
	fromConnectApp,
	setConnectAppSurvey,
	connectAppSurvey,
	branch,
	driver,
	serviceProvider,
	serviceType,
	situation,
}): JSX.Element => {
	const [questions] = useState<SurveyQuestion[]>(() =>
		getQuestions(brand, fromConnectApp, branch, driver, serviceProvider?.name, serviceType, situation)
	);

	useEffect(() => {
		const setSelectedQuestion = (questions: SurveyQuestion[]) => {
			const appQuestions: any = {};

			questions?.map((question: SurveyQuestion) => (appQuestions[question.type] = question.defaultValue ?? ''));
			setConnectAppSurvey({ ...appQuestions });
		};
		setSelectedQuestion(questions);
	}, [questions, setConnectAppSurvey]);

	return (
		<>
			{questions.map((survey: SurveyQuestion, idx: number) => {
				return (
					<div key={idx} className={survey.cssClass}>
						<div className="survey-container__text">
							<p>{`${idx + 1}. ${survey.text}`}</p>
							<SurveyInput
								brand={brand}
								value={connectAppSurvey[survey.type]}
								type={survey.inputType}
								name={survey.type}
								onChange={(value) =>
									setConnectAppSurvey({
										...connectAppSurvey,
										[survey.type]: value,
									})
								}
							/>
						</div>
						{survey.image && (
							<figure>
								<img src={survey.image} alt="App" />
							</figure>
						)}
					</div>
				);
			})}
		</>
	);
};

export default SurveyContent;
