import { useState, useEffect, useContext, useCallback } from 'react';
import { Drivers, SuggestionRequest, SuggestionResponse, Suggestions, AppContext } from '../interfaces';
import HeliosClient from '../api/Helios';
import AutoDispatchClient from '../api/AutoDispatch';
import { Context } from '../context/Context';

export const getDrivers = async (): Promise<Drivers> => {
	const {
		data: { data: drivers },
	} = await new HeliosClient().getDrivers();

	return drivers || [];
};
export const getSuggestions = async (request: SuggestionRequest): Promise<SuggestionResponse> => {
	if (request.route.situation?.latitude === 0 && request.route.situation?.longitude === 0) {
		return { drivers: [], request };
	}
	const {
		data: { data: suggestions },
	} = await new AutoDispatchClient().requestSuggestions({ request });
	return suggestions || {};
};

export const handleDriversUpdate = (
	drivers: Drivers,
	suggestions: Suggestions,
	setDrivers: (drivers: Drivers) => void
): void => {
	suggestions = suggestions.slice(0, 500);
	const bestOptions: Drivers = [];
	for (const suggestion of suggestions) {
		const found = drivers.find((d) => d._id === suggestion._id);
		if (!found) continue;
		found.eta = suggestion.eta;
		found.distance = suggestion.distance;
		bestOptions.push(found);
	}
	setDrivers(bestOptions);
};

export const useDrivers = (initialValue: Drivers = []): Drivers => {
	const { situation, locations, distance } = useContext(Context) as AppContext;
	const [drivers, setDrivers] = useState<Drivers>(initialValue);
	const [previousAddress, setPreviousAddress] = useState(locations.situation.address);

	const updateDrivers = useCallback(async (): Promise<void> => {
		const request: SuggestionRequest = {
			branch: 'Puerto Rico',
			account: '0011Y00002oECd5QAG',
			situation,
			route: {
				situation: {
					latitude: locations.situation.lat,
					longitude: locations.situation.lng,
					address: locations.situation.address,
				},
				destination: {
					latitude: locations.destination.lat,
					longitude: locations.destination.lng,
					address: locations.destination.address,
				},
			},
		};
		try {
			const drivers = await getDrivers();
			const suggestionResponse = await getSuggestions(request);
			handleDriversUpdate(drivers, suggestionResponse.drivers, setDrivers);
		} catch (error) {
			console.error('Captured error:', error);
		}
	}, [locations, situation]);

	useEffect(() => {
		setPreviousAddress(locations.situation.address);
		if (drivers.length !== 0 && previousAddress === locations.situation.address) return;
		updateDrivers();
	}, [updateDrivers, distance, drivers.length, locations, previousAddress]);

	return drivers;
};
