import React, { useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import cn from 'classnames';

import { AppContext } from '../../interfaces';
import Modal from './Modal';
import { ReactComponent as HelpSvg } from '../../assets/imgs/help.svg';
import { ReactComponent as BackBtnSvg } from '../../assets/imgs/back.svg';
import CancelServiceModal from './CancelModal';
import ReasonsModal from './ReasonsModal';
import './Breadcrumbs.scss';
import { Context } from '../../context/Context';
import { getImagesByBrand, isTrackingRoute } from '../../utils/Utils';
import i18n from '../../utils/i18n';
import cancelX from '../../assets/imgs/Cancel_button_icon-01.png';
import HelpModal from './HelpModal';
import { GREYLABEL_BRANDS } from '../../utils/constants';

const Breadcrumbs = ({ onlyShowCloseButton = false }): JSX.Element | null => {
	const { brand, userExternalParams } = useContext(Context) as AppContext;
	const [showModal, setshowModal] = useState(false);
	const [cancelModal, setCancelModal] = useState(true);
	const [reasonsModal, setReasonsModal] = useState(false);
	const [showHelpModal, setShowHelpModal] = useState(false);

	const onCancelServiceClick = (): void => {
		setCancelModal(false);
		setReasonsModal(true);
	};

	const onDimissClick = (): void => {
		setshowModal(false);
		setCancelModal(false);
		setReasonsModal(false);
		setShowHelpModal(false);
	};

	const onHelpClick = (): void => {
		setShowHelpModal(true);
	};

	const { goBack } = useHistory();
	const { pathname } = useLocation();

	const back = () => {
		if (pathname !== '/services') {
			goBack();
		} else {
			window.ReactNativeWebView?.postMessage('closed');
		}
	};

	const invisiblePathnames = ['/tracking', '/tracking/loading', '/service-summary', '/survey'];
	if (!userExternalParams?.isFromWebview) {
		invisiblePathnames.push('/services');
	}

	const renderBackBtn = (): JSX.Element => {
		if (invisiblePathnames.includes(pathname)) {
			return (
				<div className="breadcrumbs__back">
					<div className="breadcrumbs__empty"></div>
				</div>
			);
		} else {
			return (
				<div onClick={back} className="breadcrumbs__back breadcrumbs__back-btn " role="button">
					<BackBtnSvg id={pathname === '/services' ? 'back-to-app' : ''} className="breadcrumbs__back_img svg-color" />
				</div>
			);
		}
	};

	const renderCloseButton = () => {
		return (
			<div
				className={`close-webview ${onlyShowCloseButton ? 'only-show-close-webview' : ''}`}
				role="button"
				onClick={() => window.ReactNativeWebView?.postMessage('closed')}
			>
				<img src={cancelX} alt="Cancel" />
			</div>
		);
	};

	if (onlyShowCloseButton) {
		return userExternalParams?.isFromWebview ? renderCloseButton() : null;
	}

	const isCoBranding = GREYLABEL_BRANDS.includes(brand) && isTrackingRoute();
	return (
		<div className="breadcrumbs-container">
			<div className="breadcrumbs header-background-color">
				{userExternalParams?.isFromWebview && renderCloseButton()}
				<div className={cn('steps', { 'breadcrumbs__co-branding': isCoBranding })}>
					{renderBackBtn()}

					<span className="breadcrumbs__header active app-font-bold" data-testid="step-label">
						{isCoBranding ? (
							<>
								<img
									className="breadcrumbs__header_logo"
									src={getImagesByBrand('connect').headerLogo}
									height="50"
									alt="logo"
								/>
								<div className="breadcrumbs__header_separator"></div>
							</>
						) : null}
						<img
							className={cn('breadcrumbs__header_logo', {
								'breadcrumbs__header_logo-co-brand': GREYLABEL_BRANDS.includes(brand),
							})}
							src={getImagesByBrand(brand).headerLogo}
							height="50"
							alt="logo"
						/>
					</span>

					<div className="breadcrumbs__help breadcrumbs__pointer primary-color" onClick={onHelpClick}>
						{i18n.get('breadcrumbs.btn_help', 'Ayuda')}
						<HelpSvg className="svg-color-stroke" />
					</div>

					{showModal && (
						<Modal dismiss={onDimissClick}>
							{cancelModal && (
								<CancelServiceModal onCancelServiceClick={onCancelServiceClick} brand={brand} dismiss={onDimissClick} />
							)}
							{reasonsModal && <ReasonsModal brand={brand} dismiss={onDimissClick} />}
						</Modal>
					)}

					{showHelpModal && (
						<Modal dismiss={onDimissClick} containerClassName={'modal__container--help'}>
							<HelpModal dismiss={onDimissClick} />
						</Modal>
					)}
				</div>
			</div>
		</div>
	);
};

export default Breadcrumbs;
