import styled from 'styled-components';
import { CircularProgress, Box } from '@material-ui/core';

const CircularLoader = styled(CircularProgress)`
	color: ${({ theme }) => theme.primaryColor};
	align-self: center;
	height: 100px;
	width: 100px;
`;

const BoxLoader = styled(Box)`
	display: flex;
	align-items: center;
	align-self: center;
	height: 100vh;
	width: 100vw;
`;

const CommonStyles = {
	CircularLoader,
	BoxLoader,
};

export default CommonStyles;
