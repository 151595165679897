// External imports
import React from 'react';

// Local imports
import S from './styles';

interface SurveyNumberInputProps {
	value: number;
	onChange: (value: number | undefined) => void;
	min?: number;
	max?: number;
}

const SurveyNumberInput = ({ value, onChange, min = 0, max }: SurveyNumberInputProps): JSX.Element => {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const rawValue = event.target.value || '';
		const numericValue = rawValue.replace(/[^0-9]/g, '');

		// Ensure the value is within the allowed range (min and max props)
		const newValue = numericValue
			? Math.max(min, Math.min(max ?? Number.MAX_SAFE_INTEGER, parseInt(numericValue, 10)))
			: undefined;
		onChange(newValue);
	};

	return (
		<S.NumericInputContainer>
			<input onChange={handleChange} value={value?.toString() ?? ''} min={min} max={max} inputMode="numeric" />
		</S.NumericInputContainer>
	);
};

export default SurveyNumberInput;
