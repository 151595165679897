import React from 'react';
import './Steps.scss';
import { TripStatus } from '../../interfaces';

const steps = {
	[TripStatus.NEW]: 1,
	[TripStatus.ACCEPTED]: 2,
	[TripStatus.ON_ROUTE]: 3,
	[TripStatus.ARRIVED]: 4,
	[TripStatus.TOWED]: 4,
	[TripStatus.FINISHED]: -1,
	[TripStatus.CANCELLED]: -1,
	[TripStatus.CANCELLED_BY_DRIVER]: -1,
	[TripStatus.PENDING_AUDIT]: -1,
};

interface StepsProps {
	currentStatus?: TripStatus;
}

const Steps = ({ currentStatus }: StepsProps): JSX.Element | null => {
	if (!currentStatus || steps[currentStatus] === -1) {
		return null;
	}

	return (
		<div className="tracking-steps">
			{[...Array(4)].map((_, step) => (
				<div
					key={step}
					className={`step ${steps[currentStatus] >= step + 1 ? 'background-tracking-steps' : ''} ${
						step === 3 ? 'last-step' : ''
					}`}
				/>
			))}
		</div>
	);
};

export default Steps;
