import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import Breadcrumbs from '../../components/common/Breadcrumbs';
import { Context } from '../../context/Context';
import { AppContext, Situation } from '../../interfaces';
import i18n from '../../utils/i18n';
import classNames from 'classnames';
import Header from '../../components/common/Header';
import { Footer } from '../../components/common/footer';
import { selectService } from '../../context/actions';
import { getSituationLabel, getCategoryLabel } from '../../utils/Utils';
import { submitSearchContractAndGetRedirect } from '../plate/helper';

import './HomeService.scss';
import { Brand } from '../../utils/constants';
import { getServicesAllowed } from '../services/helper';

const HomeService: FunctionComponent = () => {
	const ctx = useContext(Context) as AppContext;
	const { category, situation, dispatch, serviceTypes, account, previousAccount, userExternalParams, brand, campaign } =
		ctx;
	const [categoryServiceTypes, setCategoryServiceTypes] = useState<Situation[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const history = useHistory();

	useEffect(() => {
		const currentServiceTypes = Object.values(serviceTypes);
		if (currentServiceTypes.length && category) {
			const allowedServicesByAccount = getServicesAllowed(account, brand, previousAccount, campaign);
			const serviceTypesOfSelectedCategory = currentServiceTypes.filter(
				(serviceType) => allowedServicesByAccount.includes(serviceType.name) && serviceType.category === category
			);
			setCategoryServiceTypes(serviceTypesOfSelectedCategory.map((serviceType) => serviceType.name as Situation));
		}
	}, [serviceTypes, category, account.homeServicesAllowed, account.pwaHomeServicesAllowed]);

	const handleConfirmClick = async () => {
		const isMemberOfAccount = userExternalParams.identifier && userExternalParams.fullName;
		const isBrandOnDemand = brand === Brand.HOME_ON_DEMAND_PR;
		const shouldSearchCustomerContract = isMemberOfAccount && !isBrandOnDemand;
		if (shouldSearchCustomerContract) {
			setLoading(true);
			const redirect = await submitSearchContractAndGetRedirect(
				ctx,
				dispatch,
				'',
				userExternalParams.fullName,
				undefined,
				userExternalParams.identifier
			);
			setLoading(false);
			history.push(redirect);
		} else {
			history.push('/location');
		}
	};

	return (
		<div className="home-service">
			<Breadcrumbs />
			<Header
				title={i18n.get(
					'screens.home.service.title',
					`¿Con qué servicio de ${getCategoryLabel(category, situation, serviceTypes)} podemos asistirte?`,
					[getCategoryLabel(category, situation, serviceTypes)]
				)}
				subTitle={i18n.get('screens.home.service.subTitle', 'Solo puedes seleccionar una opción')}
			/>
			<div className="home-service__container body-container">
				{categoryServiceTypes.map((serviceType, idx) => (
					<div
						key={idx}
						className={classNames('home-service__service border-light-primary-color', {
							'bg-primary': situation === serviceType,
						})}
						onClick={(): void => selectService(serviceType, dispatch)}
					>
						<p
							className={classNames('home-service__service-label', {
								'white-color': situation === serviceType,
							})}
						>
							{getSituationLabel(serviceType, serviceTypes)}
						</p>
					</div>
				))}
			</div>
			<Footer.Root>
				<Footer.Button isLoading={loading} disabled={!situation} onClick={handleConfirmClick} variant="primary">
					{i18n.get('screens.home.service.btn_confirm', 'Confirmar')}
				</Footer.Button>
			</Footer.Root>
		</div>
	);
};

export default HomeService;
