import React, { useContext } from 'react';
import { Context } from '../../context/Context';
import { AppContext } from '../../interfaces';
import i18n from '../../utils/i18n';
import { getImagesByBrand } from '../../utils/Utils';

import './GeoTextConfirmationScreen.scss';

const GeoTextConfirmationScreen: React.FC = () => {
	const { brand } = useContext(Context) as AppContext;

	const images = getImagesByBrand(brand);
	return (
		<div className="geotext-confirmation-screen tracking-route">
			<img className="logo" src={images.logo} alt="logo" />
			<img className="check" src={images.successCheckmark} alt="Checkmark Logo" />
			<p className="message">{i18n.get('screens.geotext.confirmation.lbl_received', 'Recibido')}</p>
		</div>
	);
};

export default GeoTextConfirmationScreen;
