import React from 'react';
import './Footer.scss';

interface FooterProps {
	children: React.ReactNode;
}

export const FooterRoot: React.FC<FooterProps> = ({ children }) => {
	return (
		<div className="footer-component">
			<div className="buttons">{children}</div>
		</div>
	);
};
