import React, { FunctionComponent, useContext } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider as MUIThemePRovider } from '@material-ui/core/styles';
import noInternetImage from '../../assets/imgs/CNT_Tech_Illustrationedited_Wifi-01.svg';
import cn from 'classnames';
import './NoConnection.scss';
import { Context } from '../../context/Context';
import { AppContext } from '../../interfaces';
import { getImagesByBrand } from '../../utils/Utils';
import { buildMaterialUITheme, getThemeByAccount } from '../../styled/stylesByAccount';
import GlobalStyle from '../../styled';
import i18n from '../../utils/i18n';

interface NoConnectionScreenProps {
	isOnline: boolean;
}

const NoConnectionScreen: FunctionComponent<NoConnectionScreenProps> = ({ isOnline }) => {
	const { brand } = useContext(Context) as AppContext;

	const classname = cn(brand, {
		'no-internet-hidden': isOnline,
		'no-internet': !isOnline,
	});

	const onTryAgainClick = (): void => {
		if (navigator.onLine) window.location.reload(false);
		else return;
	};

	const currentTheme = getThemeByAccount(brand);
	const muiTheme = buildMaterialUITheme(currentTheme);

	return (
		<StyledThemeProvider theme={currentTheme}>
			<MUIThemePRovider theme={muiTheme}>
				<div className={classname}>
					<GlobalStyle />
					<figure className="no-internet__logo">
						<img src={getImagesByBrand(brand).logo} alt="Logo" />
					</figure>
					<div className="no-internet__container">
						<figure className="no-internet__figure">
							<img src={noInternetImage} alt="no internet" />
						</figure>
						<p className="no-internet__container-text">
							{i18n.get(
								'screens.no_connection.title',
								'Ups, no tienes conexión a internet, \n ¿quieres intentarlo de nuevo?'
							)}
						</p>
					</div>
					<div className="no-internet__footer">
						<button onClick={onTryAgainClick} className="btn-primary btn-footer no-internet__footer-btn">
							{i18n.get('screens.no_connection.btn_retry', 'Tratar Nuevamente')}
						</button>
					</div>
				</div>
			</MUIThemePRovider>
		</StyledThemeProvider>
	);
};

export default NoConnectionScreen;
