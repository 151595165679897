import React from 'react';
import { Account, ActionType, AddressType, DispatchFunction, Situation } from '../../interfaces';
import { ReactComponent as flatTire } from '../../assets/imgs/flatTire_icon.svg';
import { ReactComponent as truck } from '../../assets/imgs/grua_icon.svg';
import { ReactComponent as gasoline } from '../../assets/imgs/gasolina_icon.svg';
import { ReactComponent as battery } from '../../assets/imgs/bateria_icon.svg';
import { ReactComponent as key } from '../../assets/imgs/cerrajeria_icon.svg';
import { ReactComponent as homeIcon } from '../../assets/imgs/icon-home.svg';
import { ReactComponent as ambulance } from '../../assets/imgs/ambulance_icon.svg';
import { ReactComponent as conductorDesignado } from '../../assets/imgs/conductor_designado_icon.svg';
import { ReactComponent as reparacionNeumatico } from '../../assets/imgs/reparacion_neumatico_icon.svg';
import { ReactComponent as asistenciaViajes } from '../../assets/imgs/asistencia_viajes_icon.svg';
import { ReactComponent as glassReplacement } from '../../assets/imgs/glass_replacement_icon.svg';

import i18n from '../../utils/i18n';
import { cleanReferenceAddress } from '../../context/actions';

export const updateLatLng = async (
	{ latLng, placeId }: any,
	addressType: AddressType,
	dispatch: DispatchFunction,
	prettyAddress?: string | null,
	reverseGeocode?: (lat: string, lng: string) => Promise<{ address: string; placeId: string }>
): Promise<void> => {
	const lat = latLng.lat();
	const lng = latLng.lng();
	dispatch({
		type: ActionType.SET_COORDS,
		data: { lat, lng, addressType, placeId },
	});
	if (!reverseGeocode) return;
	const location = await reverseGeocode(lat, lng);
	dispatch({
		type: ActionType.SET_ADDRESS,
		data: { address: location.address, placeId: location.placeId, addressType, prettyAddress },
	});

	if (addressType === AddressType.SITUATION) {
		cleanReferenceAddress(dispatch);
	}
};

export const getSituationIcon = (situation: Situation): React.ElementType => {
	switch (situation) {
		case Situation.FLAT_TIRE:
			return flatTire;
		case Situation.LOCKSMITH:
			return key;
		case Situation.FUEL_DELIVERY:
			return gasoline;
		case Situation.JUMP_START:
			return battery;
		case Situation.TOW_BREAKDOWN:
		case Situation.TOW_TIRE:
		case Situation.TOW_EXTRACTION:
		case Situation.TOW_COLLISION:
		case Situation.TOW_COLLISSION:
			return truck;
		case Situation.AMBULANCE:
			return ambulance;
		case Situation.CONDUCTOR_DESIGNADO:
			return conductorDesignado;
		case Situation.COORDINACION_REPARACION_NEUMATICO:
			return reparacionNeumatico;
		case Situation.ASISTENCIA_VIAJES_NACIONALES:
			return asistenciaViajes;
		case Situation.GLASS_REPLACEMENT:
			return glassReplacement;
		default:
			return homeIcon;
	}
};

// todo: investigate internalization for future implementation of currency strings
export const getDollarSign = (account: Account): string => {
	switch (account.branch) {
		case 'Costa Rica':
			return i18n.get('location.utils.dollar_sign.costa_rica', '₡');
		case 'Puerto Rico':
		case 'Panama':
		default:
			return i18n.get('location.utils.dollar_sign.default', '$');
	}
};

export const getTax = (account: Account): { tax: number; label: string } => {
	switch (account.branch) {
		case 'Puerto Rico':
			return { tax: 0, label: i18n.get('location.utils.tax.puerto_rico', 'IVU(11.5%)') };
		case 'Costa Rica':
			return { tax: 15, label: i18n.get('location.utils.tax.costa_rica', 'I.V.A(15%)') };
		case 'Panama':
			return { tax: 11.5, label: i18n.get('location.utils.tax.panama', 'I.V.A(15%)') };
		default:
			return { tax: 0, label: i18n.get('location.utils.tax.default', '') };
	}
};

export const getAmount = (
	service: { surplus: number },
	customerFee: number,
	account: Account,
	brand: string,
	isSurplus: boolean,
	legacyEnableReceiptPreview?: boolean
): { total: string; tax: string; customerFee: string; surplus: string } => {
	const surplus: number = service.surplus / 100;
	let total: number = customerFee / 100;
	let tax: number = (getTax(account).tax / 100) * total;
	let fee: number = customerFee / 100;

	if (brand !== 'connect' && !legacyEnableReceiptPreview) {
		total = 0;
		tax = 0;
		fee = 0;
	}

	if (brand !== 'connect' && isSurplus) {
		total = surplus;
		tax = (getTax(account).tax / 100) * surplus;
		fee = 0;
	}
	return {
		total: (total + tax).toFixed(2),
		tax: tax.toFixed(2),
		customerFee: fee.toFixed(2),
		surplus: surplus.toFixed(2),
	};
};
