import i18next from 'i18next';
import i18nextChainedBackend from 'i18next-chained-backend';
import i18nextLocalStorageBackend from 'i18next-localstorage-backend';
import i18nextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// Matches string that is wrapped with ${} just like a js interpolation
// ie: Hey this test is ${color} -> ${color} can be replaced by 'green', 'blue', etc.
const INTERPOLATION_REGEX = /\${?([\w\d?.]*)}/gim;

const init = async (brand = 'connect'): Promise<void> => {
	if (i18next.options?.defaultNS !== brand) {
		await i18next
			.use(i18nextChainedBackend)
			.use(initReactI18next)
			.init(
				{
					lng: 'es', // if we are using a language detector, do not define the lng option
					ns: ['common', brand],
					defaultNS: brand,
					fallbackLng: false,
					debug: true,
					interpolation: {
						escapeValue: false,
					},
					backend: {
						backends: [i18nextLocalStorageBackend, i18nextHttpBackend],
						backendOptions: [
							{
								expirationTime: 60, //60 * 60 * 1000, // 1 hour // 7 * 24 * 60 * 60 * 1000, // 7 days
							},
							{
								loadPath: '/locales/{{ns}}/{{lng}}.json',
							},
						],
					},
				},
				function (err) {
					if (err) console.error(err);
				}
			);
	}

	i18next.on('missingKey', function (lngs, namespace, key, res) {
		console.log(lngs, namespace, key, res);
	});
};

const get = (key: string, defaultValue: string | undefined = undefined, replacements: string[] = []): string => {
	let value: string;
	if (i18next.exists(key)) {
		value = i18next.t(key);
	} else {
		value = i18next.t(key, defaultValue, { ns: 'common' });
	}

	if (value && replacements.length) {
		const groups = value.match(INTERPOLATION_REGEX);
		groups &&
			groups.forEach((group, index) => {
				value = value.replace(group, replacements[index]);
			});
	}

	return value;
};
export default { init, get };
