import { createGlobalStyle } from 'styled-components';
import { convertHexToRGBA } from '../utils/Utils';

const GlobalStyle = createGlobalStyle`
	html {
		font-family: ${(props): string => props.theme.appFont};
		font-size: ${(props): string => props.theme.fontSizeDefault};
		line-height: 1.2;
		color: ${(props): string => props.theme.textColor};
		box-sizing: border-box;
	}

	body {
		background-color: ${(props): string => props.theme.appBackgroundColor};
	}

	div.MuiInputBase-root,
	label.MuiFormLabel-root,
	li.MuiButtonBase-root,
	input,
	label,
	textarea {
		font-size: ${(props): string => props.theme.fontSizeForm};
		font-family: ${(props): string => props.theme.appFont};
	}

	& input.MuiInputBase-input {
		font-family: ${(props): string => props.theme.appFont};
	}

	p.MuiFormHelperText-root {
		font-size: 12px;
	}

	.app-background-color {
		background-color: ${(props): string => props.theme.appBackgroundColor};
	}

  .splash-background-color {
    background-color: ${(props): string => props.theme.splashBackgroundColor};
  }

	.primary-light-line {
		width: 100%;
		height: 1px;
		background-color: ${(props): string => convertHexToRGBA(props.theme.primaryColor, 0.4)}};
	}

	.border-bottom-primary-light-line {
		border-bottom: 1px solid ${(props): string => convertHexToRGBA(props.theme.primaryColor, 0.4)}};
	}

	.border-light-primary-color {
		border-color: ${(props): string => convertHexToRGBA(props.theme.primaryColor, 0.4)};
	}

	.header-background-color {
		background-color: ${(props): string => props.theme.headerBackgroundColor};
	}

	.primary-color {
		color: ${(props): string => props.theme.primaryColor};
	}

	.border-primary-color {
		border-color: ${(props): string => props.theme.primaryColor};
	}

	.loader-color {
		border-color: ${(props): string => props.theme.loaderColor || props.theme.primaryColor};
	}

	.border-right-transparent-color {
		border-right-color: transparent;
	}

	.bg-white {
		background-color: white;
	}

	.white-color {
		color: ${(props): string => props.theme.light};
	}

	.dark-color-important {
		color: ${(props): string => props.theme.dark} !important;
	}

	.gray-color {
		color: ${(props): string => props.theme.gray};
	}

	.bg-white {
		background-color: ${(props): string => props.theme.light};
	}

	.bg-primary {
		background-color: ${(props): string => props.theme.primaryColor};
	}

	.bg-primary-important {
		background-color: ${(props): string => props.theme.primaryColor} !important;
	}

	.bg-secondary {
		background-color: ${(props): string => props.theme.secondaryColor};
	}

	.app-font {
		font-family: ${(props): string => props.theme.appFont};
	}

	.app-font-medium {
		font-family: ${(props): string => props.theme.appFontMedium};
	}

	.app-font-light {
		font-family: ${(props): string => props.theme.appFontLight};
	}

	.app-font-bold {
		font-family: ${(props): string => props.theme.appFontBold};
	}

	.app-font-semi-bold {
		font-family: ${(props): string => props.theme.appFontSemiBold};
	}

	.app-font-heading {
		font-family: ${(props): string => props.theme.appFontHeading};
	}

	.font-weight-bold {
		font-weight: bold;
	}

	.font-size-heading {
		font-size: ${(props): string => props.theme.fontSizeHeading};
	}

  .font-size-subtitle {
    font-size: ${(props): string => props.theme.fontSizeSubHeading};
  }

	.title-color {
		color: ${(props): string => props.theme.titleColor};
	}

	h2 {
		font-family: ${(props): string => props.theme.appFontHeading};
		font-size: ${(props): string => props.theme.fontSizeHeading};
		color: ${(props): string => props.theme.titleColor};
	}

	.title {
		font-family: ${(props): string => props.theme.appFontHeading};
		font-size: ${(props): string => props.theme.fontSizeHeading};
		color: ${(props): string => props.theme.titleColor};
	}

	button {
		display: block;
		padding: 7px;
		border-radius: 8px;
		text-align: center;
		text-decoration: none;
		font-size: ${(props): string => props.theme.fontSizeDefault};
		background-color: ${(props): string => props.theme.defaultBtnBackgroundColor};
		border: ${(props): string => `${props.theme.defaultBtnBorderWidth} solid ${props.theme.defaultBtnBorderColor}`};
		color: ${(props): string => props.theme.defaultBtnFontColor};
		font-family: ${(props): string => props.theme.appFontBold};
	}

	.btn-primary {
		color: white;
		border: initial;
		background-color: ${(props): string => props.theme.btnColor || props.theme.primaryColor};
		border: 1px solid ${(props): string => props.theme.btnColor || props.theme.primaryColor};
	}

	.btn-secondary {
		background-color: ${(props): string => props.theme.defaultBtnBackgroundColor};
		border: ${(props): string => `${props.theme.defaultBtnBorderWidth} solid ${props.theme.defaultBtnBorderColor}`};
		color: ${(props): string => props.theme.defaultBtnFontColor};
	}

	.btn-outline-primary {
		background-color: white;
		color: ${(props): string => props.theme.btnColor || props.theme.primaryColor};
		border: 2px solid ${(props): string => props.theme.btnColor || props.theme.primaryColor};
	}

	.btn-disabled {
		color: white;
		font-family: ${(props): string => props.theme.gray};
		border: 1px solid ${(props): string => props.theme.gray};
		background-color: ${(props): string => props.theme.gray};
	}

  .font-size-btn-footer {
    font-size: ${(props): string => props.theme.fontSizeBtnFooter};
  }

	.btn-footer {
		max-width: 400px;
		min-height: 20px;
		width: 90%;
		font-size: ${(props): string => props.theme.fontSizeBtnFooter};
	}

	.w-100 {
		width: 100%;
	}

	.svg-color path {
		fill: ${(props): string => props.theme.primaryColor};
	}

	.svg-white path {
		fill: white;
	}

	.svg-light-color path {
		fill: ${(props): string => convertHexToRGBA(props.theme.primaryColor, 0.4)};
	}

	.svg-color-stroke path {
		stroke: ${(props): string => props.theme.primaryColor};
	}

	.svg-white-stroke path {
		stroke: white;
	}

	.flex-2 {
		flex: 2;
	}

	.mb-0 {
		margin-bottom: 0;
	}

	.background-tracking-steps {
		background-color: ${(props): string => props.theme.backgroundTrackingSteps || props.theme.primaryColor} !important;
	}

	.tracking-animation {
		.animation-color {
			fill: ${(props): string => props.theme.animationsColor || props.theme.primaryColor};
			stroke: ${(props): string => props.theme.animationsColor || props.theme.primaryColor};
		}

		.animation-light-color {
			fill: ${(props): string => convertHexToRGBA(props.theme.animationsColor || props.theme.primaryColor, 0.93)};
			stroke: ${(props): string => convertHexToRGBA(props.theme.animationsColor || props.theme.primaryColor, 0.93)};
		}
		.animation-dark-color {
			fill: ${(props): string => convertHexToRGBA(props.theme.animationsColor || props.theme.primaryColor, 0.93)};
			filter: brightness(75%);
			stroke: ${(props): string => convertHexToRGBA(props.theme.animationsColor || props.theme.primaryColor, 0.93)};
		}
	}

	.map {
		.loading-pin {
			box-shadow: 0 0 0 0 ${(props): string => props.theme.primaryColor};
			animation: pulse 2s infinite;
		}

		@keyframes pulse {
			0% {
				transform: scale(0.95);
				box-shadow: 0 0 0 0 ${(props): string => props.theme.primaryColor};
			}

			70% {
				transform: scale(1);
				box-shadow: 0 0 0 200px rgba(226, 130, 5, 0);
			}

			100% {
				transform: scale(0.95);
				box-shadow: 0 0 0 0 rgba(226, 130, 5, 0);
			}
		}
	}

	.label-story {
		color: ${(props): string => props.theme.primaryColor};
	}

	.icon-info {
		color: ${(props): string => props.theme.primaryColor};
	}

	.text-info {
		color: ${(props): string => props.theme.primaryColor};
	}

	button.continue-btn.MuiButton-root:not(.disabled) {
		background-color: ${(props): string => props.theme.primaryColor};
	}

	.text-left {
		text-align: left;
	}
`;

export default GlobalStyle;
