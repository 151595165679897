import React from 'react';
import Star from '../../../components/common/Star';

interface StarRatingInputProps {
	brand: string;
	value: number;
	onChange: (value: number) => void;
}

const StarRatingInput: React.FC<StarRatingInputProps> = ({ brand, value, onChange }) => {
	return (
		<>
			{[...Array(5)].map((n, index) => (
				<Star onClick={() => onChange(index + 1)} key={index} selected={index < value} brand={brand} />
			))}
		</>
	);
};

export default StarRatingInput;
