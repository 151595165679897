import React, { FunctionComponent, useEffect, useState } from 'react';
import phoneImg from '../../../assets/imgs/Phone_Icon2-01.svg';
import i18n from '../../../utils/i18n';
import './CallModal.scss';

const TIME_TO_CLOSE = 8000;

interface CallModalProps {
	dismiss: () => void;
}

const CallModal: FunctionComponent<CallModalProps> = ({ dismiss }): JSX.Element => {
	const [dismissTimer, setCallDismissTimer] = useState(false);

	useEffect(() => {
		if (dismissTimer) return;
		setTimeout(dismiss, TIME_TO_CLOSE);

		setCallDismissTimer(true);
	}, [dismissTimer, dismiss]);

	return (
		<div className="call-modal">
			<div className="call-modal__header">
				<div>{i18n.get('tracking.driver.call_modal.title', 'Llamando')}</div>
				<span onClick={dismiss}>X</span>
			</div>
			<div className="call-modal__content">
				<figure>
					<img className="call-modal__image call-modal__image--animated" src={phoneImg} alt="phone logo" />
				</figure>
				<p>{i18n.get('tracking.driver.call_modal.subtitle', 'Favor de esperar')}</p>
			</div>
		</div>
	);
};

export default CallModal;
