import React, { ReactNode, MouseEventHandler } from 'react';
import cn from 'classnames';
import styled from 'styled-components';

interface FooterButtonProps {
	onClick: MouseEventHandler<HTMLButtonElement>;
	variant: 'primary' | 'primary-outline';
	isLoading?: boolean;
	disabled?: boolean;
	children: ReactNode;
}

const StyledButton = styled.button`
	margin-bottom: 10px;
	width: 100%;
	max-width: 400px;
	text-transform: uppercase;
`;

export const FooterButton: React.FC<FooterButtonProps> = ({ variant, isLoading, disabled, onClick, children }) => {
	const variantClass = variant === 'primary' ? 'btn-primary' : 'btn-outline-primary';
	return (
		<StyledButton
			onClick={onClick}
			disabled={disabled || isLoading}
			className={cn('font-size-btn-footer', variantClass)}
		>
			{isLoading ? <div className="btn-loader" /> : children}
		</StyledButton>
	);
};
