import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';

import HeliosClient from '../../../api/Helios';
import { saveHomeAvailability, saveHomeDate, setCustomerFee, setServiceData } from '../../../context/actions';
import { Context } from '../../../context/Context';
import { useDrivers } from '../../../hooks/useDrivers';

import { Account, ActionType, AppContext, ClientForm, DispatchFunction, Situation, TRACKS } from '../../../interfaces';
import {
	Brand,
	HIDE_PLATE_FIELD,
	MMM,
	SHOW_VEHICLE_INFO_IN_SUMMARY_BRANDS,
	SKIP_CONTRACT_BRANDS,
} from '../../../utils/constants';
import { getAmount, getDollarSign, getSituationIcon, getTax } from '../Utils';

import closeCalendarIcon from '../../../assets/imgs/icon-cancel.svg';
import bulletInactive from '../../../assets/imgs/bullet_inactive.svg';
import information from '../../../assets/imgs/informacion_icon.svg';
import { ReactComponent as PinSvg } from '../../../assets/imgs/icono-pin.svg';

import './BottomSheet.scss';
import Availability from './Availability';
import HomeAvailability from './HomeAvailability';
import i18n from '../../../utils/i18n';
import VehicleInfo from './VehicleInfo';
import { Footer } from '../../common/footer';
import SetUpButtonComponent from './SetUpButtonComponent';
import { getSituationLabel } from '../../../utils/Utils';

export const fetchFeeData = async (data: any, dispatch: DispatchFunction): Promise<void> => {
	const result = await new HeliosClient().getCustomerFee(data);
	setCustomerFee(dispatch, +result.data.data);
};

interface LocationBottomSheetProps {
	hidden: boolean;
	locationConfirmed: boolean;
}

const LocationBottomSheet: FunctionComponent<LocationBottomSheetProps> = ({
	hidden,
	locationConfirmed,
}): JSX.Element => {
	const ctx = useContext(Context) as AppContext;
	const {
		locations,
		situation,
		distance,
		customerFee,
		brand,
		account,
		service,
		actionType,
		clientForm,
		dispatch,
		homeNotes,
		serviceTypes,
		currentTrack,
		integrationServiceCreation,
	} = ctx;

	const twoDaysFromNowDate = new Date().setDate(new Date().getDate() + 2);

	const [clientInfo] = useState<ClientForm>(clientForm);
	const [accountInfo] = useState<Account>(account);
	const [loading, setLoading] = useState<boolean>(false);
	const [serviceUpdated, setServiceUpdated] = useState<boolean>(false);
	const [displayScheduleButtons, setDisplayScheduleButtons] = useState<boolean>(true);
	const [isScheduledService, setIsScheduledService] = useState<boolean>(false);
	const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
	const [isInformationOpen, setIsInformationOpen] = useState<boolean>(false);
	const [isSurplus, setIsSurplus] = useState(Boolean(service.surplus));
	const [selectedDate, setSelectedDate] = useState<string | Date>(new Date());
	const [homeSelectedDate, setHomeSelectedDate] = useState(twoDaysFromNowDate);
	const [selectedTime, setSelectedTime] = useState(new Date());
	const [eta, setEta] = useState<number>(0);
	const [availability, setAvailability] = useState<string>('');
	const [availabilityInit, setAvailabilityInit] = useState(false);
	const drivers = useDrivers();
	const history = useHistory();

	useEffect(() => {
		const isRicardoPerezScheduledServices =
			brand === Brand.RICARDO_PEREZ &&
			[Situation.CONDUCTOR_DESIGNADO, Situation.COORDINACION_REPARACION_NEUMATICO].includes(situation);
		const isMMMScheduledServices = brand === Brand.MMM && Situation.GLASS_REPLACEMENT === situation;

		if (isRicardoPerezScheduledServices || isMMMScheduledServices) {
			setDisplayScheduleButtons(false);
			setIsScheduledService(true);
		}
	}, [brand, situation]);

	useEffect(() => {
		setEta(Math.round(drivers[0]?.eta / 60));
	}, [drivers]);

	useEffect(() => {
		if (availabilityInit) return;
		setAvailability(homeNotes.availability);
		setHomeSelectedDate(homeNotes.date as any);
		setAvailabilityInit(true);
	}, [availabilityInit, homeNotes.availability, homeNotes.date]);

	const handleHomeDateChange = (date: any): void => {
		setHomeSelectedDate(date);
	};

	const handleDateChange = (date: any): void => {
		setSelectedDate(date);
	};
	const handleTimeChange = (date: any): void => {
		setSelectedTime(date);
	};

	const submitService = useCallback(
		async (ctx: AppContext): Promise<void> => {
			setLoading(true);
			const { data } = await new HeliosClient().submitService({ ...ctx });

			ctx.dispatch({ type: ActionType.SET_SERVICE_NUMBER, data: data.eventId });
			const {
				data: { data: service },
			} = await new HeliosClient().getService(data.serviceId);

			dispatch({ type: ActionType.SET_SERVICE, data: service });
			setLoading(false);
			if (!service?.schedule) {
				history.replace('/tracking/loading');
			} else {
				history.replace('/service-summary');
			}
		},
		[dispatch, history]
	);

	const onConfirmService = async (): Promise<void> => {
		let schedule = '';
		if (isScheduledService) {
			const date = dayjs(selectedDate).format('MM/DD/YYYY');
			const time = dayjs(selectedTime).format('HH:mm:ss');
			schedule = dayjs(`${date}  ${time}`).toISOString();
		}
		const scheduleData = { ...service, schedule };
		setServiceData(scheduleData, dispatch, '');
		ctx.service = { ...service, schedule };

		if (isHome) {
			saveHomeAvailability(dispatch, availability);
			saveHomeDate(dispatch, dayjs(homeSelectedDate).format('MM/DD/YYYY'));
		}

		if (actionType === 'confirmData') {
			//call update data
			setLoading(true);
			const { data } = await new HeliosClient().confirmationData({ ...ctx });
			if (data.status) {
				if (service.messageConfirmation) service.messageConfirmation.status = 'confirm';
				setServiceData(service, dispatch, null);
				setServiceUpdated(true);
			}
			setLoading(false);
			return;
		}
		if (integrationServiceCreation?.disableLegacyPaymentCollection) {
			submitService(ctx);
		} else if (currentTrack === TRACKS.HOME) {
			history.push('/clients');
		} else if (brand === 'connect') {
			history.push('/payment');
		} else {
			if (isSurplus) {
				history.push('/payment');
			} else {
				submitService(ctx);
			}
		}
	};

	const onConfirmClick = async (): Promise<void> => {
		if (
			!SKIP_CONTRACT_BRANDS.includes(brand) &&
			actionType !== 'confirmData' &&
			!integrationServiceCreation?.disableContractSearch
		) {
			let searchField = HIDE_PLATE_FIELD.includes(brand) ? clientInfo.fullName : clientInfo.plate;

			if ([Brand.MMM, Brand.MULTIPLE_ASISTENCIA_EN_EL_HOGAR].includes(brand as Brand)) {
				searchField = clientInfo.memberId || '';
			}

			try {
				setLoading(true);
				const responseSearch = await new HeliosClient().contractSearch(
					searchField,
					accountInfo.salesforce,
					accountInfo.branch,
					clientInfo.fullName,
					true
				);
				const search = responseSearch.data.data;
				const serviceData = {
					serviceData: {
						branch: accountInfo.branch,
						situation: ctx.situation,
						providerDistance: null,
						distance: distance,
						customerContract: search[0],
						customerFeeParams: {
							branch: accountInfo.branch,
							distance: distance,
							fees: JSON.stringify({
								management: ctx.account.managementFee,
								situation: ctx.account.serviceFees[ctx.situation],
							}),
							providerCosts: { serviceCost: 0 },
							sfIdAccount: accountInfo.salesforce,
						},
					},
				};

				const checkLimitServices: any = await new HeliosClient().checkLimit(serviceData);

				const checkLimitResponse = checkLimitServices.data.data;

				setIsSurplus(checkLimitResponse.surplus === 0 ? false : true);

				setServiceData({ ...service, surplus: checkLimitResponse.surplus }, dispatch, '');

				fetchFeeData(
					{
						branch: account.branch,
						situation: situation,
						distance: distance,
						accountId: account.salesforce,
					},
					dispatch
				);

				if (
					[Brand.MMM, Brand.MULTIPLE_ASISTENCIA_EN_EL_HOGAR].includes(brand as Brand) &&
					currentTrack === TRACKS.HOME
				) {
					history.push('/clients');
				} else {
					history.push('/location/summary');
				}
				setLoading(false);
			} catch (error) {
				setLoading(false);
				console.log('Search Plate Error: ', error);
			}
		} else {
			history.push('/location/summary');

			fetchFeeData(
				{
					branch: account.branch,
					situation: situation,
					distance: distance,
					accountId: account.salesforce,
				},
				dispatch
			);
		}
	};

	const etaFormatted = isNaN(eta) || -1 ? `35 - 45` : `${eta} - ${eta + 10}`;
	const receiptAmount = getAmount(
		service,
		customerFee,
		account,
		brand,
		isSurplus,
		integrationServiceCreation?.legacyEnableReceiptPreview
	);
	const hasTow = situation === Situation.TOW_BREAKDOWN;
	const isHome = currentTrack === TRACKS.HOME;
	const SituationSvg = getSituationIcon(situation);
	const name = getSituationLabel(situation, serviceTypes);
	const shouldVerifyClientAvailability = actionType !== 'confirmData';

	return (
		<>
			{!hidden && (
				<>
					{locationConfirmed && (
						<>
							<div className="location-bottom-sheet-map">
								<div className="container">
									{shouldVerifyClientAvailability && (
										<>
											{isHome ? (
												<HomeAvailability
													availability={availability}
													setAvailability={setAvailability}
													selectedDate={homeSelectedDate}
													handleDateChange={handleHomeDateChange}
												/>
											) : (
												<Availability
													displayButtons={displayScheduleButtons}
													isScheduledService={isScheduledService}
													etaFormatted={etaFormatted}
													selectedDate={selectedDate}
													selectedTime={selectedTime}
													setIsScheduledService={setIsScheduledService}
													handleDateChange={handleDateChange}
													handleTimeChange={handleTimeChange}
												/>
											)}
										</>
									)}

									<div className="details">
										{!isHome && (
											<>
												<div className="title">{i18n.get('bottom_sheet.title', 'Mi Servicio')}</div>
												<div className="service__div">
													<>
														<PinSvg className="svg-color" />
														{hasTow && (
															<>
																<div className="dividers">
																	<span className="vertical-divider"></span>
																</div>
																<img className="bullet" src={bulletInactive} alt="location bullet" />
															</>
														)}
													</>
													<div className="service__div-address">
														<span className="details__situation-name app-font-medium">
															{locations?.situation?.prettyAddress
																? locations?.situation?.prettyAddress
																: locations?.situation?.address}
														</span>
														{hasTow && (
															<span className="details__situation-name app-font-medium">
																{locations?.destination?.prettyAddress
																	? locations?.destination?.prettyAddress
																	: locations?.destination?.address}
															</span>
														)}
													</div>
												</div>
												<div className="details__situation">
													{SituationSvg && <SituationSvg className="svg-color" />}
													<span className="details__situation-name app-font-medium">{name}</span>
												</div>

												<div className="container__line"></div>
												{SHOW_VEHICLE_INFO_IN_SUMMARY_BRANDS.includes(brand) && <VehicleInfo clientInfo={clientInfo} />}
											</>
										)}
									</div>

									{parseFloat(receiptAmount.total) > 0 && (
										<div className="container__pay">
											<div className="container__pay-row app-font-medium">
												<div className="container__pay-service">
													{name}
													{isSurplus && (
														<span className="app-font-semi-bold title-color">
															({i18n.get('bottom_sheet.amount.surplus', 'excedente')})
															<img
																src={information}
																alt="Informacion"
																width="15"
																onClick={(): void => setIsInformationOpen(true)}
															></img>
														</span>
													)}
												</div>
												<div>
													{getDollarSign(account)}
													{isSurplus}
													{isSurplus && receiptAmount.surplus}
													{!isSurplus && receiptAmount.customerFee}
												</div>
											</div>
											<div className="container__pay-row app-font-medium">
												<div>{getTax(account).label}</div>
												<div>
													{getDollarSign(account)}
													{receiptAmount.tax}
												</div>
											</div>
											<div className="container__pay-row app-font-medium">
												<div>{i18n.get('bottom_sheet.amount.total', 'Total')}</div>
												<div>
													{getDollarSign(account)}
													{receiptAmount.total}
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="footer">
									{serviceUpdated && (
										<p className="center-text">{i18n.get('bottom_sheet.service_updated', 'SERVICIO ACTUALIZADO')}</p>
									)}
								</div>
							</div>
							<SetUpButtonComponent
								brand={brand}
								isSurplus={isSurplus}
								isHome={isHome}
								actionType={actionType}
								loading={loading}
								service={service}
								receiptAmount={receiptAmount}
								onConfirmService={onConfirmService}
								availability={availability}
								currentTrack={currentTrack}
							/>
						</>
					)}
					{!locationConfirmed && (
						<Footer.Root>
							<Footer.Button
								isLoading={loading}
								disabled={(hasTow && !locations?.destination?.lat) || !locations?.situation?.address}
								variant="primary"
								onClick={onConfirmClick}
							>
								{i18n.get('bottom_sheet.location_to_confirm.btn_confirm', 'Confirmar Localización')}
							</Footer.Button>
						</Footer.Root>
					)}
					{isCalendarOpen && (
						<div className="calendar">
							<div className="calendar__container">
								<div className="calendar__container-top">
									<img
										src={closeCalendarIcon}
										alt="location bullet"
										width="18"
										onClick={(): void => {
											setIsCalendarOpen(false);
										}}
									/>
								</div>
							</div>
						</div>
					)}
					{isInformationOpen && (
						<div className="information">
							<div className="information__container">
								<div className="information__container-top"></div>
								<div className="information__container-center">
									<h2>{i18n.get('bottom_sheet.information.surplus', 'Excedente')}</h2>
									<p>
										{i18n.get(
											'',
											'Se aplica un excedente cuando el servicio solicitado supera el límite de su cobertura.'
										)}
									</p>
								</div>
								<Footer.Root>
									<Footer.Button variant="primary" onClick={() => setIsInformationOpen(false)}>
										{i18n.get('bottom_sheet.information.btn_ok', 'Ok')}
									</Footer.Button>
								</Footer.Root>
							</div>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default LocationBottomSheet;
