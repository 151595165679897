import React from 'react';
import Star from '../../components/common/Star';
import i18n from '../../utils/i18n';

interface SurveyRatingsProps {
	brand: string;
}

const RatingHeader: React.FC<SurveyRatingsProps> = ({ brand }) => {
	return (
		<div className="survey__ratings">
			<div className="survey__ratings-poor">
				<span>
					<Star brand={brand} selected />
				</span>
				<span> = {i18n.get('survey.lbl_poor', 'Pobre')}</span>
			</div>
			<div className="survey__ratings-excellent">
				<span>
					{[...Array(5)].map((n, idx) => (
						<Star brand={brand} key={idx} selected />
					))}
				</span>
				<span> = {i18n.get('survey.lbl_excellent', 'Excelente')}</span>
			</div>
		</div>
	);
};

export default RatingHeader;
