import React, { FunctionComponent } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ScreensRoot from './screens/Root';
import AppState from './context/Context';
import useNetwork from './hooks/useNetwork';
import NoConnectionScreen from './screens/noConnection/NoConnection';
import './App.scss';
import ServiceWorkerWrapper from './components/ServicWorkerWrapper';

const App: FunctionComponent = (): JSX.Element => {
	const isOnline = useNetwork();

	return (
		<AppState>
			<ServiceWorkerWrapper />
			<Router>
				<ScreensRoot show={isOnline} /> <NoConnectionScreen isOnline={isOnline} />
			</Router>
		</AppState>
	);
};

export default App;
