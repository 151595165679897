import {
	ActionType,
	AppContext,
	Action,
	AddressType,
	ServiceType,
	ServiceTypesObject,
	homeCategories,
	INTEGRATION_TYPE,
	Integration,
} from '../interfaces';
import { cleanState } from './Context';

export const reducer = (state: AppContext, action: Action): AppContext => {
	switch (action.type) {
		case ActionType.SET_PASSENGERS:
			return { ...state, passengers: action.data };
		case ActionType.SET_SITUATION:
			return { ...state, situation: action.data };
		case ActionType.SET_CATEGORY:
			return { ...state, category: action.data };
		case ActionType.SET_CLIENT_INFO:
			return { ...state, clientForm: action.data };
		case ActionType.SET_CUSTOMER_CONTRACT:
			return { ...state, customerContract: action.data };
		case ActionType.FILL_FORM: {
			let stateKey;
			let stateValue;

			// Need to extract the key and value from the data object and assign them to
			// state object
			for (const [key, value] of Object.entries(action.data)) {
				stateKey = key;
				stateValue = value;
			}
			return {
				...state,
				clientForm: {
					...state.clientForm,
					[stateKey as string]: stateValue,
				},
			};
		}
		case ActionType.SECOND_STOP_FILL_FORM:
			return { ...state, clientForm: action.data };
		case ActionType.SAVE_PAYMENT:
			return { ...state, payment: action.data };
		case ActionType.SET_COORDS:
			if (action.data.addressType === AddressType.SITUATION) {
				return {
					...state,
					locations: {
						...state.locations,
						situation: {
							...state.locations.situation,
							lat: action.data.lat,
							lng: action.data.lng,
							placeId: action.data.placeId,
						},
					},
				};
			} else if (action.data.addressType === AddressType.DESTINATION) {
				return {
					...state,
					locations: {
						...state.locations,
						destination: {
							...state.locations.destination,
							lat: action.data.lat,
							lng: action.data.lng,
							placeId: action.data.placeId,
						},
					},
				};
			} else if (action.data.addressType === AddressType.PROVIDER) {
				return {
					...state,
					locations: {
						...state.locations,
						provider: {
							...state.locations.provider,
							lat: action.data.lat,
							lng: action.data.lng,
							placeId: action.data.placeId,
						},
					},
				};
			} else return state;
		case ActionType.SET_ADDRESS:
			if (action.data.addressType === AddressType.SITUATION)
				return {
					...state,
					locations: {
						...state.locations,
						situation: {
							...state.locations.situation,
							address: action.data.address,
							placeId: action.data.placeId,
							prettyAddress: action.data.prettyAddress,
						},
					},
				};
			else if (action.data.addressType === AddressType.DESTINATION)
				return {
					...state,
					locations: {
						...state.locations,
						destination: {
							...state.locations.destination,
							address: action.data.address,
							placeId: action.data.placeId,
							prettyAddress: action.data.prettyAddress,
						},
					},
				};
			else return state;
		case ActionType.SET_CUSTOMER_FEE:
			return { ...state, customerFee: action.data };
		case ActionType.SET_REFERENCE_ADDRESS_FORM:
			return { ...state, referenceAddressForm: action.data };
		case ActionType.SET_DISTANCE:
			return { ...state, distance: action.data };
		case ActionType.SET_SERVICE_NUMBER:
			return { ...state, service: { ...state.service, serviceNumber: action.data } };
		case ActionType.SET_SERVICE:
			return { ...state, service: { ...state.service, ...action.data } };
		case ActionType.SET_SERVICE_DRIVER: {
			const eta = action.data?.eta >= 0 ? action.data?.eta : state.service?.driver?.eta;
			return { ...state, service: { ...state.service, driver: { ...state.service?.driver, ...action.data, eta } } };
		}
		case ActionType.SET_NOTES:
			return { ...state, notes: action.data };
		case ActionType.SAVE_HOME_AVAILABILITY:
			return { ...state, homeNotes: { ...state.homeNotes, availability: action.data } };
		case ActionType.SAVE_HOME_DATE:
			return { ...state, homeNotes: { ...state.homeNotes, date: action.data } };
		case ActionType.SAVE_ANSWERS:
			return { ...state, answers: action.data };
		case ActionType.CLEAR_ALL:
			return {
				...(cleanState as any),
				dispatch: state.dispatch,
			};
		// Si el cliente es afiliado a una cuenta pero ya se sobrepasó del límite de servicios que puede pedir,
		// este cliente es redirigido al flujo de Connect On Demand (Home o Road/Concierge) para completar el pedido de su servicio.
		// Se conserva el contrato para poder identificarlo como afiliado a una cuenta.
		case ActionType.CLEAR_ALL_FOR_CHECK_LIMIT:
			return {
				...(cleanState as any),
				dispatch: state.dispatch,
				previousAccount: state.account,
				customerContract: state.customerContract,
				clientForm: state.clientForm,
			};
		case ActionType.CLEAR_ALL_FOR_MMM_HOD:
			return {
				...(cleanState as any),
				dispatch: state.dispatch,
				customerContract: state.customerContract,
				isMMMHOD: state.isMMMHOD,
			};
		case ActionType.SET_MMM_HOD:
			return { ...state, isMMMHOD: action.data };
		case ActionType.SET_CAMPAIGN:
			return { ...state, campaign: action.data };
		case ActionType.SET_LOCATION_PERMISSION:
			return { ...state, locations: { ...state.locations, permission: action.data } };
		case ActionType.SET_BRAND:
			return { ...state, brand: action.data };
		case ActionType.SET_USER_EXTERNAL_PARAMS:
			return { ...state, userExternalParams: action.data };
		case ActionType.SET_ACCOUNT:
			return { ...state, account: action.data };
		case ActionType.SET_ACTION_TYPE:
			return { ...state, actionType: action.data };
		case ActionType.SET_PUSH_OPT_IN:
			return { ...state, pushOptIn: action.data };
		case ActionType.SET_SERVICE_TYPES: {
			const sortedServiceTypes =
				action.data.sort((a: ServiceType, b: ServiceType) => (a.label > b.label ? 1 : -1)) || [];
			const serviceTypesObj: ServiceTypesObject = {};

			sortedServiceTypes.forEach((serviceType: ServiceType) => {
				serviceTypesObj[serviceType.name] = serviceType;
			});
			return { ...state, serviceTypes: serviceTypesObj };
		}
		case ActionType.SET_CURRENT_TRACK:
			return { ...state, currentTrack: action.data };
		case ActionType.SET_CHECK_LIMIT_ERRORS:
			return { ...state, checkLimitErrors: action.data };
		case ActionType.SET_TOKEN:
			return { ...state, token: action.data };
		case ActionType.SET_PHONE_NUMBER:
			return { ...state, phoneNumber: action.data };
		case ActionType.CLEAR_CATEGORY:
			return { ...state, category: '' as homeCategories };
		case ActionType.SET_INTEGRATIONS: {
			const integrationServiceCreation = action.data.find(
				(element: Integration) => element.type === INTEGRATION_TYPE.CONNECT_APP_SERVICE_CREATION
			);

			const integrationTracking = action.data.find(
				(element: Integration) => element.type === INTEGRATION_TYPE.CONNECT_APP_SURVEY
			);

			return {
				...state,
				integrationServiceCreation,
				integrationTracking,
			};
		}
		default:
			return state;
	}
};
