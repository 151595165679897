import { useEffect, useState } from 'react';

interface Point {
	x: number;
	y: number;
}

const bezier = {
	C1: (t: number) => {
		return (1 - t) * (1 - t) * (1 - t);
	},
	C2: (t: number) => {
		return 3 * t * (1 - t) * (1 - t);
	},
	C3: (t: number) => {
		return 3 * t * t * (1 - t);
	},
	C4: (t: number) => {
		return t * t * t;
	},
	getCoords: (P0: Point, P1: Point, P2: Point, P3: Point, percent: number) => {
		const pos: Point = { x: 0, y: 0 };
		pos.x =
			P0.x * bezier.C1(percent) + P1.x * bezier.C2(percent) + P2.x * bezier.C3(percent) + P3.x * bezier.C4(percent);
		pos.y =
			P0.y * bezier.C1(percent) + P1.y * bezier.C2(percent) + P2.y * bezier.C3(percent) + P3.y * bezier.C4(percent);
		return pos;
	},
};

const calculateCurvePoints = (start: google.maps.LatLng, end: google.maps.LatLng, resolution: number) => {
	const lineLength = window.google.maps.geometry.spherical.computeDistanceBetween(start, end);
	const lineHeading = window.google.maps.geometry.spherical.computeHeading(start, end);

	const ANGULAR_OPENING = 20;
	let lineHeading1, lineHeading2;
	if (lineHeading < 0) {
		lineHeading1 = lineHeading + ANGULAR_OPENING;
		lineHeading2 = lineHeading + (180 - ANGULAR_OPENING);
	} else {
		lineHeading1 = lineHeading + -ANGULAR_OPENING;
		lineHeading2 = lineHeading + -(180 - ANGULAR_OPENING);
	}
	const startF = window.google.maps.geometry.spherical.computeOffset(start, lineLength / 2, lineHeading1);
	const endF = window.google.maps.geometry.spherical.computeOffset(end, lineLength / 2, lineHeading2);

	const P0 = {
		x: start.lat(),
		y: start.lng(),
	};
	const P1 = {
		x: startF.lat(),
		y: startF.lng(),
	};
	const P2 = {
		x: endF.lat(),
		y: endF.lng(),
	};
	const P3 = {
		x: end.lat(),
		y: end.lng(),
	};

	const points: Point[] = [];

	for (let percent = 0; percent <= 1; percent += resolution) {
		points.push(bezier.getCoords(P0, P1, P2, P3, percent));
	}
	const path = [];
	for (let i = 0; i < points.length - 1; i++) {
		path.push(new window.google.maps.LatLng(points[i].x, points[i].y));
		path.push(new window.google.maps.LatLng(points[i + 1].x, points[i + 1].y, false));
	}
	return path;
};

function useProgressCurve(start: google.maps.LatLngLiteral, end: google.maps.LatLngLiteral) {
	const [progressCurvePoints, setProgressCurvePoints] = useState<google.maps.LatLng[]>([]);

	useEffect(() => {
		if (start && end) {
			const path = calculateCurvePoints(
				new window.google.maps.LatLng(start.lat, start.lng),
				new window.google.maps.LatLng(end.lat, end.lng),
				0.01
			);
			setProgressCurvePoints(path);
		}
	}, [start, end]);

	return {
		progressCurvePoints,
	};
}

export { useProgressCurve };
