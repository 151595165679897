import React, { FunctionComponent, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import HeliosClient from '../../api/Helios';
import TrackingHeader from '../../components/tracking/Header';
import TrackingReceipt from '../../components/tracking/Receipt';
import { Context } from '../../context/Context';
import { ActionType, AppContext, Branches, TripStatus } from '../../interfaces';
import {
	FINISHED_TRIP_STATUS,
	ACTIVE_TRIP_STATUS,
	ACCOUNTS_WITH_DISABLED_IN_HOUSE_SURVEY,
} from '../../utils/constants';
import LoadingAnimationContent from '../../components/tracking/LoadingAnimationContent';
import { getSituationLabel, isDriverActive, isInspectionCRPA } from '../../utils/Utils';
import './Loading.scss';
import OneSignal from 'react-onesignal';
import { initOneSignal, subscribeOneSignal } from '../../api/OneSignal';
import i18n from '../../utils/i18n';
import useDriverLocation from '../../hooks/useDriverLocation';
import { setIntegrations } from '../../context/actions';

const TIME_TO_REQUEST = 18000;

const TrackingLoadingScreen: FunctionComponent = (): JSX.Element => {
	const { service, dispatch, account, payment, brand, serviceTypes, integrationTracking } = useContext(
		Context
	) as AppContext;
	const history = useHistory();
	useDriverLocation();

	useEffect(() => {
		const appId = process.env.REACT_APP_ONESIGNAL_APP_ID;
		const startOneSignal = async () => {
			if (appId) {
				await initOneSignal(account.name || '');
				// Si el usuario pide otro servicio y ya se habia rergistrado al push
				// verifico si tiene permiso y le asigno el External user Id

				const notificationPermission = await OneSignal.getNotificationPermission();

				if (notificationPermission !== 'denied') {
					if (notificationPermission === 'default') {
						await OneSignal.showSlidedownPrompt();
					}
				} else {
					await OneSignal.showSlidedownPrompt();
					// Con este evento valido que el usuario le dio al boton de Ok y le asigno el External user Id
					OneSignal.on('subscriptionChange', () => (isSubscribed: boolean): void => {
						console.log('subscriptionChange');
						console.log('isSubscribed', isSubscribed);
					});
				}

				subscribeOneSignal(String(service.serviceNumber), dispatch);
				OneSignal.on('notificationDisplay', () => (event: any): void => {
					console.warn('OneSignal notification displayed:', event);
				});
			}
		};
		startOneSignal();
		if (account._id) fetchAndSetIntegrations(account._id);

		const fetchService = async (): Promise<void> => {
			const {
				data: { data },
			} = await new HeliosClient().getService(service._id);
			if (['Cancelled', 'Void'].includes(data.status)) history.replace('/cancelled');
			if (service.driver?.id !== data.driver?.id) {
				dispatch({ type: ActionType.SET_SERVICE_DRIVER, data: data.driver });
			}
			if (ACTIVE_TRIP_STATUS.includes(data.trip.status)) {
				const keepFirstStep = !isDriverActive(service.driver) && data.trip.status === TripStatus.ACCEPTED;
				if (
					(isInspectionCRPA(data.situation, data.branch as Branches) && data.trip.status === TripStatus.ACCEPTED) ||
					keepFirstStep
				) {
					return;
				}

				history.replace('/tracking');
			}

			if (['Finished', 'Pending Audit'].includes(data.status) || FINISHED_TRIP_STATUS.includes(data.trip.status)) {
				if (integrationTracking?.onSuccessRedirect) {
					window.location.href = integrationTracking.onSuccessRedirect;
				} else if (ACCOUNTS_WITH_DISABLED_IN_HOUSE_SURVEY.includes(data.sfIdAccount)) {
					history.replace('/finished');
				} else {
					history.replace('/survey');
				}
			}
		};

		if (service._id) {
			fetchService();
		}

		const timer = setInterval(() => fetchService(), TIME_TO_REQUEST);

		return (): void => clearInterval(timer);
	}, [service.serviceNumber, service._id, dispatch, history, service.driver, account.name, account.salesforce, brand]);

	const fetchAndSetIntegrations = async (accountId: string) => {
		try {
			const {
				data: { data: integrations },
			} = await new HeliosClient().fetchAccountIntegrations(accountId);
			setIntegrations(integrations, dispatch);
		} catch (err) {
			console.log('Error Getting Integrations: ', err);
		}
	};

	const situationLabel = `${getSituationLabel(service?.situation, serviceTypes)}`;
	const title = i18n.get(
		'tracking.loading.title',
		`${service?.firstname}, nos estamos preparando para tu servicio de ${situationLabel}.`,
		[service?.firstname, situationLabel]
	);
	const defaultTitle = i18n.get(
		'tracking.loading.default_title',
		`Nos estamos preparando para tu servicio de ${situationLabel}.`,
		[situationLabel]
	);

	return (
		<div className="tracking-loading-screen app-background-color tracking-route app-font-medium">
			<TrackingHeader
				title={service?.firstname ? title : defaultTitle}
				finishService={true}
				currentStatus={TripStatus.NEW}
			/>
			<LoadingAnimationContent service={service} brand={brand} />
			<div className="drawer-handle" />
			<TrackingReceipt service={service} customerFee={payment.amount} brand={brand} />
		</div>
	);
};

export default TrackingLoadingScreen;
